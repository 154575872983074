import { useCallback, useEffect, useState } from 'react';
import { BlockStack, Text, InlineStack, Tag, Icon, Popover, Card, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';
import { PlusIcon } from '@shopify/polaris-icons';
import QuickSearchUser from 'components/quickSearchUser';
import Capacity from 'components/Capacity';
import { TypedOrder, useCreateAssign, useDeleteAssign, useGetAssignList } from 'queries/orders.query';
export default function OrderAssign({
  entity
}: {
  entity: TypedOrder;
}) {
  const {
    refetch: getAssignList,
    isPending: assignLoading,
    data: assignList
  } = useGetAssignList(entity.order_id);
  const {
    mutate: addAssignee,
    isPending: addAssigneeLoading
  } = useCreateAssign();
  const {
    mutateAsync: deleteAssign
  } = useDeleteAssign();
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    setSelectedTags(assignList);
  }, [assignList]);
  useEffect(() => {
    if (!entity) return;
    getAssignList();
  }, [entity]);
  const removeTag = useCallback(async (tag: any) => {
    setSelectedTags(previousTags => previousTags.filter(previousTag => previousTag.id !== tag.id));
    await deleteAssign(tag.id);
  }, []);
  const [showAddnewUser, setShowAddnewUser] = useState(false);
  const addNewAssignee = useCallback(async user_id => {
    addAssignee({
      order_id: entity?.order_id,
      user_id: user_id
    });
  }, [entity]);
  return <>
      {assignLoading ? <SkeletonBodyText /> : <BlockStack gap={'200'}>
          <Text as="p" variant="headingSm" key="OrderAssign_title">
            Phân quyền
          </Text>
          <InlineStack gap={'200'} align="start" blockAlign="center">
            <Tag>{entity?.user?.display_name || entity?.user?.user_email}</Tag>
            {Array.isArray(selectedTags) && selectedTags.length > 0 && selectedTags.map((e, index) => {
          return <Tag key={index + 'order_taglist'} onRemove={() => removeTag(e)}>
                    {e?.assignee?.display_name}
                  </Tag>;
        })}

            {addAssigneeLoading && <SkeletonThumbnail size="extraSmall" />}
            <Capacity current_user_can={['manage_orders', 'edit_orders']}>
              <Popover active={showAddnewUser} activator={<Tag onClick={() => setShowAddnewUser(true)}>
                    <InlineStack align="start" blockAlign="center" gap="200">
                      <span key="add_new_assignee">Thêm nhân viên</span>
                      <Icon source={PlusIcon} />
                    </InlineStack>
                  </Tag>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(false)}>
                <Card>
                  <QuickSearchUser title="" user_role="sale,sale_admin,admin" onClose={(userData: any) => {
                let __newUser = userData.user_id;
                addNewAssignee(__newUser);
                setShowAddnewUser(false);
              }} />
                </Card>
              </Popover>
            </Capacity>
          </InlineStack>
        </BlockStack>}
    </>;
}