import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedTransactions } from "interface/transaction.model";
import queryClient from 'queries';

type IQuery = TypedTransactions & IQueryParams;

/*
* List of all app
*/
export function useGetTransactions(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["transactions/fetch_entity_list"],
        queryFn: () => axios.get<TypedTransactions[]>(`/transactions${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

/** Get transaction detail via Hash */
export function useGetTransaction() {
    return useMutation({
        mutationKey: ['transactions/fetch_entity_via_hash'],
        mutationFn: (hash: string) => axios.get<TypedTransactions[]>(`/transactions/${hash}`).then((res) => res.data),
        retry: 1,
    });
}

export function useCreateTransaction() {
    return useMutation({
        mutationKey: ['transactions/create_entity'],
        mutationFn: (entity: TypedTransactions) => axios.post<TypedTransactions>(`/transactions`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['transactions/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['transaction_to_order/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] }); // resset don hang
        }
    });
}
