import { BlockStack, Button, DataTable, Link } from '@shopify/polaris';
import { TypedUser_role, useDeleteUserRole, useGetUserRoles } from 'queries/user_role.query';
import { CheckIcon, DeleteIcon, PlusIcon, StarFilledIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import dateandtime from 'date-and-time';
import UserRoleNewModal from './user_role.new.modal';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function UserRole() {
  const {
    data: user_role_list
  } = useGetUserRoles({});
  const {
    mutateAsync: deleteUserRole
  } = useDeleteUserRole();
  const [entities, setEntities] = useState<TypedUser_role[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  useEffect(() => {
    if (user_role_list) {
      let {
        entities,
        totalItems
      } = user_role_list;
      setEntities(entities);
      setTotalItems(totalItems);
    }
  }, [user_role_list]);
  const deleteUserRoleCallback = useCallback(async id => {
    const confirmed = await showDeleteConfirm('Xóa quyền này?', `Những người có vai trò này sẽ được chuyển sang vai trò mặc định. Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.`);
    if (confirmed) {
      try {
        await deleteUserRole(id);
      } catch (e) {}
    }
  }, []);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const currentUserRoleForEdit = useRef(null);
  return <>
      <BlockStack gap="400">
        <br />
        <div>
          <Button icon={PlusIcon} onClick={() => {
          setShowUpsertModal(true);
          currentUserRoleForEdit.current = null;
        }}>
            Thêm mới
          </Button>
        </div>
        <DataTable verticalAlign="middle" columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Tên vai trò', 'Phân loại', 'Nhóm quyền', 'Mặc định', 'Chỉ đọc', '', 'Ngày tạo']} hideScrollIndicator hasZebraStripingOnData increasedTableDensity rows={entities?.map(el => {
        return [el.is_readonly === 1 ? el.user_role : <Link onClick={() => {
          setShowUpsertModal(true);
          currentUserRoleForEdit.current = el;
        }}>
                    {el.user_role}
                  </Link>, el.user_role_type, el.user_capacity_group?.capacity_group_name, el.is_default ? <Button variant="plain" icon={StarFilledIcon}></Button> : '', el.is_readonly ? <Button variant="plain" icon={CheckIcon}></Button> : '', el.is_readonly !== 1 ? <Button icon={DeleteIcon} variant="plain" onClick={() => deleteUserRoleCallback(el.id)}></Button> : '', dateandtime.format(new Date(Number(el.createdAt)), 'DD-MM-YYYY')];
      }) || []} />
      </BlockStack>

      <UserRoleNewModal open={showUpsertModal} onClose={() => setShowUpsertModal(false)} entity={currentUserRoleForEdit.current} />
    </>;
}