import { BlockStack, Button, Form, Popover } from '@shopify/polaris';
import { SaveIcon } from '@shopify/polaris-icons';
import axios from 'axios';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
import { useAuth } from 'context/AuthContext';
import { useNotification } from 'context/NotificationContext';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import 'media/css/social_post.scss';
import { TypedSocial, useCreateSocialPost } from 'queries/social.query';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
export type TypedUploadResult = {
  media_id: string;
  media_url: string;
  media_filename: string;
  media_filetype: string;
};
export default function NewPost({
  social_category,
  onCallback
}: {
  social_category: string;
  onCallback: (entity: TypedSocial) => void;
}) {
  const {
    user
  } = useAuth();
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const {
    addNotification
  } = useNotification();
  const [productID, setProductID] = useState(null);
  const [productName, setProductName] = useState('Đính kèm sản phẩm');
  const {
    mutateAsync: createSocialPost,
    isPending: creating,
    isSuccess,
    data
  } = useCreateSocialPost();
  useEffect(() => {
    if (isSuccess) {
      addNotification('info', __('Đăng bài thành công!'));
      onCallback(data);
    }
  }, [isSuccess, data]);
  async function saveToServer(file: File): Promise<TypedUploadResult | undefined> {
    const fd = new FormData();
    fd.append('files', file);
    let {
      data
    } = await axios.post(REACT_APP_AJAX_UPLOAD_URL, fd);
    return data as TypedUploadResult;
  }
  const handleContentChange = e => {
    setContent(e.target.value);
  };
  const handleImageChange = e => {
    const files: any = Array.from(e.target.files);
    const newImageURLs = files.map((file: any) => URL.createObjectURL(file));
    setImages(prevImages => [...prevImages, ...newImageURLs]);
    setImageFiles(prevFiles => [...prevFiles, ...files]);
  };
  const handleRemoveImage = index => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
    setImageFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (content.trim() || images.length > 0) {
      try {
        const uploadedImages = [];
        for (let file of imageFiles) {
          let data = await saveToServer(file);
          uploadedImages.push(data.media_id);
        }
        await createSocialPost({
          social_post_content: content,
          social_category: social_category,
          social_suggest_product_id: productID,
          media_id: uploadedImages
        });
      } catch (e) {
        console.error(e);
      }
      setContent('');
      setImages([]);
      setImageFiles([]);
    }
  };
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  const onDragEnd = result => {
    if (!result.destination) return;
    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    setImages(reorderedImages);
  };
  return <div className="new-post">
      <Form onSubmit={handleSubmit}>
        <div className="new-post-header">
          <img src={__helpers.getMediaLink(user.user_avatar)} alt=" Avatar" className="avatar" />
          <textarea value={content} onChange={handleContentChange} placeholder="Nội dung bạn cần đăng tải là gì?" />
        </div>

        {images.length > 0 && <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
              {provided => <div className="image-preview-outerwrap">
                  <div className="image-previews" {...provided.droppableProps} ref={provided.innerRef}>
                    {images.map((image, index) => <Draggable key={index} draggableId={`image-${index}`} index={index}>
                        {(provided, snapshot) => <div className="image-preview-container" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{
                  ...provided.draggableProps.style,
                  display: 'inline-block',
                  margin: snapshot.isDragging ? '0 10px' : '0',
                  width: '100px',
                  height: '100px'
                }}>
                            <img src={image} alt={`Preview ${index}`} className="image-preview" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }} />
                            <button type="button" onClick={() => handleRemoveImage(index)}>
                              &times;
                            </button>
                          </div>}
                      </Draggable>)}
                    {provided.placeholder}
                  </div>
                </div>}
            </Droppable>
          </DragDropContext>}

        <BlockStack gap="400">
          <div className="new-post-actions">
            <label htmlFor="image-upload" className="action-btn">
              Thêm ảnh
            </label>
            <input type="file" id="image-upload" multiple onChange={handleImageChange} style={{
            display: 'none'
          }} />
            <Button loading={creating} submit disabled={content ? false : true} icon={SaveIcon}>
              Đăng
            </Button>
          </div>
          <Popover active={popoverActive} activator={<>
                <Button variant="plain" onClick={togglePopoverActive} disclosure>
                  {productName}
                </Button>
              </>} onClose={togglePopoverActive} ariaHaspopup={false} sectioned>
            <QuickSearchProduct2 allowMultiple={false} current_product_id={null} label={''} onClose={v => {
            let product_id = v[0].product_id;
            let product_name = v[0].product_name;
            setProductID(product_id);
            setProductName(`Đính kèm sản phẩm` + product_name);
          }} />
          </Popover>
        </BlockStack>
      </Form>
    </div>;
}