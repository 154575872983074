import { BlockStack, Button, DataTable, Link } from '@shopify/polaris';
import { DeleteIcon, EditIcon, PlusIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import dateandtime from 'date-and-time';
import { TypedUser_capacity_group, useDeleteUserCapacityGroup, useGetUserCapacityGroups } from 'queries/user_capacity_group.query';
import UserCapacityGroupNewModal from './capacity_group.modal';
import __helpers from 'helpers/index';
import UserCapacityGroupPermissionModal from './capacity_group_permission.modal';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function CapacityGroup() {
  const {
    data: user_role_list
  } = useGetUserCapacityGroups({});
  const {
    mutateAsync: deleteUserCapacityGroup
  } = useDeleteUserCapacityGroup();
  const [entities, setEntities] = useState<TypedUser_capacity_group[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  useEffect(() => {
    if (user_role_list) {
      let {
        entities,
        totalItems
      } = user_role_list;
      setEntities(entities);
      setTotalItems(totalItems);
    }
  }, [user_role_list]);
  const deleteUserCapacityGroupCallback = useCallback(async capacity_group_id => {
    const confirmed = await showDeleteConfirm('Xóa nhóm quyền này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      try {
        await deleteUserCapacityGroup(capacity_group_id);
      } catch (e) {}
    }
  }, []);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const currentUserCapacityGroupForEdit = useRef(null);
  return <>
      <BlockStack gap="400">
        <br />
        <div>
          <Button icon={PlusIcon} onClick={() => {
          setShowUpsertModal(true);
          currentUserCapacityGroupForEdit.current = null;
        }}>
            Thêm mới
          </Button>
        </div>
        <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'numeric']} headings={['Tên nhóm', 'Mô tả', 'Phân quyền', '-', 'Ngày tạo']} hideScrollIndicator hasZebraStripingOnData increasedTableDensity rows={entities?.map(el => {
        return [<Link onClick={() => {
          setShowUpsertModal(true);
          currentUserCapacityGroupForEdit.current = el;
        }}>
                  {el.capacity_group_name}
                </Link>, __helpers.trimContentString(el.capacity_group_description), <Button variant="plain" icon={EditIcon} onClick={() => {
          setShowPermissionModal(true);
          currentUserCapacityGroupForEdit.current = el;
        }}></Button>, <Button icon={DeleteIcon} variant="plain" onClick={() => deleteUserCapacityGroupCallback(el.capacity_group_id)}></Button>, dateandtime.format(new Date(Number(el.createdAt)), 'DD-MM-YYYY')];
      }) || []} />
      </BlockStack>

      <UserCapacityGroupNewModal open={showUpsertModal} onClose={() => setShowUpsertModal(false)} entity={currentUserCapacityGroupForEdit.current} />
      <UserCapacityGroupPermissionModal open={showPermissionModal} onClose={() => setShowPermissionModal(false)} entity={currentUserCapacityGroupForEdit.current} />
    </>;
}