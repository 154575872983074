import { Form, InlineGrid, Modal, TextField, Text, Banner, Checkbox, BlockStack } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import __helpers from 'helpers/index';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import { useCreatePostCategory, useUpdatePostCategory } from 'queries/post_category.query';
import { TypedPostCategory } from 'queries/posts.query';
import React, { useState, useEffect, useCallback } from 'react';
export default function ProductCategoryModalAddAndEdit({
  show = false,
  onClose = null,
  entity = null
}: {
  show: boolean;
  entity?: TypedPostCategory;
  onClose?: (current_category_id: bigint | null) => void;
}) {
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    data: afterUpdateData
  } = useUpdatePostCategory();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccess,
    data: afterCreateData
  } = useCreatePostCategory();
  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  const onCloseCallback = useCallback((category_id?: bigint) => {
    onClose(category_id);
    setOpen(false);
  }, []);
  useEffect(() => {
    if (!updateSuccess) return;
    if (!createSuccess) return;
    if (createSuccess) {
      onCloseCallback(afterCreateData?.category_id);
      addNotification('info', __('created_successfully'));
    }
    if (updateSuccess) {
      onCloseCallback(afterUpdateData?.category_id);
      addNotification('info', __('update_successfully'));
    }
  }, [updateSuccess, createSuccess, afterCreateData, afterUpdateData, onCloseCallback]);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    category_thumbnail: useField<string>({
      value: entity?.category_thumbnail,
      validates: []
    }),
    category_thumbnail_cache: useField<string>({
      value: entity?.category_thumbnail_to_media?.media_thumbnail ? entity?.category_thumbnail_to_media?.media_thumbnail['128x128'] : '',
      validates: []
    }),
    category_name: useField<string>({
      value: entity?.category_name ?? '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    category_slug: useField<string>({
      value: entity?.category_slug ?? '',
      validates: [lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    category_description: useField<string>({
      value: entity?.category_description ?? '',
      validates: [lengthLessThan(1000, 'Không được dài hơn 1000 ký tự.')]
    }),
    category_status: useField<number>({
      value: entity?.category_status ?? 1,
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        let addProductCategory: any = null;
        if (entity) {
          addProductCategory = await updateEntity({
            category_id: entity?.category_id,
            category_name: values.category_name,
            category_description: values.category_description,
            category_thumbnail: values.category_thumbnail,
            category_status: values.category_status,
            category_slug: values.category_slug
          });
        } else {
          addProductCategory = await createEntity({
            category_name: values.category_name,
            category_description: values.category_description,
            category_thumbnail: values.category_thumbnail,
            category_status: values.category_status,
            category_slug: values.category_slug
          });
        }
        let {
          data
        } = await addProductCategory;
        reset();
        onCloseCallback(data.category_id);
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  useEffect(() => {
    let slugText = helpers.slugify(useFields.category_name.value);
    if (slugText && !entity) {
      useFields.category_slug.onChange(slugText);
    }
  }, [useFields.category_name, entity]);
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={entity ? 'Cập nhật chuyên mục bài đăng' : 'Thêm chuyên mục bài đăng'} primaryAction={{
    content: entity ? __('update_label_button') : 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
              <div className="d1">
                <QuickUploadImage width={150} height={150} onSuccess={e => {
                useFields.category_thumbnail.onChange(e.media_id);
                useFields.category_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={__helpers.getMediaLink(useFields.category_thumbnail_cache.value, 'https://placehold.co/100x100')} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>
              <BlockStack gap="500">
                <TextField label="Tên danh mục" maxLength={150} showCharacterCount autoComplete="off" requiredIndicator {...fields.category_name} />
                <TextField label="Đường dẫn tĩnh" maxLength={150} showCharacterCount autoComplete="off" {...fields.category_slug} />
              </BlockStack>
            </InlineGrid>

            <TextField label="Mô tả" autoComplete="off" multiline={3} maxLength={1000} showCharacterCount requiredIndicator {...fields.category_description} />

            <Checkbox label="Cho phép hiển thị" helpText="Nếu tắt, Category này sẽ không hiển thị trên ứng dụng hoặc website." checked={useFields.category_status.value === 1} onChange={e => {
            useFields.category_status.onChange(Number(e));
          }} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}