import { Button, Card, DataTable, EmptyState, Link, Page, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import SkeletonPageLoading from 'components/skeletonPageLoading';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import { TypedShopping_cart_item, useAdminDeleteShoppingCartItem, useAdminGetShoppingCartItem, useGetShoppingCart } from 'queries/shopping_cart.query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import emptyIMG from '../../media/images/empty.png';
export default function ShoppingCartView() {
  let {
    user_id,
    cart_id
  } = useParams();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  // lấy shopping cart
  const {
    data,
    isError,
    isLoading,
    refetch: getEntities
  } = useGetShoppingCart(user_id);
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    shopping_cart_id: cart_id ?? '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) history(`/shopping_cart/${user_id}/${cart_id}` + buildURLSearch, {
      replace: true
    });
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  // lấy shopping cart item
  const {
    data: all_entities
  } = useAdminGetShoppingCartItem(mainQuery);
  const {
    mutateAsync: deleteShoppingCartItem
  } = useAdminDeleteShoppingCartItem();
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState<TypedShopping_cart_item[] | null>(null);
  useEffect(() => {
    if (all_entities) {
      let {
        body,
        totalItems
      } = all_entities;
      setTotalItems(totalItems);
      setEntities(body);
    }
  }, [all_entities]);
  const EmptyData = () => <Page title={`Giỏ hàng của ` + data?.user?.display_name} backAction={{
    content: 'Back to home',
    onAction: () => history(-1)
  }}>
          <EmptyState heading="Không có sản phẩm nào trong giỏ hàng!" image={emptyIMG} />
        </Page>;
  const deleteCallback = useCallback((shopping_cart_item_id: string) => {
    //
    deleteShoppingCartItem(shopping_cart_item_id);
  }, []);
  return <>
      {isLoading ? <SkeletonPageLoading /> : helpers.isEmpty(entities) ? <EmptyData /> : <Page title={`Giỏ hàng của ` + data?.user?.display_name} backAction={{
      content: 'Back to home',
      onAction: () => history('/shopping_cart')
    }}>
          <Card padding="0">
            <DataTable verticalAlign="middle" columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Tên sản phẩm', 'Giá', 'Số lượng', 'Created At', 'Tool']} rows={entities.map((el, index) => {
          return [<Text as="h6">
                    <Link url={`/product/edit/${el.product_id}`}>{el.product.product_name}</Link>
                    {el.variant_id && <Text as="p" tone="subdued">
                        Phân loại: {el?.product_variant?.variant_name}
                      </Text>}
                  </Text>, helpers.formatNumber(el.cart_price), helpers.formatNumber(el.cart_quantity), dateandtime.format(new Date(Number(el.createdAt)), 'DD-MM-YYYY'), <Button variant="plain" onClick={() => deleteCallback(el.id)}>
                    Delete
                  </Button>];
        })} />
          </Card>
        </Page>}
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
    </>;
}