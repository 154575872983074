import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dateandtime from 'date-and-time';
import { InlineStack, Badge, Button, DataTable, Divider, Icon, Layout, Link, Page, ResourceList, Select, Text, TextField, BlockStack, Thumbnail, Card, Box, Tabs, Tooltip, Banner } from '@shopify/polaris';
import orderStatusJson from 'config/order.status.json';
import TransactionNewModal from '../transactions/transaction.new';
import { XSmallIcon, ReceiptIcon, MenuHorizontalIcon, CheckSmallIcon, ProductIcon, ProductCostIcon, NoteIcon, ReceiptDollarFilledIcon, PlusIcon, QuestionCircleIcon, DeleteIcon, LockIcon, StatusActiveIcon, CollectionFeaturedIcon } from '@shopify/polaris-icons';
import 'media/css/order.detail.scss';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import ShowCustomFee from './order_fee.modal';
import __ from 'languages/index';
import OrderAssign from './order_detail/order.assign';
import OrderTransaction from './order_detail/order.transaction';
import { TypedOrder, useGetOrderDetail, useOrderChecked, useUpdateOrder, useUpdateOrderStatus } from 'queries/orders.query';
import { Helmet } from 'react-helmet-async';
import { useGetOrder_fees, useDeleteOrder_fee } from 'queries/order_fee.query';
import { useCreateOrderProduct, useDeleteOrderProduct, useGetOrderProducts, useUpdateOrderProduct } from 'queries/order_product.query';
import OrderTransportLog from './order_detail/order_transport_log';
import QuickSearchProductAndVariant from 'components/quickSearchProductAndVariant.modal';
import { PAYMENT_STATUS_CONST, PAYMENT_TYPE_CONST } from 'constant/index';
import { useNotification } from 'context/NotificationContext';
import { getBadgeOfPaymentMethod, getOrderStatusBadge } from './order.component';
import { useCancelFulfillmentOrder, useCreateFulfillmentOrder } from 'queries/order_fulfillment.query';
import OrderSidebarQuickSuft from './order.sidebar.quicksuft';
import useDidMountEffect from 'components/useDidMountEffect';
import printJS from 'print-js';
import OrderBountyList from './order_detail/order_bounty';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useAuth } from 'context/AuthContext';
const OrderTransportInformation = lazy(() => import('./order_detail/order_transport_information'));
const OrderCustomerInformation = lazy(() => import('./order_detail/order_customer_information'));
const OrderLogComponent = lazy(() => import('./order_detail/order_log.component'));
export default function OrderView() {
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  let {
    order_id
  } = useParams();

  // lấy quyền
  const {
    currentUserCan
  } = useAuth();
  const {
    addNotification
  } = useNotification(); // no ti
  const {
    showDeleteConfirm
  } = useDeleteConfirm(); // hỏi xóa

  const [entity, setEntity] = useState<TypedOrder>(null);
  const {
    data,
    refetch: getEntity
  } = useGetOrderDetail(order_id);
  const {
    data: orderFeeEntities,
    refetch: getEntityOrderFees
  } = useGetOrder_fees(order_id);
  const {
    mutateAsync: deleteOrderFee
  } = useDeleteOrder_fee();
  useEffect(() => {
    if (data) setEntity(data);
  }, [data]);

  /** Xóa Phí */
  const handleDeleteOrderFee = useCallback(async fee_id => {
    const confirmed = await showDeleteConfirm('Xóa phí này này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteOrderFee(fee_id);
    }
  }, []);

  /** Tạo đơn vận chuyển */
  const {
    mutateAsync: createFulfillmentOrder
  } = useCreateFulfillmentOrder();
  const handleCreateFullfillmentOrder = async () => {
    try {
      await createFulfillmentOrder(order_id);
      addNotification('info', 'Tạo đơn vận chuyển thành công!');
    } catch (e) {}
  };

  /** Hủy đơn vận chuyển */
  const {
    mutateAsync: cancelFulfillmentOrder
  } = useCancelFulfillmentOrder();
  const handleCancelFulfilmentOrder = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Hủy đơn vận chuyển?', 'Việc hủy đơn vận chuyển có thể bị đối tác từ chối. Nếu vậy, vui lòng truy cập nhà cung cấp để hủy trực tiếp. Hành động này không thể hoàn tác.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await cancelFulfillmentOrder(order_id);
        addNotification('info', 'Hủy đơn vận chuyển thành công!');
      } catch (e) {
        console.error(e, 'CANCEL_FULFILLMENT_ORDER_ERR_876');
      }
    }
  }, []);
  const {
    mutateAsync: updateOrder,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateOrder();

  /**
   * order_product
   */
  const {
    data: entitiesOrderProduct,
    refetch: getEntityOrderProducts
  } = useGetOrderProducts(order_id);
  const {
    mutate: updateOrderProduct,
    isPending: updatingOrderProduct,
    isSuccess: updateSuccessOrderProduct
  } = useUpdateOrderProduct();
  const {
    mutateAsync: updateOrderStatus,
    isSuccess: updateOrderStatusSuccess
  } = useUpdateOrderStatus();
  const {
    mutate: createOrderProduct
  } = useCreateOrderProduct();
  const {
    mutateAsync: deleteOrderProduct
  } = useDeleteOrderProduct();
  /**
   * Khoá hay không nhỉ?
   */
  const [orderIsLock, setOrderIsLock] = useState<boolean>(true);
  useEffect(() => {
    if (updateOrderStatusSuccess) {
      addNotification('info', __('update_successfully'));
    }
  }, [updateOrderStatusSuccess]);
  useEffect(() => {
    if (updateSuccessOrderProduct) {
      addNotification('info', __('update_successfully'));
    }
  }, [updateSuccessOrderProduct]);

  // hook ...
  useDidMountEffect(() => {
    getEntity();
    getEntityOrderFees();
    getEntityOrderProducts();
  }, [order_id]);

  /**
   * Số tiền thanh toán ....
   */

  const paymentTypeChangeCallback = useCallback(async (new_payment_type: string) => {
    if (!new_payment_type) return;
    try {
      await updateOrder({
        order_id: order_id,
        payment_type: new_payment_type
      });
      let new_entity = {
        ...entity,
        ...{
          payment_type: new_payment_type
        }
      };
      setEntity(new_entity);
    } catch (e) {}
  }, [order_id, entity]);
  const paymentStatusChangeCallback = useCallback(async (new_payment_status: string) => {
    if (!new_payment_status) return;
    try {
      await updateOrder({
        order_id: order_id,
        payment_status: Number(new_payment_status)
      });
      let new_entity = {
        ...entity,
        ...{
          payment_status: Number(new_payment_status)
        }
      };
      setEntity(new_entity);
    } catch (e) {}
  }, [order_id, entity]);

  /**
   * Handle selected order status change ...
   */
  const handleOrderStatusChange = useCallback(async (value: string) => {
    try {
      await updateOrderStatus({
        order_id: order_id,
        order_status: Number(value)
      });
      let new_entity = {
        ...entity,
        ...{
          order_status: Number(value)
        }
      };
      setEntity(new_entity);
    } catch (e) {}
  }, [entity, order_id]);
  const handleCancelOrder = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Hủy đơn này?', 'Việc hủy đơn này không thể hoàn tác. Khi hủy đơn, đơn vị vận chuyển cũng nhận được lệnh hủy vận chuyển, nhưng tùy thuộc vào quy định của từng đối tác. Ngoài ra, khách hàng cũng sẽ nhận được thông báo hủy đơn.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await handleOrderStatusChange('19');
        addNotification('info', 'Hủy đơn thành công!');
      } catch (e) {
        console.error(e, 'CANCEL_ORDER_ERR_876');
      }
    }
  }, [handleOrderStatusChange]);
  const handleCustomerCancelOrder = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Khách yêu cầu hủy đơn này?', 'Việc hủy đơn này không thể hoàn tác. Khi hủy đơn, đơn vị vận chuyển cũng nhận được lệnh hủy vận chuyển, nhưng tùy thuộc vào quy định của từng đối tác.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await handleOrderStatusChange('20');
        addNotification('info', 'Hủy đơn thành công!');
      } catch (e) {
        console.error(e, 'CANCEL_ORDER_ERR_876');
      }
    }
  }, [handleOrderStatusChange]);

  /**
   * Quick note
   */
  const [showQuickNote, setShowQuickNote] = useState(false);
  const [valuetext, setValuetext] = useState('');
  const inputInitialData = useCallback(() => {
    setValuetext(entity.order_note || '');
    setOrderIsLock(entity.order_checked === 1 || entity.order_status > 11);
    if (!currentUserCan(['manage_orders', 'edit_orders'])) {
      setOrderIsLock(true); // tắt hết edit
    }
  }, [entity, currentUserCan]);
  useEffect(() => {
    if (!entity) return;
    inputInitialData();
  }, [entity]);
  useEffect(() => {
    if (updateSuccess === true) {
      setShowQuickNote(false);
    }
  }, [updateSuccess]);
  const saveQuickNoteButtonCallback = async () => {
    try {
      await updateOrder({
        order_id: entity?.order_id,
        order_note: valuetext
      });
    } catch (e) {}
  };
  const handleQuickNoteChange = useCallback((newValue: string) => {
    setValuetext(newValue);
  }, []);

  /**!SECTION
   * Show product form search
   */

  const [showModelProductSearchForm, setShowModelProductSearchForm] = useState(false);
  const onCloseshowModelProductSearchForm = useCallback((data: {
    product_id: string;
    variant_id: string;
  } | null) => {
    setShowModelProductSearchForm(false);
    if (!data) return;
    if (!data.product_id) return;
    // save data and close Modal...
    createOrderProduct({
      order_id: entity?.order_id,
      product_id: data.product_id,
      variant_id: data.variant_id,
      quantity: 1
    });
  }, [entity]);
  /**
   * Thêm một giao dịch
   */

  const [showTransactionModal, setShowTransactionModal] = useState(false);
  function afterCloseTransactionModal() {
    setShowTransactionModal(false);
  }

  /**
   * Thêm chi phí cho một đơn hàng ...
   */
  const [showModelCustomFeeForm, setShowModelCustomFeeForm] = useState(false);

  /**
   * Product list
   */
  const deleteOrderProductCallback = (order_product_id: any) => {
    deleteOrderProduct({
      order_product_id: order_product_id,
      order_id: order_id
    });
  };

  /**
   * Khối xử lý click thay đổi số lượng hàng nhanh...
   */
  const [quantityValue, setQuantityValue] = useState({});
  useEffect(() => {
    let quantityAll = {};
    if (!entitiesOrderProduct) return;
    for (let product of entitiesOrderProduct) {
      quantityAll = {
        ...quantityAll,
        ...{
          [product.order_product_id]: product.quantity
        }
      };
    }
    setQuantityValue(quantityAll);
  }, [entitiesOrderProduct]);
  const updateOrderProductQuantity = (newValue: any, order_product_id: any) => {
    // quantityValue[order_product_id] = newValue;
    if (isNaN(newValue)) return;
    let newString = {
      ...quantityValue,
      ...{
        [order_product_id]: newValue
      }
    };
    setQuantityValue(newString);
  };
  /**
   * Lưu số lượng ngay khi click ra ngoài!
   * @param order_product_id
   */
  const savingQuantityThisIndex = (order_product_id: any) => {
    let newVal = quantityValue[order_product_id];
    updateOrderProduct({
      order_product_id: order_product_id,
      quantity: Number(newVal)
    });
  };

  /**
   * Khối xử lý click thay đổi giá trong từng đơn hàng nhanh ...
   */
  const [pricyValue, setPricyValue] = useState({});
  useEffect(() => {
    let quantityAll = {};
    if (!entitiesOrderProduct) return;
    for (let product of entitiesOrderProduct) {
      quantityAll = {
        ...quantityAll,
        ...{
          [product.order_product_id]: product.price
        }
      };
    }
    setPricyValue(quantityAll);
  }, [entitiesOrderProduct]);
  function updateOrderProductPrice(newValue: any, order_product_id: any) {
    if (isNaN(newValue)) return;
    let newString = {
      ...pricyValue,
      ...{
        [order_product_id]: newValue
      }
    };
    setPricyValue(newString);
  }
  /**
   * Lưu số lượng ngay khi click ra ngoài!
   * @param order_product_id
   */
  const savingPriceForThisIndex = (order_product_id: any) => {
    let newVal = pricyValue[order_product_id];
    updateOrderProduct({
      order_product_id: order_product_id,
      price: Number(newVal)
    });
  };

  /** Khi ấn nút in đơn ... */
  /** https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf */
  const markThisOrderPrintPackingSlip = (order_fulfillment_packingslip_uri: string) => {
    updateOrder({
      order_id: entity?.order_id,
      order_print_packing_slip: 1
    }).catch(e => {});
  };
  const productRows = [];
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalProductUnit, setTotalProductUnit] = useState(0);

  /**
   * Cập nhật tổng giá, realtime
   */
  useEffect(() => {
    if (!entitiesOrderProduct) return;
    let tPrice = 0,
      tProductUnit = 0;
    for (let product of entitiesOrderProduct) {
      tPrice += product.quantity * product.price;
      tProductUnit += product.quantity;
    }
    setTotalPrice(tPrice);
    setTotalProductUnit(tProductUnit);
  }, [entitiesOrderProduct]);

  /**
   * Cập nhật tổng phí. realtime
   */
  useEffect(() => {
    let tPrice = 0;
    if (!orderFeeEntities) return;
    for (let productFee of orderFeeEntities) {
      tPrice += productFee.order_fee_value;
    }
    setTotalFee(tPrice);
  }, [orderFeeEntities]);

  /** Đối soát đơn hàng */
  const {
    mutate: doOrderChecked,
    isSuccess: orderCheckedSuccess
  } = useOrderChecked();
  const orderCheckedCallback = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Đánh dấu đã đối soát?', 'Khi đối soát, đơn sẽ bị khóa lại và không chỉnh sửa được nữa. Ngoài ra các hoa hồng, chiết khấu sẽ được tính cho người nhận nếu đơn là đối soát hoàn thành.');
    if (confirmed) {
      doOrderChecked(order_id);
    }
  }, [order_id]);
  useEffect(() => {
    if (orderCheckedSuccess) {
      addNotification('info', 'Thành công! Đơn đã được đánh dấu đối soát.');
    }
  }, [orderCheckedSuccess]);
  if (entitiesOrderProduct) {
    for (let orderProductData of entitiesOrderProduct) {
      // tính total ...
      productRows.push([<div style={{
        width: '250px'
      }}>
          <BlockStack gap="400">
            <InlineStack gap="400" align="start" blockAlign="center">
              <Thumbnail size="small" source={helpers.getMediaLink(orderProductData.product.product_thumbnail_to_media ? orderProductData.product.product_thumbnail_to_media.media_thumbnail ? orderProductData.product.product_thumbnail_to_media.media_thumbnail['128x128'] : '' : '')} alt="" />
              <BlockStack>
                <Text as="p" fontWeight="bold">
                  <Link removeUnderline url={`/product/edit/${orderProductData.product_id}`}>
                    {orderProductData.product.product_name}
                  </Link>
                </Text>
                <Text as="p" tone="subdued">
                  SKU: {orderProductData.product.product_sku ?? '-'}
                </Text>
              </BlockStack>
            </InlineStack>
            {orderProductData.product_variant && <div style={{
            marginLeft: '15px'
          }}>
                <InlineStack align="start" blockAlign="center" gap="400">
                  <Thumbnail size="extraSmall" source={helpers.getMediaLink(orderProductData.product_variant.variant_thumbnail_to_media ? orderProductData.product_variant.variant_thumbnail_to_media.media_thumbnail ? orderProductData.product_variant.variant_thumbnail_to_media.media_thumbnail['128x128'] : '' : '')} alt="" />
                  <BlockStack>
                    <Text as="p">
                      <strong>{orderProductData.product_variant.variant_name}</strong>{' '}
                      <span>SKU: {orderProductData.product_variant.variant_sku ?? '-'}</span>
                    </Text>
                  </BlockStack>
                </InlineStack>
              </div>}
          </BlockStack>
        </div>,
      // product.product_price,
      <div style={{
        width: '80px'
      }}>
          <TextField label="" max={99} min={0} disabled={updatingOrderProduct || orderIsLock} value={pricyValue[orderProductData.order_product_id]} onBlur={() => {
          savingPriceForThisIndex(orderProductData.order_product_id);
        }} onChange={(newValue: any) => {
          updateOrderProductPrice(newValue, orderProductData.order_product_id);
        }} autoComplete="off" />
        </div>,
      // product.order_quantity,
      <div style={{
        width: '80px'
      }}>
          <TextField label="" max={99} min={1} disabled={updatingOrderProduct || orderIsLock} value={quantityValue[orderProductData.order_product_id]} onBlur={() => {
          savingQuantityThisIndex(orderProductData.order_product_id);
        }} onChange={(newValue: any) => {
          updateOrderProductQuantity(newValue, orderProductData.order_product_id);
        }} autoComplete="off" />
        </div>, entity?.order_status < 11 ? <Link onClick={() => {
        deleteOrderProductCallback(orderProductData.order_product_id);
      }}>
            <Icon source={XSmallIcon} tone="base" />
          </Link> : null]);
    }
  }
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelectedTab(selectedTabIndex), []);
  const ActualPage = <>
      <Page title={entity?.order_pnr} subtitle={'Tạo bởi ' + entity?.user?.user_login + ' / ' + String(entity ? helpers.subtractDate(entity.createdAt) : '1') + ' ngày trước'} backAction={{
      content: 'Danh sách đơn hàng',
      url: '/order'
    }} titleMetadata={<>
            <Badge icon={ReceiptDollarFilledIcon} tone="info-strong">
              {entity?.payment_type}
            </Badge>{' '}
            {getBadgeOfPaymentMethod(entity?.payment_status)} {getOrderStatusBadge(entity?.order_status)}{' '}
            {entity?.order_checked ? <Badge tone="attention">Đã đối soát</Badge> : null}{' '}
          </>} secondaryActions={[{
      content: 'In hoá đơn',
      icon: ReceiptIcon,
      disabled: true,
      onAction: () => {
        window.open(process.env.REACT_APP_BACKEND_URL + '/orders/invoice/print/' + entity?.order_id, '_blank');
      }
    }, {
      content: 'Nhãn đóng hàng',
      disabled: !entity?.order_fulfillment?.order_fulfillment_packingslip_uri,
      icon: entity?.order_print_packing_slip ? CheckSmallIcon : MenuHorizontalIcon,
      // CheckSmallIcon
      url: process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + '' + entity?.order_fulfillment?.order_fulfillment_packingslip_uri,
      target: '_blank',
      onAction: () => {
        // printJS(process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + '' + entity?.order_fulfillment?.order_fulfillment_packingslip_uri);
        markThisOrderPrintPackingSlip(entity?.order_fulfillment?.order_fulfillment_packingslip_uri);
      }
    }]} pagination={{
      label: <>{dateandtime.format(new Date(Number(entity?.createdAt)), 'DD/MM')}</>,
      hasPrevious: entity?.pagination?.prev ? true : false,
      hasNext: entity?.pagination?.next ? true : false,
      nextURL: '/order/view/' + entity?.pagination?.next,
      previousURL: '/order/view/' + entity?.pagination?.prev,
      previousTooltip: 'Đơn cũ hơn',
      nextTooltip: 'Đơn mới hơn'
    }}>
        {entity?.order_fulfillment?.order_fulfillment_code && <>
            <Banner title={`Cẩn thận! Đơn hàng này đã được ${entity?.order_fulfillment?.logistic_service?.logistic_name} xử lý. Mọi việc chỉnh sửa có thể không được chấp nhận.`} tone="warning"></Banner>
            <br />
          </>}

        <div style={{
        marginBottom: '20px'
      }}>
          <InlineStack align="space-between" blockAlign="start" gap="400">
            <div className="order_status_transition">
              <Text as="h3" tone="subdued">
                SALE XỬ LÝ
              </Text>
              <div className="order_status_transition_wrap">
                <button className="link_format block block_2" onClick={() => handleOrderStatusChange(`10`)}>
                  {entity?.order_status === 10 && <StatusActiveIcon />}
                  <span>{orderStatusJson[10]}</span>
                </button>
                <button className="link_format block block_3" onClick={() => handleOrderStatusChange(`11`)}>
                  {entity?.order_status === 11 && <StatusActiveIcon />}
                  <span>{orderStatusJson[11]}</span>
                </button>
                <button className="link_format block block_4" onClick={() => handleOrderStatusChange(`12`)}>
                  {entity?.order_status > 11 && <StatusActiveIcon />}
                  <span>{orderStatusJson[12]}</span>
                </button>
              </div>
            </div>
            <div className="order_status_transition">
              <Text as="h3" tone="subdued">
                DỊCH VỤ VẬN CHUYỂN
              </Text>
              <div className="order_status_transition_wrap">
                <BlockStack gap="100">
                  <InlineStack align="start" blockAlign="start">
                    <button className="link_format block block_5" onClick={() => handleCreateFullfillmentOrder()}>
                      {entity?.order_fulfillment?.order_fulfillment_code && <StatusActiveIcon />}
                      <span>Tạo đơn giao vận</span>
                    </button>
                    <button className="link_format block block_6" onClick={() => handleCancelFulfilmentOrder()}>
                      <span>Hủy đơn giao vận</span>
                    </button>
                    {/* </InlineStack> */}
                    {/* <InlineStack align="start" blockAlign="start"> */}
                    <button className="link_format block block_7" onClick={() => handleOrderStatusChange(`13`)}>
                      {entity?.order_status === 13 && <StatusActiveIcon />}
                      <span>{orderStatusJson[13]}</span>
                    </button>
                    <button className="link_format block block_8" onClick={() => handleOrderStatusChange(`14`)}>
                      {entity?.order_status === 14 && <StatusActiveIcon />}
                      <span>{orderStatusJson[14]}</span>
                    </button>
                    <button className="link_format block block_9" onClick={() => handleOrderStatusChange(`15`)}>
                      {entity?.order_status === 15 && <StatusActiveIcon />}
                      <span>{orderStatusJson[15]}</span>
                    </button>
                  </InlineStack>
                </BlockStack>
              </div>
            </div>
            <div className="order_status_transition">
              <Text as="h3" tone="subdued">
                CHUNG
              </Text>
              <div className="order_status_transition_wrap">
                <button className="link_format block block_10" onClick={() => handleCancelOrder()}>
                  {entity?.order_status === 19 && <StatusActiveIcon />}
                  <span>{orderStatusJson[19]}</span>
                </button>
                <button className="link_format block block_11" onClick={() => handleCustomerCancelOrder()}>
                  {entity?.order_status === 20 && <StatusActiveIcon />}
                  <span>{orderStatusJson[20]}</span>
                </button>
                <button className="link_format block block_12" onClick={() => orderCheckedCallback()}>
                  {entity?.order_checked === 1 && <LockIcon />}
                  <span>Đối soát</span>
                </button>
              </div>
            </div>
          </InlineStack>
        </div>

        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap="400">
                <InlineStack align="space-between" blockAlign="center">
                  <div className="small-icon">
                    <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                      <ProductIcon /> Danh sách sản phẩm trong đơn
                    </Text>
                  </div>
                  <Button icon={PlusIcon} variant="plain" disabled={orderIsLock} onClick={() => setShowModelProductSearchForm(true)}>
                    Thêm sản phẩm
                  </Button>
                </InlineStack>

                {entitiesOrderProduct?.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'text']} headings={['Sản phẩm', 'Giá', 'S.lượng', '']} rows={productRows} /> : <Text as="p" tone="critical">
                    Chưa có sản phẩm nào trong đơn này! Click "thêm sản phẩm" để thêm!
                  </Text>}
                <Divider />
              </BlockStack>

              <br />
              <Box padding={'0'}>
                <Text fontWeight="bold" as="p">
                  Tóm tắt
                </Text>
                <InlineStack gap="400">
                  <InlineStack>Số hàng</InlineStack>
                  <InlineStack>
                    <strong>{totalProductUnit} đơn vị</strong>
                  </InlineStack>
                  <InlineStack>Tổng tiền hàng</InlineStack>
                  <InlineStack>
                    <strong> {helpers.formatNumber(totalPrice, ',')} vnd</strong>
                  </InlineStack>
                </InlineStack>
              </Box>
            </Card>

            <br />

            <Card>
              <BlockStack gap="400">
                <InlineStack align="space-between" blockAlign="center">
                  <div className="small-icon">
                    <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                      Các chi phí
                      <Tooltip content="Bạn có thể lên phí tùy chỉnh tùy theo thỏa thuận giữa sale và khách hàng.">
                        <Icon source={QuestionCircleIcon} />
                      </Tooltip>
                    </Text>
                  </div>
                  <Button icon={PlusIcon} variant="plain" disabled={orderIsLock} onClick={() => setShowModelCustomFeeForm(true)}>
                    Thêm chi phí tùy chỉnh
                  </Button>
                </InlineStack>

                <Divider />

                {!helpers.isEmpty(orderFeeEntities) ? <ResourceList resourceName={{
                singular: 'fee',
                plural: 'fees'
              }} items={orderFeeEntities} renderItem={item => {
                const {
                  order_fee_name,
                  order_fee_value,
                  order_fee_id
                } = item;
                return <ResourceList.Item id={order_fee_name.toLocaleLowerCase()} url={'#'} key={`` + order_fee_id}>
                          <InlineStack align="space-between">
                            <Text as="p">{order_fee_name}</Text>
                            <InlineStack align="center" gap={'400'} blockAlign="center">
                              <Text as="span">{helpers.formatNumber(order_fee_value, ',')}</Text>
                              <Button variant="plain" disabled={orderIsLock} icon={DeleteIcon} onClick={() => {
                        handleDeleteOrderFee(order_fee_id);
                      }}></Button>
                            </InlineStack>
                          </InlineStack>
                        </ResourceList.Item>;
              }} /> : <Text as="p" tone="subdued">
                    Chưa có phí thêm nào.
                  </Text>}
              </BlockStack>
            </Card>

            <br />

            <Card padding="0">
              <Box padding={'400'}>
                <BlockStack gap="400">
                  <InlineStack align="space-between" blockAlign="center">
                    <div className="small-icon">
                      <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                        <ProductCostIcon /> Giao dịch
                      </Text>
                    </div>
                    <Button icon={PlusIcon} variant="plain" disabled={orderIsLock} onClick={() => setShowTransactionModal(true)}>
                      Thêm giao dịch
                    </Button>
                  </InlineStack>

                  <div>
                    <OrderTransaction order_id={order_id} />
                  </div>
                </BlockStack>
              </Box>

              <Box background="bg" padding={'400'}>
                <BlockStack gap="400">
                  <Text as="h3" fontWeight="bold" variant="headingMd">
                    Tổng phải thanh toán
                  </Text>
                  <ResourceList resourceName={{
                  singular: 'fee',
                  plural: 'fees'
                }} items={[...[{
                  id: 'totalPrice',
                  name: 'Tổng tiền hàng',
                  destructive: false,
                  description: '',
                  value: helpers.formatNumber(totalPrice, ',')
                }, {
                  id: 'totalFee',
                  destructive: false,
                  name: 'Tổng tiền phí',
                  description: '',
                  value: helpers.formatNumber(totalFee, ',')
                }, {
                  id: 'totalAlreadyPay',
                  destructive: false,
                  name: 'Đã thanh toán',
                  description: '',
                  value: helpers.formatNumber(entity?.order_total_paid, ',')
                }], ...(entity?.order_to_voucher?.map((element, index) => {
                  return {
                    id: 'random_' + index,
                    destructive: true,
                    name: element.voucher_name,
                    description: element.voucher_code + ' /' + element.voucher_value + ' ' + element.voucher_value_unit + '/' + element.voucher_category,
                    value: element.voucher_after_calculator
                  };
                }) ?? []), ...[{
                  id: 'totalMustPay',
                  destructive: false,
                  name: 'Phải thanh toán',
                  description: '',
                  value: helpers.formatNumber(entity?.order_total_mustpay, ',')
                }]]} renderItem={item => {
                  const {
                    id,
                    name,
                    value,
                    description,
                    destructive
                  } = item;
                  return <ResourceList.Item id={id} url={'#'} key={`kakak_ANNAOOO_` + id} media={destructive === true && <Icon source={CollectionFeaturedIcon} />}>
                          <InlineStack align="space-between">
                            <BlockStack>
                              <Text as="p" tone="caution">
                                {name}
                              </Text>
                              <Text as="p" variant="bodyXs" tone="subdued">
                                {description}
                              </Text>
                            </BlockStack>
                            <BlockStack>{value}</BlockStack>
                          </InlineStack>
                        </ResourceList.Item>;
                }} />
                </BlockStack>
              </Box>
            </Card>

            <br />
            <Card>
              <OrderAssign entity={entity} />
            </Card>
            <br />

            <div style={{
            margin: '0 -15px 15px -15px'
          }}>
              <Tabs tabs={[{
              id: 'order_log_tab',
              content: 'Nhật ký nội bộ đơn hàng'
            }, {
              id: 'transportation_tab',
              content: 'Vận chuyển'
            }, {
              id: 'order_bounty',
              content: 'Hoa hồng / Chiết khấu'
            }]} selected={selectedTab} onSelect={handleTabChange}></Tabs>
              <Divider />
            </div>
            {/* {selectedTab === 0 && <Comments objectId={order_id} commentType="order_comment" title="Nhận xét"></Comments>} */}
            {selectedTab === 0 && <Suspense fallback={<>Xin đợi chút ...</>}>
                <OrderLogComponent objectId={order_id} title="Nhật ký nội bộ đơn hàng" />{' '}
              </Suspense>}
            {selectedTab === 1 && <OrderTransportLog order_id={order_id} />}
            {selectedTab === 2 && <OrderBountyList order_id={order_id} />}
          </Layout.Section>

          <Layout.Section variant="oneThird">
            <BlockStack gap="400">
              <Suspense fallback={<>Loading...</>}>
                <OrderTransportInformation entity={entity} />
              </Suspense>

              <Card>
                <BlockStack gap="400">
                  <Text as="h4" variant="headingMd">
                    Thanh toán
                  </Text>
                  <Select label={'Cách thanh toán'} disabled={orderIsLock} options={PAYMENT_TYPE_CONST.map((el, index) => {
                  return {
                    label: __(`order_payment_type_via_` + el),
                    value: `${el}`
                  };
                })} onChange={val => paymentTypeChangeCallback(val)} value={`` + entity?.payment_type} />

                  <Select label={'Tình trạng thanh toán'} disabled={orderIsLock} options={PAYMENT_STATUS_CONST.map((el, index) => {
                  return {
                    label: __(el),
                    value: `${index}`
                  };
                })} onChange={val => paymentStatusChangeCallback(val)} value={`` + entity?.payment_status} />
                </BlockStack>
              </Card>

              <Suspense fallback={<>Loading...</>}>
                <OrderCustomerInformation entity={entity} />
              </Suspense>

              <Card>
                <Box>
                  <InlineStack align="space-between" blockAlign="center">
                    <div className="small-icon">
                      <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
                        <NoteIcon /> Ghi chú đơn hàng
                      </Text>
                    </div>

                    <Button variant="plain" disabled={entity?.order_checked === 1} onClick={() => setShowQuickNote(true)}>
                      sửa
                    </Button>
                  </InlineStack>
                </Box>

                <Box>
                  {showQuickNote ? <>
                      <TextField label="" multiline={2} value={valuetext} onChange={handleQuickNoteChange} autoComplete="off" maxLength={250} showCharacterCount />
                      <Button variant="plain" onClick={saveQuickNoteButtonCallback} loading={updating}>
                        Lưu
                      </Button>
                      &nbsp; hoặc &nbsp;
                      <Button variant="plain" onClick={() => {
                    setShowQuickNote(false);
                  }}>
                        Huỷ
                      </Button>
                    </> : <div style={{
                  color: '#999',
                  padding: '15px 0'
                }}>{entity?.order_note ?? 'chưa có ghi chú'}</div>}
                </Box>
              </Card>
            </BlockStack>
          </Layout.Section>
        </Layout>
      </Page>

      <br />
      <br />

      <QuickSearchProductAndVariant show={showModelProductSearchForm} onClose={onCloseshowModelProductSearchForm} />
    </>;
  return <>
      <Helmet>
        <title>Thông tin đơn hàng</title>
      </Helmet>

      <ShowCustomFee show={showModelCustomFeeForm} order_id={order_id} onClose={() => setShowModelCustomFeeForm(false)} />
      <TransactionNewModal show={showTransactionModal} order_id={entity?.order_id} default_customer_id={entity?.user_id} default_money_quantity={entity?.order_total_price + entity?.order_total_fee} onClose={afterCloseTransactionModal} />

      {entity ? ActualPage : <SkeletonLoading />}
      {/*
       <Grid columns={{ xs: 1, sm: 2 }}>
        {lgUp && (
          <Grid.Cell columnSpan={{ xs: 6, sm: 2 }}>
            <OrderSidebarQuickSuft order_id={order_id} />
          </Grid.Cell>
        )}
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 8 }}>{entity ? ActualPage : <SkeletonLoading />}</Grid.Cell>
       </Grid> */}
    </>;
}