import React, { useCallback, useState } from 'react';
import { Button, Form, InlineStack, Modal, Select, TextField } from '@shopify/polaris';
import { TypedSocial, useUpdateSocialPost } from 'queries/social.query';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
import { useNotification } from 'context/NotificationContext';
import __ from 'languages/index';
import axios from 'axios';
import __helpers from 'helpers/index';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import 'media/css/social_post.scss';
const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
export default function EditPostModal({
  post,
  onClose,
  onSave
}: {
  post: TypedSocial;
  onClose: () => void;
  onSave: (updatedPost: TypedSocial) => void;
}) {
  const [content, setContent] = useState(post?.social_post_content || '');
  const [category, setCategory] = useState(post?.social_category || 'business');
  const [productID, setProductID] = useState(post?.social_suggest_product_id);
  const [mediaList, setMediaList] = useState(post?.social_post_media?.map(item => ({
    media: {
      ...item.media,
      media_url: __helpers.getMediaLink(item?.media?.media_thumbnail ? item?.media?.media_thumbnail['128x128'] : item?.media?.media_url)
    }
  })) || []);
  const [images, setImages] = useState(mediaList.map(media => media?.media?.media_url) || []);
  const [imageFiles, setImageFiles] = useState<{
    file: File;
    media_url: string;
  }[]>([]);
  const {
    mutateAsync: updateSocialPost,
    isPending: updating
  } = useUpdateSocialPost();
  const {
    addNotification
  } = useNotification();
  const handleContentChange = useCallback(value => setContent(value), []);
  const handleCategoryChange = useCallback(value => setCategory(value), []);
  const handleProductChange = useCallback(product => {
    setProductID(product[0]?.product_id);
  }, []);
  const handleImageChange = e => {
    const files: any = Array.from(e.target.files);
    const newImageURLs = files.map((file: any) => URL.createObjectURL(file));
    setImages(prevImages => [...prevImages, ...newImageURLs]);
    setImageFiles(prevFiles => [...prevFiles, ...files?.map((file, index) => ({
      file: file,
      media_url: newImageURLs[index]
    }))]);
  };
  const handleRemoveImage = image => {
    setMediaList(prevImages => prevImages.filter(preImage => preImage?.media?.media_url !== image));
    setImages(prevImages => prevImages.filter(preImage => preImage !== image));
    setImageFiles(prevFiles => prevFiles.filter(preImage => preImage?.media_url !== image));
  };
  const saveToServer = async (file: File): Promise<string | undefined> => {
    const fd = new FormData();
    fd.append('files', file);
    let {
      data
    } = await axios.post(REACT_APP_AJAX_UPLOAD_URL, fd);
    return data.media_id;
  };
  const handleSubmit = async () => {
    try {
      const uploadedImages = [];
      for (let file of imageFiles) {
        let media_id = await saveToServer(file.file);
        if (media_id) uploadedImages.push({
          media: {
            media_id: media_id,
            media_url: file.media_url
          }
        });
      }
      // Preserve the order of images based on the current `images` array
      let allImages = [...mediaList, ...uploadedImages];
      const mediaIDsInOrder = images.map(img =>
      // @ts-ignore
      allImages.find(item => item?.media?.media_url === img)?.media?.media_id).filter(Boolean) as string[];
      const updatedPost = await updateSocialPost({
        social_post_id: post?.social_post_id,
        social_post_content: content,
        social_category: category,
        social_suggest_product_id: productID ?? null,
        media_id: [...mediaIDsInOrder]
      });
      addNotification('info', __('Post updated successfully!'));

      // Fetch the updated post details
      const response = await axios.get(`/social_post/${updatedPost.social_post_id}`);
      onSave(response.data);
      onClose();
    } catch (e) {
      console.error(e);
    }
  };
  const categoryOptions = [{
    label: 'Thông báo',
    value: 'live'
  }, {
    label: 'Đăng bán',
    value: 'business'
  }];
  const onDragEnd = result => {
    if (!result.destination) return; // Nếu kéo ra ngoài vùng cho phép thì không thay đổi
    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    setImages(reorderedImages);
    console.log(reorderedImages);
  };
  return <Modal open onClose={onClose} title="Sửa bài đăng" primaryAction={{
    content: 'Lưu',
    onAction: handleSubmit,
    loading: updating
  }} secondaryActions={[{
    content: 'Huỷ',
    onAction: onClose
  }]}>
      <Modal.Section>
        <Form onSubmit={handleSubmit}>
          <TextField label="Nội dung" value={content} onChange={handleContentChange} multiline autoComplete="off" />
          <div style={{
          marginBottom: '16px'
        }}></div>
          <Select label="Loại bài đăng" options={categoryOptions} value={category} onChange={handleCategoryChange} />
          <div style={{
          marginBottom: '16px'
        }}></div>
          <InlineStack gap="200">
            <QuickSearchProduct2 allowMultiple={false} current_product_id={productID ? [productID] : []} label={'Sản phẩm đính kèm'} onClose={handleProductChange} />
          </InlineStack>
          <div style={{
          marginBottom: '16px'
        }}></div>
          <div>
            {/*<label htmlFor="image-upload-edit" className="Polaris-Label">Thêm ảnh</label>*/}
            <input type="file" id="image-upload-edit" multiple onChange={handleImageChange} style={{
            display: 'none'
          }} />
            <Button onClick={() => document.getElementById('image-upload-edit').click()}>Chọn ảnh</Button>
            <div style={{
            marginBottom: '16px'
          }}></div>


            {images.length > 0 && <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="images" direction="horizontal">
                  {provided => <div className="image-preview-outerwrap">
                      <div className="image-previews" {...provided.droppableProps} ref={provided.innerRef}>
                        {images.map((image, index) => <Draggable key={index} draggableId={`image-${index}`} index={index}>
                            {(provided, snapshot) => <div className="image-preview-container" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{
                      ...provided.draggableProps.style,
                      display: 'inline-block',
                      margin: snapshot.isDragging ? '0 10px' : '0',
                      width: '100px',
                      height: '100px'
                    }}>
                                <img src={image} alt={`Preview ${index}`} className="image-preview" style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }} />
                                <button type="button" onClick={() => handleRemoveImage(image)}>
                                  &times;
                                </button>
                              </div>}
                          </Draggable>)}
                        {provided.placeholder}
                      </div>
                    </div>}
                </Droppable>
              </DragDropContext>}


          </div>
        </Form>
      </Modal.Section>
    </Modal>;
}