import { Autocomplete, Icon, InlineError, InlineStack, Tag, Text } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import ProductCollectionModalAddAndEdit from './product_collection.edit.modal';
import { useGetProductCollections } from 'queries/product_collections.query';
export default function QuickSearchProductCollection({
  label = '',
  onClose,
  errorMessage = '',
  current_collection_id,
  allowMultiple = true
}: {
  label?: string;
  onClose: (val: string[]) => void;
  errorMessage?: string;
  current_collection_id: string[];
  allowMultiple?: boolean;
}) {
  const [initialQuery, setInitialQuery] = useState({
    limit: 5,
    query: '',
    collection_status: 1,
    sort: 'createdAt:desc'
  });
  const {
    refetch: fetchDataFromServerCallback,
    isLoading: searching,
    data: entities
  } = useGetProductCollections(initialQuery);

  /**
   *  list
   */

  const [postCategoryList, setPostCategoryList] = useState(null);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setPostCategoryList(body);
    }
  }, [entities]);

  /**
   * Assign cho ai đóa
   */
  const [categorySelected, setCategorySelected] = useState<string[] | undefined>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [productCategoryListForQuickOption, setproductCategoryListForQuickOption] = useState<{
    label: string;
    value: string;
  }[]>([]);
  useEffect(() => {
    if (!current_collection_id) return;
    setCategorySelected(current_collection_id);
  }, [current_collection_id]);

  /** Chỉ khi hiện tại khác với current thì mới gọi sang bên kia ... */
  const callmebackCallback = useCallback(categorySelected => {
    if (JSON.stringify(current_collection_id) !== JSON.stringify(categorySelected)) {
      onClose(categorySelected);
    }
  }, [current_collection_id, onClose]);
  useEffect(() => {
    let innerProductCollectionData = [];
    if (postCategoryList) for (let collectionData of postCategoryList) {
      innerProductCollectionData.push({
        value: collectionData.collection_id,
        label: collectionData.collection_name
      });
    }
    setproductCategoryListForQuickOption(innerProductCollectionData);
  }, [postCategoryList]);
  const updateTextThenDelayCallback = useMemo(() => helpers.debounce((_value: any) => updateTextThenDoitCallback?.call(this, _value), 500), []);
  const updateTextThenDoitCallback = useCallback((value: string) => {
    fetchDataFromServerCallback();
    return value;
  }, []);
  const updateText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    const cloneInitialQuery = {
      ...initialQuery,
      ...{
        query: value
      }
    };
    setInitialQuery(cloneInitialQuery);
    updateTextThenDelayCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    let selectedLabel = '';
    selected.map(selectedItem => {
      const matchedOption = productCategoryListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      selectedLabel = matchedOption.label;
      return matchedOption && matchedOption.label;
    });
    setCategorySelected(selected);
    //   setUserQuickSearchText(selectedValue[0] || '');
    if (!allowMultiple) setUserQuickSearchText(selectedLabel);
    callmebackCallback(selected);
  }, [allowMultiple, callmebackCallback, productCategoryListForQuickOption]);

  /** ABC DEF GHI */

  const removeTag = useCallback((tag: string) => () => {
    const options = [...categorySelected];
    options.splice(options.indexOf(tag), 1);
    setCategorySelected(options);
    callmebackCallback(options);
  }, [categorySelected]);
  const [showQuickCreate, setShowQuickCreate] = useState(false);
  const verticalContentMarkup = categorySelected?.length > 0 ? <InlineStack align="start" blockAlign="center" gap="200">
        {categorySelected.map(option => {
      if (!option) return null;
      let l = productCategoryListForQuickOption.find(el => el.value === String(option))?.label;
      if (!l) return null;
      return <Tag key={`option_x_${option}`} onRemove={removeTag(option)}>
              {l}
            </Tag>;
    })}
      </InlineStack> : <Text as="p" tone="subdued" variant="bodySm">
        Chưa có một bộ sưu tập nào ...
      </Text>;
  const textFieldSelectUser = <>
      <Autocomplete.TextField error={errorMessage ? true : false} onChange={updateText} label={label} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} verticalContent={verticalContentMarkup} placeholder="Tìm kiếm" autoComplete="off" id="TextFieldSelectCollection" />
      <div style={{
      marginTop: '4px'
    }}>
        <InlineError message={errorMessage} fieldID="TextFieldSelectCollection" />
      </div>
    </>;
  return <>
      <ProductCollectionModalAddAndEdit show={showQuickCreate} onClose={(category_id: any) => {
      setShowQuickCreate(false);
      if (category_id) {
        setCategorySelected([category_id]);
      }
    }} />

      <Autocomplete actionBefore={{
      accessibilityLabel: 'Tạo bộ sưu tập mới',
      content: 'Tạo một bộ sưu tập mới',
      ellipsis: true,
      helpText: 'Tạo bộ sưu tập mới và chỉnh sửa sau',
      icon: PlusCircleIcon,
      onAction: () => {
        setShowQuickCreate(!showQuickCreate);
      }
    }} allowMultiple={allowMultiple} options={productCategoryListForQuickOption} selected={categorySelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={searching} />
    </>;
}