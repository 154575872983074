import { Autocomplete, Icon } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useGetStocks } from 'queries/stock.query';

/**
 * Tìm kiếm nhanh một cửa hàng ...
 * Chưa dùng ở đâu luôn ...
 */

export default function QuickSearchStock({
  current_store_id,
  disabled = false,
  title = 'Tìm kho hàng',
  onClose
}: {
  current_store_id: bigint | string;
  disabled?: boolean;
  title?: string;
  onClose: (val: string) => void;
}) {
  /**!SECTION
   * Select người handler
   */

  const [mainQuery, setMainQuery] = useState({
    query: '',
    sort: 'createdAt:desc',
    limit: 5
  });
  const {
    isLoading: loading,
    refetch: getEntities,
    data: stockList
  } = useGetStocks(mainQuery);
  const [entities, setEntities] = useState<any[]>(null);
  useEffect(() => {
    if (!stockList) return;
    let {
      body,
      totalItems
    } = stockList;
    setEntities(body);
  }, [stockList]);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    let innerUser = [{
      value: '',
      label: 'Chưa chọn một kho nào'
    }];
    if (entities) for (let stock of entities) {
      innerUser.push({
        value: stock.stock_id,
        label: stock.stock_name
      });
      if (stock.stock_id === current_store_id) {
        setUserQuickSearchText(stock.stock_name);
      }
    }
    setUserListForQuickOption(innerUser);
  }, [entities, current_store_id]);
  const onQueryToServer = useCallback((keyword: string) => {
    setMainQuery({
      query: keyword,
      sort: 'createdAt:desc',
      limit: 10
    });
    getEntities();
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose?.call(this, selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <>
      <Autocomplete.TextField onChange={updateUserText} label={title} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm" autoComplete="off" disabled={disabled} />
    </>;
  return <>
      <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={loading} />
    </>;
}