// DeleteConfirmContext.tsx
import { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Modal, Text } from '@shopify/polaris';
import helpers from 'helpers/index';
interface DeleteConfirmContextProps {
  showDeleteConfirm: (title: string, content?: string) => Promise<boolean>;
}
const DeleteConfirmContext = createContext<DeleteConfirmContextProps | undefined>(undefined);
export const DeleteConfirmProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('Bạn chắc chắn chứ? Hành động này không thể khôi phục.');
  const [resolveCallback, setResolveCallback] = useState<(value: boolean) => void>(() => {});
  const showDeleteConfirm = useCallback((title: string, content = 'Bạn chắc chắn chứ?') => {
    setModalTitle(title);
    setModalContent(content);
    setModalOpen(true);
    return new Promise<boolean>(resolve => {
      setResolveCallback(() => resolve);
    });
  }, []);
  const handleClose = useCallback(() => {
    setModalOpen(false);
    resolveCallback(false);
  }, [resolveCallback]);
  const handleConfirm = useCallback(async () => {
    setModalOpen(false);
    await helpers.sleep(1000); // giả lập tải
    resolveCallback(true);
  }, [resolveCallback]);
  return <DeleteConfirmContext.Provider value={{
    showDeleteConfirm
  }}>
      {children}
      <Modal open={isModalOpen} onClose={handleClose} title={modalTitle} primaryAction={{
      content: 'Tiếp tục?',
      onAction: handleConfirm
    }} secondaryActions={[{
      content: 'Hủy',
      onAction: handleClose
    }]}>
        <Modal.Section>
          <Text as="p">{modalContent}</Text>
        </Modal.Section>
      </Modal>
    </DeleteConfirmContext.Provider>;
};
export const useDeleteConfirm = () => {
  const context = useContext(DeleteConfirmContext);
  if (!context) {
    throw new Error('useDeleteConfirm must be used within a DeleteConfirmProvider');
  }
  return context;
};