import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import brand_list from './brand.list';
import brand_detail from './brand.detail';

/**
 *   Create index file
 */

export default function Brands() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = brand_list;
      break;
    case 'view':
      ActualPage = brand_detail;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}