import { DataTable, EmptyState, Layout, Page, Text, Badge, Card, InlineStack, Thumbnail, Button } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import emptyIMG from '../../media/images/empty.png';
import SkeletonLoading from 'components/skeletonPageLoading';
import NotificationNew from './notification.new';
import { PlusIcon, RefreshIcon, XIcon } from '@shopify/polaris-icons';
import SimpleFilter from 'components/simpleFilter';
import __, { ___ } from 'languages/index';
import { useCancelsendNotifitcation, useGetNotifications, useResendNotifitcation } from 'queries/notification.query';
import { Helmet } from 'react-helmet-async';
import { TypedNotification } from 'interface/notification.model';
export default function NotificationList() {
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    isLoading: loading,
    refetch: loadData,
    isFetched
  } = useGetNotifications(mainQuery);
  const {
    mutate: resendNotification
  } = useResendNotifitcation();
  const {
    mutate: cancelSendNotification
  } = useCancelsendNotifitcation();
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) window.history.replaceState(null, 'Notification', '/notification' + buildURLSearch);
    loadData();
  }, [mainQuery, search]);
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (!isFetched) return;
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [isFetched, data]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const resendNotificationCallback = useCallback(async (notification_id: string) => {
    resendNotification(notification_id);
  }, []);
  const cancelsendNotificationCallback = useCallback(async (notification_id: string) => {
    cancelSendNotification(notification_id);
  }, []);

  /**
   * END QUERY NOTIFICATION DETAIL
   */

  function notificationStatusBadge(status: number) {
    switch (status) {
      case -2:
        return <Badge size="small">Không gửi</Badge>;
      case -1:
        return <Badge size="small">Đã hủy</Badge>;
      case 0:
        return <Badge size="small" tone="info">
            Chờ gửi
          </Badge>;
      case 1:
        return <Badge size="small" tone="info-strong">
            Đang gửi
          </Badge>;
      case 2:
        return <Badge size="small" tone="success">
            Đã gửi
          </Badge>;
      case 3:
        return <Badge size="small" tone="warning-strong">
            Gửi lỗi
          </Badge>;
    }
  }
  const renderItem = (notification: TypedNotification, index: number) => {
    const {
      notification_id,
      notification_title,
      notification_image,
      notification_content,
      notification_category,
      notification_channel,
      notification_user,
      createdAt,
      notification_meta
    } = notification;
    let notification_meta_status = notification_meta?.notification_meta_status;
    let notification_schedule = notification_meta?.notification_meta_schedule ?? false;
    return [notification_id, <InlineStack gap="400" align="start" blockAlign="center">
        <Thumbnail size="small" source={notification_image || 'https://placehold.co/60x60'} alt={''} />
        <Text as="p">{notification_title}</Text>
      </InlineStack>, notificationStatusBadge(notification_meta_status), notification_user || notification_channel, <time key={index + '11'}>
        {notification_schedule ? dateandtime.format(new Date(Number(notification_schedule)), 'DD-MM-YYYY HH:mm:ss') : '-'}
      </time>, __(`notification_category_${notification_category}`), <InlineStack gap={'400'} align="start" blockAlign="center">
        <Button variant="plain" onClick={() => resendNotificationCallback(notification_id)} icon={RefreshIcon}></Button>
        <Button variant="plain" onClick={() => cancelsendNotificationCallback(notification_id)} icon={XIcon}></Button>
      </InlineStack>, <time key={index + '1122'}>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss') : '-'}</time>];
  };
  const PagesList = entities && entities.length > 0 ? <>
        <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric']} headings={['ID', 'Nội dung', 'Trạng thái', 'Gửi đến', 'Lịch gửi', 'Danh mục', '-', 'Ngày tạo']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : <EmptyState heading="Không có thông báo!" image={emptyIMG}>
        <p>Oh, Không có gì ở đây. Hãy thử bỏ bộ lọc hoặc tạo mới.</p>
      </EmptyState>;
  const Actual_page = <>
      <Page title="Quản lý thông báo" secondaryActions={[{
      content: 'Thêm thông báo',
      icon: PlusIcon,
      onAction: () => setUploadModelactive(true)
    }]}>
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />

              {PagesList}
            </Card>
            <br />
            {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>;
  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(() => setUploadModelactive(active => !active), []);
  return <>
      <Helmet>
        <title>Quản lý thông báo</title>
      </Helmet>
      {entities === null ? <SkeletonLoading /> : Actual_page}

      <NotificationNew showModal={uploadModelactive} closeModal={toggleUpdateActive} />
    </>;
}