import { useCallback, useEffect, useState } from 'react';
import { Form, Modal, Checkbox, InlineStack, BlockStack, Text, Button, Divider } from '@shopify/polaris';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import { TypedUser_capacity_group, useGetUserCapacityPermission, useUpdateUserCapacityGroup } from 'queries/user_capacity_group.query';
import { CheckboxIcon, XSmallIcon } from '@shopify/polaris-icons';
export default function UserCapacityGroupPermissionModal({
  open = false,
  entity,
  onClose
}: {
  open: boolean;
  onClose: Function;
  entity: TypedUser_capacity_group;
}) {
  const {
    mutateAsync: updateUserCapacity,
    isPending: savingCapacity
  } = useUpdateUserCapacityGroup();
  const {
    data: __allPermission
  } = useGetUserCapacityPermission();
  const {
    addNotification
  } = useNotification();
  const [permissions, setPermissions] = useState<{
    [key: string]: string[];
  }>({});
  const [initialPermissions, setInitialPermissions] = useState<{
    [key: string]: string[];
  }>({});
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: {
      [key: string]: boolean;
    };
  }>({});
  useEffect(() => {
    if (__allPermission) {
      setPermissions(__allPermission);
      const parsedPermissions = entity?.user_capacity_group_permission || {};
      setInitialPermissions(parsedPermissions);

      // Initialize checkbox states based on parsedPermissions
      const newCheckboxStates: {
        [key: string]: {
          [key: string]: boolean;
        };
      } = {};
      for (const group in __allPermission) {
        newCheckboxStates[group] = __allPermission[group].reduce((acc: any, action: string) => {
          acc[action] = parsedPermissions[group]?.includes(action) || false;
          return acc;
        }, {});
      }
      setCheckboxStates(newCheckboxStates);
    }
  }, [__allPermission, entity]);
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
    setCheckboxStates(Object.keys(permissions).reduce((acc, group) => {
      acc[group] = permissions[group].reduce((subAcc, action) => {
        subAcc[action] = initialPermissions[group]?.includes(action) || false;
        return subAcc;
      }, {});
      return acc;
    }, {}));
  }, [permissions, initialPermissions, onClose]);
  const handleCheckboxChange = (group: string, action: string, value: boolean) => {
    setCheckboxStates(prev => ({
      ...prev,
      [group]: {
        ...prev[group],
        [action]: value
      }
    }));
  };
  const handleSubmit = async () => {
    const updatedPermissions: {
      [key: string]: string[];
    } = {};
    for (const group in checkboxStates) {
      updatedPermissions[group] = Object.keys(checkboxStates[group]).filter(action => checkboxStates[group][action]);
    }
    try {
      await updateUserCapacity({
        capacity_group_id: entity?.capacity_group_id,
        user_capacity_group_permission: updatedPermissions
      });
      addNotification('info', __('updated_successfully'));
      closeModal();
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message || __('error_updating_permissions');
      addNotification('error', errorMessage);
    }
  };
  if (!Object.keys(permissions).length) {
    return <div>Loading...</div>;
  }
  return <Modal open={open} title={`Cài đặt quyền cho nhóm quyền ${entity?.capacity_group_name}`} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: savingCapacity,
    onAction: handleSubmit
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeModal
  }]}>
      <Modal.Section>
        {/* Thêm nút chọn tất cả / bỏ chọn tất cả */}
        <InlineStack gap="200" align="start" blockAlign="center">
          <Button icon={CheckboxIcon} variant="plain" onClick={() => {
          // Chọn tất cả
          setCheckboxStates(Object.keys(permissions).reduce((acc, group) => {
            acc[group] = permissions[group].reduce((subAcc, action) => {
              subAcc[action] = true;
              return subAcc;
            }, {});
            return acc;
          }, {}));
        }}>
            {__('select_all')}
          </Button>
          <Button icon={XSmallIcon} variant="plain" onClick={() => {
          // Bỏ chọn tất cả
          setCheckboxStates(Object.keys(permissions).reduce((acc, group) => {
            acc[group] = permissions[group].reduce((subAcc, action) => {
              subAcc[action] = false;
              return subAcc;
            }, {});
            return acc;
          }, {}));
        }}>
            {__('deselect_all')}
          </Button>
        </InlineStack>
        <br />
        <Divider />
        <br />
        <Form onSubmit={handleSubmit}>
          <BlockStack gap="400">
            {Object.keys(permissions).map(group => <BlockStack key={group} gap="100">
                <Text as="p" fontWeight="bold">
                  {__(group)}
                </Text>
                <InlineStack gap="200">
                  {permissions[group].map(action => <Checkbox key={action} label={__(action)} checked={checkboxStates[group]?.[action] || false} onChange={value => handleCheckboxChange(group, action, value)} />)}
                </InlineStack>
              </BlockStack>)}
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}