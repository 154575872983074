import { Autocomplete, BlockStack, Checkbox, ChoiceList, ContextualSaveBar, DescriptionList, Divider, FormLayout, InlineGrid, LegacyStack, Page, Select, Tag, Text, TextField, Thumbnail } from '@shopify/polaris';
import { lengthLessThan, notEmpty, notEmptyString, useField, useForm } from '@shopify/react-form';
import { FormPrompt } from 'components/FormPrompt';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
import QuickSearchProductBrand from 'components/quickSearchProductBrand';
import QuickSearchProductCategory2 from 'components/quickSearchProductCategory';
import QuickSearchUser from 'components/quickSearchUser';
import QuickSearchVoucher from 'components/quickSearchVoucher';
import QuickSearchWallet from 'components/quickSearchWallet';
import TextFieldRichText from 'components/TextFieldRichText';
import { USER_ACTIVITY_LIST } from 'constant';
import { useNotification } from 'context/NotificationContext';
import { useUserRoleContext } from 'context/UserRoleContext';
import dateandtime from 'date-and-time';
import QuickSearchProductCollection from 'entities/product/product_collection/quickSearchProductCollection';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import 'media/css/voucher.scss';
import voucher_default_image from 'media/images/voucher_default_image.png';
import { ALLOWED_VOUCHER_DATA_KEY, SUGGEST_NOTIFICATION_DATA_KEY } from 'queries/notification.query';
import { useCreateVoucher, useMutateGetVoucher, useUpdateVoucher } from 'queries/voucher.query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
export default function NewVoucher() {
  const history = useNavigate();
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: createVoucher,
    isSuccess: createSuccess,
    isPending: creating
  } = useCreateVoucher();
  const {
    mutateAsync: getEntity,
    data: entity
  } = useMutateGetVoucher();
  const {
    mutateAsync: updateVoucher,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateVoucher();

  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();

  /** Hết code lấy user role ra ... */

  /** Delay load lại, vì không delay nó sẽ không hiện đúng phần đã chọn */
  const [getEntitySuccess, setGetEntitySuccess] = useState(false);
  const delayLoading = useCallback(async () => {
    await __helpers.sleep(1000);
    setGetEntitySuccess(true);
  }, []);
  useEffect(() => {
    delayLoading();
  }, []);
  let {
    voucher_id
  } = useParams();
  useEffect(() => {
    if (!voucher_id) return;
    getEntity(voucher_id);
  }, [voucher_id]);
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('updated_successfully'));
      getEntity(voucher_id);
    }
  }, [updateSuccess, voucher_id]);
  useEffect(() => {
    if (createSuccess) {
      addNotification('info', __('created_successfully'));
    }
  }, [createSuccess]);
  const useFields = {
    voucher_name: useField<string>({
      value: entity?.voucher_name ?? '',
      validates: [notEmptyString('Trường này không được để trống')]
    }),
    voucher_excerpt: useField<string>({
      value: entity?.voucher_excerpt ?? '',
      validates: [notEmptyString('Trường này không được để trống'), lengthLessThan(255, 'Mô tả ngắn không được quá 255 ký tự')]
    }),
    voucher_description: useField<string>({
      value: entity?.voucher_description ?? '',
      validates: []
    }),
    voucher_category: useField<string>({
      value: entity?.voucher_category ?? 'shipping',
      validates: []
    }),
    is_private: useField<string>({
      value: String(entity?.is_private ?? '0'),
      validates: []
    }),
    is_transferable: useField<string>({
      value: String(entity?.is_transferable ?? '0'),
      validates: []
    }),
    is_multiple: useField<string>({
      value: String(entity?.is_multiple ?? '0'),
      validates: []
    }),
    is_active: useField<string>({
      value: String(entity?.is_active ?? '1'),
      validates: []
    }),
    voucher_code: useField<string>({
      value: entity?.voucher_code ?? '',
      validates: [notEmptyString('Trường này không được để trống'), inputValue => {
        if (!/^[a-zA-Z0-9_]+$/.test(inputValue)) return 'Chỉ sử dụng chữ cái, số và dấu gạch dưới.';
        if (inputValue && __helpers.isUTF8(inputValue)) return 'Chỉ sử dụng chữ cái và số.';
      }]
    }),
    voucher_thumbnail: useField<string>({
      value: entity?.voucher_thumbnail,
      validates: []
    }),
    voucher_thumbnail_cache: useField<string>({
      value: entity?.voucher_thumbnail_to_media ? entity.voucher_thumbnail_to_media?.media_thumbnail ? entity.voucher_thumbnail_to_media?.media_thumbnail['512x512'] : entity.voucher_thumbnail_to_media?.media_url : undefined,
      validates: []
    }),
    voucher_valid_from: useField<string>({
      value: entity?.voucher_valid_from ? dateandtime.format(new Date(Number(entity?.voucher_valid_from)), 'HH:mm DD-MM-YYYY') : dateandtime.format(new Date(), 'HH:mm DD-MM-YYYY'),
      validates: [notEmptyString('Trường này không được để trống.')]
    }),
    voucher_valid_to: useField<string>({
      value: entity?.voucher_valid_to ? dateandtime.format(new Date(Number(entity?.voucher_valid_to)), 'HH:mm DD-MM-YYYY') : dateandtime.format(new Date(), 'HH:mm DD-MM-YYYY'),
      validates: [notEmptyString('Trường này không được để trống.')]
    }),
    voucher_count_max: useField<string>({
      value: String(entity?.voucher_count_max ?? '1'),
      validates: [inputValue => {
        if (!__helpers.isNumeric(inputValue)) return 'Phải là một số hợp lệ.';
        if (Number(inputValue) < 1) return 'Phải là một số hợp lệ lớn hơn 1.';
      }]
    }),
    voucher_count_max_per_user: useField<string>({
      value: String(entity?.voucher_count_max_per_user ?? '0'),
      validates: [inputValue => {
        if (!__helpers.isNumeric(inputValue)) return 'Phải là một số hợp lệ.';
        if (Number(inputValue) < 0) return 'Phải là một số hợp lệ lớn hơn 0.';
      }]
    }),
    voucher_value: useField<string>({
      value: String(entity?.voucher_value || '1'),
      validates: [inputVal => {
        if (__helpers.parseNumeric(inputVal) < 1) return 'Phải là một số hợp lệ lớn hơn 1.';
      }, notEmpty('Không được để trống trường này.')]
    }),
    voucher_value_max: useField<string>({
      value: String(entity?.voucher_value_max || '1'),
      validates: [inputVal => {
        if (__helpers.parseNumeric(inputVal) < 1) return 'Phải là một số hợp lệ lớn hơn 1.';
      }, notEmpty('Không được để trống trường này.')]
    }),
    voucher_value_unit: useField<string>({
      value: String(entity?.voucher_value_unit || 'percent'),
      validates: []
    }),
    voucher_rebate_wallet: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'rebate_wallet')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_data_screen: useField<string>({
      value: entity?.voucher_action ? JSON.parse(entity?.voucher_action).screen : ALLOWED_VOUCHER_DATA_KEY[0],
      validates: []
    }),
    voucher_data_param: useField<string>({
      value: entity?.voucher_action ? JSON.parse(entity?.voucher_action).param : '',
      validates: []
    }),
    voucher_rule_user_role: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'user_role')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_buyer_day_of_register: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'buyer_day_of_register')?.voucher_rule_value || '0',
      validates: []
    }),
    voucher_buyer_minimum_order_number: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'buyer_minimum_order_number')?.voucher_rule_value || '0',
      validates: []
    }),
    voucher_rule_user_activity: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'user_activity')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_min_totalpay_in_order: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'min_totalpay_in_order')?.voucher_rule_value || '0',
      validates: []
    }),
    voucher_min_product_quantity_in_order: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'min_product_quantity_in_order')?.voucher_rule_value || '0',
      validates: []
    }),
    voucher_product_condition_match_mode: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'product_condition_match_mode')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_product_category_match_list: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'product_category_match_list')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_product_match_list: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'product_match_list')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_user_id: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'user_id')?.voucher_rule_value || '',
      validates: []
    }),
    voucher_payment_type: useField<string>({
      value: entity?.voucher_rule?.find(el => el.voucher_rule_key === 'payment_type')?.voucher_rule_value || '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (__helpers.isEmpty(entity)) {
          let createdResult = await createVoucher({
            voucher_name: values.voucher_name,
            voucher_code: String(values.voucher_code).toUpperCase(),
            voucher_excerpt: values.voucher_excerpt,
            voucher_description: values.voucher_description,
            voucher_count_max: Number(values.voucher_count_max ?? 1),
            voucher_count_max_per_user: Number(values.voucher_count_max_per_user ?? 1),
            voucher_thumbnail: values.voucher_thumbnail,
            voucher_valid_from: new Date(dateandtime.parse(values.voucher_valid_from, 'HH:mm DD-MM-YYYY')).getTime(),
            voucher_valid_to: new Date(dateandtime.parse(values.voucher_valid_to, 'HH:mm DD-MM-YYYY')).getTime(),
            voucher_category: values.voucher_category,
            voucher_value: __helpers.parseNumeric(values.voucher_value),
            voucher_value_unit: values.voucher_value_unit,
            voucher_value_max: __helpers.parseNumeric(values.voucher_value_max),
            is_private: Number(values.is_private),
            is_multiple: Number(values.is_multiple),
            is_active: Number(values.is_active),
            is_transferable: Number(values.is_transferable),
            voucher_action: JSON.stringify({
              screen: values.voucher_data_screen,
              param: values.voucher_data_param
            }),
            rules: [{
              voucher_rule_key: 'user_id',
              voucher_rule_value: String(values.voucher_user_id)
            }, {
              voucher_rule_key: 'payment_type',
              voucher_rule_value: String(values.voucher_payment_type)
            }, {
              voucher_rule_key: 'user_role',
              voucher_rule_value: String(values.voucher_rule_user_role)
            }, {
              voucher_rule_key: 'rebate_wallet',
              voucher_rule_value: String(values.voucher_rebate_wallet)
            }, {
              voucher_rule_key: 'user_activity',
              voucher_rule_value: String(values.voucher_rule_user_activity)
            }, {
              voucher_rule_key: 'buyer_day_of_register',
              voucher_rule_value: values.voucher_buyer_day_of_register
            }, {
              voucher_rule_key: 'buyer_minimum_order_number',
              voucher_rule_value: values.voucher_buyer_minimum_order_number
            }, {
              voucher_rule_key: 'min_totalpay_in_order',
              voucher_rule_value: String(values.voucher_min_totalpay_in_order)
            }, {
              voucher_rule_key: 'min_product_quantity_in_order',
              voucher_rule_value: String(values.voucher_min_product_quantity_in_order)
            }, {
              voucher_rule_key: 'product_condition_match_mode',
              voucher_rule_value: String(values.voucher_product_condition_match_mode)
            }, {
              voucher_rule_key: 'product_category_match_list',
              voucher_rule_value: String(values.voucher_product_category_match_list)
            }, {
              voucher_rule_key: 'product_match_list',
              voucher_rule_value: String(values.voucher_product_match_list)
            }]
          });
          history(`/voucher/edit/${createdResult.voucher_id}`);
        } else {
          await updateVoucher({
            voucher_id: voucher_id,
            voucher_name: values.voucher_name,
            voucher_code: String(values.voucher_code).toUpperCase(),
            voucher_excerpt: values.voucher_excerpt,
            voucher_description: values.voucher_description,
            voucher_count_max: Number(values.voucher_count_max ?? 1),
            voucher_count_max_per_user: Number(values.voucher_count_max_per_user ?? 1),
            voucher_thumbnail: values.voucher_thumbnail,
            voucher_valid_from: new Date(dateandtime.parse(values.voucher_valid_from, 'HH:mm DD-MM-YYYY')).getTime(),
            voucher_valid_to: new Date(dateandtime.parse(values.voucher_valid_to, 'HH:mm DD-MM-YYYY')).getTime(),
            voucher_category: values.voucher_category,
            voucher_value: __helpers.parseNumeric(values.voucher_value),
            voucher_value_unit: values.voucher_value_unit,
            voucher_value_max: __helpers.parseNumeric(values.voucher_value_max),
            is_private: Number(values.is_private),
            is_multiple: Number(values.is_multiple),
            is_active: Number(values.is_active),
            is_transferable: Number(values.is_transferable),
            voucher_action: JSON.stringify({
              screen: values.voucher_data_screen,
              param: values.voucher_data_param
            }),
            rules: [{
              voucher_rule_key: 'user_id',
              voucher_rule_value: String(values.voucher_user_id)
            }, {
              voucher_rule_key: 'payment_type',
              voucher_rule_value: String(values.voucher_payment_type)
            }, {
              voucher_rule_key: 'user_role',
              voucher_rule_value: String(values.voucher_rule_user_role)
            }, {
              voucher_rule_key: 'rebate_wallet',
              voucher_rule_value: String(values.voucher_rebate_wallet)
            }, {
              voucher_rule_key: 'user_activity',
              voucher_rule_value: String(values.voucher_rule_user_activity)
            }, {
              voucher_rule_key: 'buyer_day_of_register',
              voucher_rule_value: values.voucher_buyer_day_of_register
            }, {
              voucher_rule_key: 'buyer_minimum_order_number',
              voucher_rule_value: values.voucher_buyer_minimum_order_number
            }, {
              voucher_rule_key: 'min_totalpay_in_order',
              voucher_rule_value: String(values.voucher_min_totalpay_in_order)
            }, {
              voucher_rule_key: 'min_product_quantity_in_order',
              voucher_rule_value: String(values.voucher_min_product_quantity_in_order)
            }, {
              voucher_rule_key: 'product_condition_match_mode',
              voucher_rule_value: String(values.voucher_product_condition_match_mode)
            }, {
              voucher_rule_key: 'product_category_match_list',
              voucher_rule_value: String(values.voucher_product_category_match_list)
            }, {
              voucher_rule_key: 'product_match_list',
              voucher_rule_value: String(values.voucher_product_match_list)
            }]
          });
        }
      } catch (e) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const handUploadError = useCallback((err: any) => {
    addNotification('error', err?.message ?? 'unknown_error');
  }, []);

  /******************************** *
   * ADVANCE
   ******************************** */

  const [selectedOptions, setSelectedOptions] = useState<string[]>(String(useFields.voucher_rule_user_role.value || '').split(','));
  useEffect(() => {
    let originalValue = entity?.voucher_rule?.find(el => el.voucher_rule_key === 'user_role')?.voucher_rule_value || '';
    setSelectedOptions(originalValue.split(','));
  }, [entity]);
  useEffect(() => {
    useFields.voucher_rule_user_role.onChange(selectedOptions.filter(el => el !== '').join(','));
  }, [selectedOptions]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(allRoles);
  }, [allRoles]);
  const updateText = useCallback((value: string) => {
    setInputValue(value);
    if (value === '') {
      setOptions(allRoles);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = allRoles.filter(option => option.label.match(filterRegex));
    setOptions(resultOptions);
  }, [allRoles]);
  const removeTag = useCallback((tag: string) => () => {
    const options = [...selectedOptions];
    options.splice(options.indexOf(tag), 1);
    setSelectedOptions(options);
  }, [selectedOptions]);
  const verticalContentMarkup = selectedOptions.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions.map(option => {
      if (!option) return null;
      let tagLabel = __(`user_role_` + option);
      return <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;

  /******************************** *
   * ADVANCE
   ******************************** */
  const deselectedOptions2 = useMemo(() => USER_ACTIVITY_LIST.map(activity => {
    return {
      label: __("ACTION_" + activity),
      value: activity
    };
  }), []);
  const [selectedOptions2, setSelectedOptions2] = useState<string[]>([]);
  const [inputValue2, setInputValue2] = useState('');
  const [options2, setOptions2] = useState(deselectedOptions2);
  useEffect(() => {
    let originalValue = entity?.voucher_rule?.find(el => el.voucher_rule_key === 'user_activity')?.voucher_rule_value || '';
    setSelectedOptions2(originalValue.split(','));
  }, [entity]);
  useEffect(() => {
    useFields.voucher_rule_user_activity.onChange(selectedOptions2.join(','));
  }, [selectedOptions2]);
  const updateText2 = useCallback((value: string) => {
    setInputValue2(value);
    if (value === '') {
      setOptions2(deselectedOptions2);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = deselectedOptions2.filter(option => option.label.match(filterRegex));
    setOptions2(resultOptions);
  }, [deselectedOptions2]);
  const removeTag2 = useCallback((tag: string) => () => {
    const options = [...selectedOptions2];
    options.splice(options.indexOf(tag), 1);
    setSelectedOptions2(options);
  }, [selectedOptions2]);
  const verticalContentMarkup2 = selectedOptions2.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions2.map(option => {
      if (!option) return null;
      let tagLabel = __(`ACTION_` + option);
      return <Tag key={`option${option}`} onRemove={removeTag2(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;
  const renderVoucherParamField = useCallback(() => {
    switch (fields.voucher_data_screen.value) {
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_CATEGORY':
        return <QuickSearchProductCategory2 label="Chọn danh mục" current_category_id={fields.voucher_data_param.value ? [fields.voucher_data_param.value] : []} onClose={categories => fields.voucher_data_param.onChange(categories.pop())} allowMultiple={false} />;
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_COLLECTION':
        return <QuickSearchProductCollection label="Chọn bộ sưu tập" current_collection_id={fields.voucher_data_param.value ? [fields.voucher_data_param.value] : []} onClose={collections => fields.voucher_data_param.onChange(collections.pop())} allowMultiple={false} />;
      case 'NAVIGATION_LIST_PRODUCT_SCREEN_BRAND':
        return <QuickSearchProductBrand label="Chọn thương hiệu" current_brand_id={fields.voucher_data_param.value ? [fields.voucher_data_param.value] : []} onClose={brands => fields.voucher_data_param.onChange(brands.pop())} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_PRODUCT_SCREEN':
        return <QuickSearchProduct2 label="Chọn sản phẩm" current_product_id={fields.voucher_data_param.value ? [fields.voucher_data_param.value] : []} onClose={products => fields.voucher_data_param.onChange(products.pop()?.product_id)} allowMultiple={false} />;
      case 'NAVIGATION_DETAIL_VOUCHER_SCREEN':
        return <QuickSearchVoucher title="Chọn voucher" current_voucher_id={fields.voucher_data_param.value} onClose={voucher => fields.voucher_data_param.onChange(voucher?.voucher_id)} />;
      default:
        return <TextField label="Thông số" {...fields.voucher_data_param} autoComplete="off" />;
    }
  }, [fields.voucher_data_screen.value, fields.voucher_data_param.value]);
  const mainPage = <Page narrowWidth title={voucher_id ? `Cập nhật Voucher` : `Tạo mới một voucher`} backAction={{
    content: 'Back',
    onAction: () => history('/voucher')
  }}>
      <div className="voucher" key={`voucher_`}>
        <div className="voucher-date">Hạn đến {useFields.voucher_valid_to.value}</div>
        <div className="voucher-content">
          <div className="voucher-value">{useFields.voucher_name.value || 'voucher_name'}</div>
          <div className="voucher-icon">
            {useFields.voucher_thumbnail_cache.value ? <Thumbnail source={__helpers.getMediaLink(useFields.voucher_thumbnail_cache.value)} alt={''} /> : <img src={voucher_default_image} alt="Gift Icon" />}
          </div>
        </div>
        <div style={{
        width: 225
      }}></div>
        <p className="voucher-terms">{useFields.voucher_excerpt.value || 'voucher_excerpt'}</p>
        <p className="voucher-terms">{(useFields.voucher_code.value || 'voucher_code').toUpperCase()}</p>
      </div>

      <br />

      <BlockStack gap="400">
        <br />
        <Divider />
        <Text as="h3" variant="headingLg">
          Thông tin chung
        </Text>
        <InlineGrid gap="200" columns={{
        xs: '',
        md: ['oneThird', 'twoThirds']
      }}>
          <QuickUploadImage height={150} width={150} placeholder={__helpers.getMediaLink(useFields.voucher_thumbnail_cache.value, 'https://placehold.co/128x128')} onSuccess={res => {
          useFields.voucher_thumbnail.onChange(res.media_id);
          useFields.voucher_thumbnail_cache.onChange(res.media_url);
        }} onError={handUploadError} />
          <BlockStack gap="400">
            <TextField label="Tên của Voucher" autoFocus autoComplete="off" {...useFields.voucher_name} />
            <TextField label="Mô tả ngắn" autoComplete="off" {...useFields.voucher_excerpt} showCharacterCount maxLength={255} />
          </BlockStack>
        </InlineGrid>

        <FormLayout.Group>
          <TextField label="Mã Voucher" autoComplete="off" {...useFields.voucher_code} />
          <TextField label="Số lượng phát hành" autoComplete="off" {...useFields.voucher_count_max} value={__helpers.formatNumberWithCommas(useFields.voucher_count_max.value)} onChange={v => useFields.voucher_count_max.onChange(`` + __helpers.parseNumeric(v))} />
        </FormLayout.Group>

        <FormLayout.Group>
          <TextField label="Giá trị của voucher" autoComplete="off" {...useFields.voucher_value} value={__helpers.formatNumberWithCommas(useFields.voucher_value.value)} onChange={v => useFields.voucher_value.onChange(`` + __helpers.parseNumeric(v))} labelAction={{
          content: __('commission_method_label_' + useFields.voucher_value_unit.value),
          onAction: () => {
            useFields.voucher_value_unit.onChange(useFields.voucher_value_unit.value === 'percent' ? 'fixed_amount' : 'percent');
          }
        }} suffix={useFields.voucher_value_unit.value === 'percent' ? '%' : 'fixed'} />
          <TextField label="Giá trị tối đa khi áp dụng" suffix="fixed" autoComplete="off" {...useFields.voucher_value_max} value={__helpers.formatNumberWithCommas(useFields.voucher_value_max.value)} onChange={v => useFields.voucher_value_max.onChange(`` + __helpers.parseNumeric(v))} />
          <TextField label="Số lượt tối đa / mỗi người mua" suffix="lượt mua" autoComplete="off" helpText="Điền 0 nếu không giới hạn số lượt mua / người mua" {...useFields.voucher_count_max_per_user} value={__helpers.formatNumberWithCommas(useFields.voucher_count_max_per_user.value)} onChange={v => useFields.voucher_count_max_per_user.onChange(`` + __helpers.parseNumeric(v))} />
        </FormLayout.Group>

        <ChoiceList title="Áp dụng cho" choices={[{
        label: 'Mã giảm giá phí ship',
        value: 'shipping'
      }, {
        label: 'Mã giảm giá trên đơn hàng',
        value: 'order'
      }, {
        label: 'Hoàn lại tiền vào ví',
        value: 'rebate',
        renderChildren: (isSelected: boolean) => {
          return isSelected && <QuickSearchWallet onClose={v => useFields.voucher_rebate_wallet.onChange(v)} title="" current_wallet_id={useFields.voucher_rebate_wallet.value} />;
        }
      }]} selected={[useFields.voucher_category.value]} onChange={v => useFields.voucher_category.onChange(v[0])} />

        <ChoiceList title="Chế độ hiển thị" choices={[{
        label: 'Riêng tư, chia sẻ nội bộ, chỉ admin thấy',
        value: '1'
      }, {
        label: 'Hiển thị trên mọi danh sách',
        value: '0'
      }]} selected={[useFields.is_private.value]} onChange={v => useFields.is_private.onChange(v[0])} />

        <ChoiceList title="Áp dụng kết hợp với các voucher khác?" choices={[{
        label: 'Không kết hợp, sử dụng độc lập',
        value: '0'
      }, {
        label: 'Cho phép',
        value: '1'
      }]} selected={[useFields.is_multiple.value]} onChange={v => useFields.is_multiple.onChange(v[0])} />

        <Checkbox label="Cho phép sử dụng" helpText="Nếu tắt, người mua sẽ không thấy và không sử dụng được voucher này nữa." checked={useFields.is_active.value === '1'} onChange={v => useFields.is_active.onChange(v === true ? '1' : '0')} />

        <FormLayout.Group>
          <TextField label="Hiệu lực từ ngày" autoComplete="off" placeholder="Giờ:phút Ngày-tháng-năm" {...useFields.voucher_valid_from} />
          <TextField label="Đến ngày" autoComplete="off" placeholder="Giờ:phút Ngày-tháng-năm" {...useFields.voucher_valid_to} />
        </FormLayout.Group>
        <TextFieldRichText label="Chính sách sử dụng / mô tả"
      // maxLength={65000}
      initialData={entity?.voucher_description ?? ''} onCallback={e => fields.voucher_description.onChange(e)} />
      </BlockStack>
      <br />
      <Divider />
      <br />
      <BlockStack gap="400">
        <Text as="h3" variant="headingLg">
          Điều kiện nâng cao
        </Text>

        <FormLayout>
          <Text as="p">Khi click vào voucher, Di chuyển tới màn hình nào?</Text>
          <FormLayout.Group>
            <Select options={ALLOWED_VOUCHER_DATA_KEY.map(screen => {
            return {
              label: `${SUGGEST_NOTIFICATION_DATA_KEY.includes(screen) ? "⭐ " : ""}${__('MOBILE_' + screen)}`,
              value: screen
            };
          })} label="Màn hình" value={fields.voucher_data_screen.value} onChange={v => fields.voucher_data_screen.onChange(v)} />
            {renderVoucherParamField()}
          </FormLayout.Group>
        </FormLayout>

        <DescriptionList items={[{
        term: 'Điều kiện người mua',
        description: <BlockStack gap={'400'}>
                  <QuickSearchUser current_user_id={useFields.voucher_user_id.value} title={`Chỉ dành cho một người duy nhất?`} user_role="" onClose={(userData: any) => {
            useFields.voucher_user_id.onChange(userData.user_id.toString());
          }} />
                  <Text as="p" variant="bodySm">
                    * Nếu dành riêng cho họ, họ sẽ nhận được thông báo qua thiết bị di động. Tuy nhiên nếu có điều kiện nâng cao bên dưới, họ vẫn phải
                    thỏa mãn.
                  </Text>

                  <Checkbox label="Cho phép chuyển nhượng?" helpText="Nếu bật, và voucher được set cho 1 người duy nhất, nó có thể được sử dụng bởi một người khác." checked={useFields.is_transferable.value === '1'} onChange={v => useFields.is_transferable.onChange(v === true ? '1' : '0')} />

                  <Autocomplete allowMultiple options={options} selected={selectedOptions} textField={<>
                        <Autocomplete.TextField onChange={updateText} label="Vai trò người mua" value={inputValue} placeholder="Khách hàng, cộng tác viên" verticalContent={verticalContentMarkup} autoComplete="off" />
                      </>} onSelect={setSelectedOptions} listTitle="Danh sách các vai trò" />

                  <TextField placeholder="Để trống để không cài đặt" suffix={'Ngày'} autoComplete="off" label="Số ngày người dùng đăng ký" {...useFields.voucher_buyer_day_of_register} />
                  <TextField placeholder="Để trống để không cài đặt" suffix={'Số đơn'} autoComplete="off" label="Người mua đã mua tối thiểu" {...useFields.voucher_buyer_minimum_order_number} />

                  <Autocomplete allowMultiple options={options2} selected={selectedOptions2} textField={<>
                        <Autocomplete.TextField onChange={updateText2} label="Người mua đã từng hành động" value={inputValue2} placeholder={`${__("ACTION_" + USER_ACTIVITY_LIST[2])}, ${__("ACTION_" + USER_ACTIVITY_LIST[4])},v.v...`} verticalContent={verticalContentMarkup2} autoComplete="off" />
                      </>} onSelect={setSelectedOptions2} listTitle="Danh sách các hành động" />
                </BlockStack>
      }, {
        term: 'Điều kiện đơn hàng',
        description: <BlockStack gap="400">
                  <TextField placeholder="0" suffix={'đ'} autoComplete="off" label="Tổng tiền hàng tối thiểu trong đơn mua" {...useFields.voucher_min_totalpay_in_order} />
                  <TextField placeholder="0" suffix={'sản phẩm'} autoComplete="off" label="Tổng số hàng trong đơn tối thiểu" {...useFields.voucher_min_product_quantity_in_order} />
                  <Select label="Hình thức thanh toán" options={[{
            label: 'Mọi hình thức thanh toán',
            value: ''
          }, {
            label: 'Thanh toán COD',
            value: 'cod'
          }, {
            label: 'Thanh toán trực tuyến',
            value: 'bank'
          }, {
            label: 'Thanh toán tiền mặt',
            value: 'cash'
          }]} value={useFields.voucher_payment_type.value} onChange={v => useFields.voucher_payment_type.onChange(v)} />
                </BlockStack>
      }, {
        term: 'Điều kiện về sản phẩm',
        description: <BlockStack gap="400">
                  <ChoiceList title="Chọn chế độ khớp ngành hàng" choices={[{
            label: 'Không cài đặt',
            value: ''
          }, {
            label: 'Một trong các sản phẩm',
            value: 'someof',
            helpText: 'Chỉ cần một trong các sản phẩm trong giỏ khớp với ngành hàng'
          }, {
            label: 'Mọi sản phẩm trong đơn hàng',
            value: 'everyof',
            helpText: 'Mọi sản phẩm trong giỏ hàng phải khớp với ngành hàng'
          }]} selected={[useFields.voucher_product_condition_match_mode.value]} onChange={v => useFields.voucher_product_condition_match_mode.onChange(v[0])} />
                  <QuickSearchProductCategory2 label="Khớp với một ngành hàng" current_category_id={String(entity?.voucher_rule?.find(el => el.voucher_rule_key === 'product_category_match_list')?.voucher_rule_value || '').split(',')} onClose={v => useFields.voucher_product_category_match_list.onChange(String(v))} />
                  <Text as="p">Chỉ định một sản phẩm cụ thể</Text>
                  <QuickSearchProduct2 current_product_id={String(entity?.voucher_rule?.find(el => el.voucher_rule_key === 'product_match_list')?.voucher_rule_value || '').split(',')} label={''} onClose={v => {
            let A = v.map(el => el.product_id);
            useFields.voucher_product_match_list.onChange(String(A));
          }} />
                </BlockStack>
      }]} />
      </BlockStack>
    </Page>;
  return <>
      <Helmet>
        <title>{voucher_id ? `Cập nhật Voucher` : `Tạo mới một voucher`}</title>
      </Helmet>
      {getEntitySuccess ? mainPage : <>Loading...</>}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <FormPrompt hasUnsavedChanges={dirty} />
      {dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
      onAction: submit,
      loading: updating || creating,
      disabled: !dirty
    }} discardAction={{
      content: 'Hoàn tác',
      onAction: Userreset
    }} />}
    </>;
}