import { compiledBlock as _compiledBlock } from "million/react";
const AffiliateDefaultSetup_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      <br />
      <br />
      <br />
      <br />
      <br />

      {_props.v2}
    </slot>, {
  name: "AffiliateDefaultSetup_1",
  portals: ["v0", "v1", "v2"]
});
const AffiliateDefaultSetup = function AffiliateDefaultSetup() {
  const {
    data: entity,
    isPending: loading
  } = useGetDefaultAffiliateSetting();
  const {
    mutateAsync: createEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useCreateDefaultAffiliateSetting();
  const {
    addNotification
  } = useNotification();

  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();

  /** Hết code lấy user role ra ... */

  useEffect(() => {
    if (updateSuccess) addNotification('info', __('update_successfully'));
  }, [updateSuccess]);
  const useFields = {
    product_commission: useField<TypedProductCommission[]>({
      value: helpers.isEmpty(entity?.commission || []) ? [{
        commission_level: 1,
        commission_value: 0,
        commission_method: 'percent'
      }] : entity?.commission,
      validates: []
    }),
    product_commission_advance: useField<TypedProductCommissionAdvance[]>({
      value: entity?.commission_advance || [],
      validates: []
    }),
    product_rebate: useField<{
      rebate_value: number;
      rebate_method: string;
      user_role: string;
    }[]>({
      value: helpers.isEmpty(entity?.rebate || []) ? [{
        rebate_value: 1,
        rebate_method: 'percent',
        user_role: 'visitor'
      }] : entity?.rebate?.map(({
        rebate_value,
        rebate_method,
        user_role
      }) => {
        return {
          rebate_value,
          rebate_method,
          user_role
        };
      }),
      validates: []
    }),
    product_tiered_rebate: useField<{
      rebate_value: number;
      rebate_method: string;
      user_role: string;
    }[]>({
      value: helpers.isEmpty(entity?.tiered_rebate || []) ? [{
        rebate_value: 0,
        rebate_method: 'percent',
        user_role: 'collaborator'
      }, {
        rebate_value: 0,
        rebate_method: 'percent',
        user_role: 'agency'
      }] : entity?.tiered_rebate?.map(({
        rebate_value,
        rebate_method,
        user_role
      }) => {
        return {
          rebate_value,
          rebate_method,
          user_role
        };
      }),
      validates: []
    })
  };
  const {
    product_commission,
    product_rebate,
    product_tiered_rebate,
    product_commission_advance
  } = useFields;
  const addNewRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number) => {
    let currentValue = [...useFields.product_commission_advance.value, ...[]];
    currentValue.push({
      commission_parent_index: commission_parent_index,
      user_role: 'customer',
      commission_value: 1
    });
    useFields.product_commission_advance.onChange(currentValue);
  }, [useFields.product_commission_advance]);
  const deleteRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number, index_id: number) => {
    let currentValue = [...useFields.product_commission_advance.value, ...[]];
    // currentValue = currentValue.filter((e) => e.commission_parent_index === commission_parent_index);
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_commission_advance.onChange(currentValue);
  }, [useFields.product_commission_advance]);
  const addNewRuleToProductCommissionCallback = useCallback(() => {
    let currentValue = [...useFields.product_commission.value, ...[]];
    if (currentValue.length > 2) return;
    currentValue.push({
      commission_level: currentValue.length + 1,
      commission_method: 'percent',
      commission_value: 1
    });
    useFields.product_commission.onChange(currentValue);
  }, [useFields.product_commission]);
  const deleteRuleToProductCommissionCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_commission.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_commission.onChange(currentValue);
  }, [useFields.product_commission]);
  const addNewRuleToProductRebateCallback = useCallback(() => {
    let currentValue = [...useFields.product_rebate.value, ...[]];
    currentValue.push({
      rebate_value: 1,
      rebate_method: 'percent',
      user_role: 'visitor'
    });
    useFields.product_rebate.onChange(currentValue);
  }, [useFields.product_rebate]);
  const deleteRuleToProductRebateCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_rebate.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_rebate.onChange(currentValue);
  }, [useFields.product_rebate]);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        await createEntity({
          commission: values.product_commission,
          commission_advance: values.product_commission_advance,
          rebate: values.product_rebate,
          tiered_rebate: values.product_tiered_rebate
        });
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return /*@million jsx-skip*/<AffiliateDefaultSetup_1 v0={<Helmet>
        <title>Cài đặt Affiliate mặc định cho sản phẩm</title>
      </Helmet>} v1={<Page title="Cài đặt Affiliate mặc định">
        <BlockStack gap="600">
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt hoa hồng
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các quy tắc chung, được áp dụng toàn bộ hệ thống. Cài đặt của từng sản phẩm sẽ ghi đè các quy tắc mặc định này.
                </Text>
              </BlockStack>
            </Box>
            <Card>
              <BlockStack gap="400">
                <Text as="p">-- Đây là người mua (bậc 0)</Text>
                {product_commission.value.map((commission, index) => {
              return <div key={'index_product_commission_' + index}>
                      <TextField autoComplete="off" label={`-- Hoa hồng giới thiệu bậc ${index + 1}`} labelAction={{
                  content: __('commission_method_label_' + commission.commission_method),
                  onAction: () => {
                    product_commission.onChange(product_commission.value.map((item, i) => i === index ? {
                      ...item,
                      commission_method: commission.commission_method === 'percent' ? 'fixed_amount' : 'percent'
                    } : item));
                  }
                }} value={helpers.formatNumberWithCommas(commission.commission_value.toString())} onChange={value => product_commission.onChange(product_commission.value.map((item, i) => i === index ? {
                  ...item,
                  commission_value: helpers.parseNumeric(value)
                } : item))} suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductCommissionCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />
                      <Box paddingInlineStart={'600'} paddingBlock={'200'}>
                        <BlockStack gap={'400'}>
                          {useFields.product_commission_advance.value.map((commission_advance, index2) => {
                      if (commission_advance.commission_parent_index !== index) return undefined;
                      return <TextField size="slim" label={`Nếu người nhận là *** thì nhận thêm (${helpers.formatNumberWithCommas(commission.commission_value.toString())} + x) giá trị`} connectedLeft={<Select label="" labelHidden options={allRoles} onChange={value => product_commission_advance.onChange(product_commission_advance.value.map((item, i) => i === index2 ? {
                        ...item,
                        user_role: value
                      } : item))} value={commission_advance.user_role.toString()} />} labelAction={{
                        content: 'Xoá',
                        onAction: () => deleteRuleToProductCommissionAdvanceCallback(index, index2)
                      }} autoComplete="off" value={helpers.formatNumberWithCommas(commission_advance.commission_value.toString())} onChange={value => product_commission_advance.onChange(product_commission_advance.value.map((item, i) => i === index2 ? {
                        ...item,
                        commission_value: helpers.parseNumeric(value)
                      } : item))} placeholder="Giá trị" suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} />;
                    })}

                          <div>
                            <Button variant="plain" tone="success" onClick={() => addNewRuleToProductCommissionAdvanceCallback(index)} icon={PlusIcon}>
                              Thêm điều kiện nâng cao
                            </Button>
                          </div>
                        </BlockStack>
                      </Box>
                    </div>;
            })}
                <Button icon={PlusIcon} onClick={() => addNewRuleToProductCommissionCallback()} variant="primary">
                  Thêm quy tắc
                </Button>
                <Text as="p" tone="subdued">
                  Hoa hồng trong chương trình Affiliate được thanh toán SAU KHI đơn hàng hoàn thành đối soát. ĐÂY KHÔNG PHẢI LÀ CHIẾT KHẤU.
                  <br />
                  Hoa hồng bậc 1 là người giới thiệu trực tiếp người mua, bậc 2 là người giới thiệu bậc 1, bậc 3 là người giới thiệu bậc 2...
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt chiết khấu
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các quy tắc chung, được áp dụng toàn bộ hệ thống. Cài đặt của từng sản phẩm sẽ ghi đè các quy tắc mặc định này.
                </Text>
              </BlockStack>
            </Box>
            <Card>
              <BlockStack gap="400">
                {product_rebate.value.map((rebate, index) => {
              return <InlineGrid columns={{
                xs: 1,
                md: 2
              }} gap="400" key={'index_product_rebate_' + index}>
                      <TextField autoComplete="off" label={`Giá trị chiết khấu ${index + 1}`} labelAction={{
                  content: __('commission_method_label_' + rebate.rebate_method),
                  onAction: () => {
                    product_rebate.onChange(product_rebate.value.map((item, i) => i === index ? {
                      ...item,
                      rebate_method: rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                    } : item));
                  }
                }} value={helpers.formatNumberWithCommas(rebate.rebate_value.toString())} onChange={value => product_rebate.onChange(product_rebate.value.map((item, i) => i === index ? {
                  ...item,
                  rebate_value: helpers.parseNumeric(value)
                } : item))} suffix={rebate.rebate_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductRebateCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />

                      <Select label={'Cho nhóm người dùng'} options={allRoles} onChange={value => product_rebate.onChange(product_rebate.value.map((item, i) => i === index ? {
                  ...item,
                  user_role: String(value)
                } : item))} value={rebate.user_role.toString()} />
                    </InlineGrid>;
            })}
                <Button icon={PlusIcon} onClick={() => addNewRuleToProductRebateCallback()} variant="primary">
                  Thêm quy tắc
                </Button>
                <Text as="p" tone="subdued">
                  Chiết khấu là số tiền cứng sau khi bán một sản phẩm. Số tiền này được hoàn lại vào tài khoản của người mua sau khi đơn hàng được đối
                  soát.
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt chiết khấu phân tầng
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các quy tắc chung, được áp dụng toàn bộ hệ thống. Cài đặt của từng sản phẩm sẽ ghi đè các quy tắc mặc định này.
                </Text>
              </BlockStack>
            </Box>
            <Card>
              <BlockStack gap="400">
                <Text as="p">-- Đây là người mua</Text>

                {product_tiered_rebate.value.map((tiered_rebate, index) => {
              return <TextField autoComplete="off" label={`-- Chiết khấu cho ${__(`user_role_` + tiered_rebate.user_role)}`} labelAction={{
                content: __('commission_method_label_' + tiered_rebate.rebate_method),
                onAction: () => {
                  product_tiered_rebate.onChange(product_tiered_rebate.value.map((item, i) => i === index ? {
                    ...item,
                    rebate_method: tiered_rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                  } : item));
                }
              }} value={helpers.formatNumberWithCommas(tiered_rebate.rebate_value.toString())} onChange={value => product_tiered_rebate.onChange(product_tiered_rebate.value.map((item, i) => i === index ? {
                ...item,
                rebate_value: helpers.parseNumeric(value)
              } : item))} suffix={tiered_rebate.rebate_method === 'percent' ? '%' : 'fixed'} />;
            })}

                <Text as="p" tone="subdued">
                  Chiết khấu phân tầng là CHIẾT KHẤU và chia theo tầng, ở tầng thấp hơn khi mua hàng, các tầng trên có liên quan sẽ nhận được giá trị
                  tương ứng.
                </Text>
                <Text as="p" tone="subdued">
                  Chiết khấu này cũng sẽ được chia ngay sau khi đơn hàng giao thành công và được đánh dấu là "Đã đối soát".
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>
        </BlockStack>
      </Page>} v2={dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
    onAction: submit,
    loading: updating,
    disabled: !dirty
  }} discardAction={{
    content: 'Hoàn tác',
    onAction: Userreset
  }} />} />;
};
import { block as _block } from "million/react";
import { BlockStack, Box, Button, Card, ContextualSaveBar, InlineGrid, Page, Select, Text, TextField } from '@shopify/polaris';
import { useNotification } from 'context/NotificationContext';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import __ from 'languages/index';
import { useField, useForm } from '@shopify/react-form';
import helpers from 'helpers/index';
import { TypedProductCommission, TypedProductCommissionAdvance } from 'interface/product.model';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';
import { useCreateDefaultAffiliateSetting, useGetDefaultAffiliateSetting } from 'queries/default_product_commission.query';
import { useUserRoleContext } from 'context/UserRoleContext';
export default AffiliateDefaultSetup;