import { Badge, Card, DataTable, EmptyState, LegacyCard, Link, Page, SkeletonThumbnail, Tabs, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import SimpleFilter from 'components/simpleFilter';
import SkeletonLoading from 'components/skeletonPageLoading';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import emptyIMG from 'media/images/empty.png';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { useLocation, useNavigate } from 'react-router-dom';
import TransactionNewModal from './transaction.new';
import { PlusIcon } from '@shopify/polaris-icons';
import { TypedTransactions, allowed_transaction_category } from 'interface/transaction.model';
import __, { ___ } from 'languages/index';
import { useGetTransactions } from 'queries/transaction.query';
import { useGetAdminWallet } from 'queries/user_wallet.query';
import { useGetWallets } from 'queries/wallet.query';
import { Helmet } from 'react-helmet-async';
import { useAuth } from 'context/AuthContext';
import __helpers from 'helpers/index';
import TransactionView from './transaction.view';
export default function TransactionList() {
  const {
    mutateAsync: getAdminWallet
  } = useGetAdminWallet();
  const {
    data: walletEntities
  } = useGetWallets({
    limit: 30
  });
  const [walletTypeList, setWalletTypeList] = useState<any>([]);
  useEffect(() => {
    if (walletEntities?.body) {
      setWalletTypeList(walletEntities?.body);
    }
  }, [walletEntities]);
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search) || false;
  const initialQuery = useMemo(() => {
    return {
      query: '',
      page: 1,
      limit: 50,
      sort: 'createdAt:desc',
      transaction_category: '',
      wallet_type_id: ''
    };
  }, []);
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    refetch: getEntities,
    isFetching: loading
  } = useGetTransactions(mainQuery);
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [data]);
  const [showModal, setShowModal] = useState(false);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const getMainQuery = useCallback(async (mainQuery: any) => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/transactions' + buildURLSearch);
    getEntities();
  }, []);
  const getMainQueryDebound = useMemo(() => helpers.debounce(_value => getMainQuery?.call(this, _value), 500), []);
  useEffect(() => {
    getMainQueryDebound(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Load current content
   */
  const reloadContent = useCallback(() => {
    getEntities();
    setShowModal(false);
  }, [mainQuery]);

  /** showTransactionDetail */
  const [showTransactionDetail, setShowTransactionDetail] = useState<string>('');
  const emptyData = <EmptyState heading="Không có giao dịch nào!" image={emptyIMG}>
      <p>Oh, Không có gì ở đây. Hãy thử bỏ bộ lọc hoặc tạo mới.</p>
    </EmptyState>;
  let totalMoney = 0;
  let totalID = 0;
  const renderItem = (transactionData: TypedTransactions) => {
    if (!transactionData) return;
    const {
      transaction_id,
      amount,
      transaction_note,
      transaction_type,
      transaction_category,
      transaction_hash,
      createdAt,
      wallet_receiver,
      transaction_to_order,
      creator_to_user
    } = transactionData;
    totalMoney += Number(amount);
    totalID++;
    return [<Text as="span">{String(transaction_id)}</Text>, helpers.formatNumber(amount, ','), wallet_receiver && <Link url={'/customer/view/' + wallet_receiver.user.user_id}>
          {wallet_receiver.user?.display_name || wallet_receiver.user?.user_phonenumber}
        </Link>, transaction_note || <Badge></Badge>, <Badge tone="info">{__('transaction_type_' + transaction_type)}</Badge>, <Badge tone="info">{__('transaction_category_' + transaction_category)}</Badge>, <Link onClick={() => setShowTransactionDetail(transaction_hash)}>{__helpers.trimContentString(transaction_hash)}</Link>, creator_to_user?.display_name ?? creator_to_user?.user_phonenumber ?? creator_to_user?.user_email ?? '', <div key={transaction_id}>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'HH:mm DD-MM-YYYY') : '-'}</div>];
  };
  const PagesList = entities && entities.length > 0 ? <>
        <DataTable truncate sortable={[false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={1} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', 'Số tiền', 'Khách hàng', 'G.chú', 'Phương thức', 'Loại', 'Hash', 'Người tạo', 'Time']} rows={entities?.map(renderItem)} hideScrollIndicator totals={[`${totalID} giao dịch`, helpers.formatNumber(totalMoney, ',') + ' đ', '', '', '', '', '', '']} showTotalsInFooter
    // hasZebraStripingOnData
    increasedTableDensity
    // fixedFirstColumns={2}
    // stickyHeader
    footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} verticalAlign="middle" />
      </> : emptyData;
  const optionForFilter = useMemo(() => {
    let r = [];
    for (let keyofFilter of allowed_transaction_category) {
      r.push({
        label: __('transaction_category_' + keyofFilter),
        value: keyofFilter,
        field: 'transaction_category'
      });
    }
    return r;
  }, []);

  /**
   * TABs
   */

  /** Khi lấy thông tin của một ví, thì mình lấy luôn số dư để họ có thể checking rõ ràng ... */
  const [loadingMotherWallet, setLoadingMotherWallet] = useState(false);
  const motherWalletData = useRef(0);
  const getAdminWalletCallback = useCallback(async (wallet_unit: string) => {
    setLoadingMotherWallet(true);
    motherWalletData.current = 0;
    try {
      let data = await getAdminWallet(wallet_unit);
      motherWalletData.current = data.balance || 0;
    } catch (e) {}
    setLoadingMotherWallet(false);
  }, []);
  const [walletSelected, setWalletSelected] = useState(0);
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    let walletUnits = [{
      id: String('0'),
      content: __('Tất cả'),
      description: 'Toàn bộ giao dịch trên ví',
      wallet_unit: ''
    }];
    if (walletTypeList) for (let w of walletTypeList) {
      walletUnits.push({
        id: `` + w.id,
        content: w.wallet_name,
        description: w.wallet_description,
        wallet_unit: w.wallet_unit
      });
    }
    setTabs(walletUnits);
  }, [walletTypeList]);
  const loadBeyonWallet = useCallback((wallet_type_id: any) => {
    setMainQuery({
      ...mainQuery,
      ...{
        wallet_type_id: wallet_type_id === '0' ? '' : wallet_type_id
      }
    });
  }, [mainQuery]);
  useEffect(() => {
    loadBeyonWallet(tabs[walletSelected]?.id);
    if (tabs[walletSelected] && tabs[walletSelected].wallet_unit) getAdminWalletCallback(tabs[walletSelected]?.wallet_unit);
  }, [walletSelected, tabs]);

  // lấy quyền
  const {
    currentUserCan
  } = useAuth();
  return <>
      <Helmet>
        <title>Toàn bộ giao dịch</title>
      </Helmet>
      {entities === null ? <SkeletonLoading fullWidth /> : <Page title="Giao dịch" subtitle="Quản lý toàn bộ giao dịch trên hệ thống liên quan tới ví" compactTitle fullWidth primaryAction={{
      content: 'Thêm giao dịch',
      icon: PlusIcon,
      disabled: !currentUserCan('manage_transactions'),
      key: '13',
      // enter
      // declare enum Key // @shopify\polaris\build\ts\latest\src\types.d.ts
      onAction: () => {
        setShowModal(true);
      }
    }}>
          <LegacyCard>
            <Tabs tabs={tabs} selected={walletSelected} onSelect={setWalletSelected}>
              <LegacyCard.Section>
                {tabs[walletSelected]?.id !== '0' ? <div>
                    {/* <p>{tabs[walletSelected]?.wallet_unit ?? '-'}</p> */}
                    {loadingMotherWallet ? <SkeletonThumbnail size="small" /> : <Text variant="heading2xl" as="h2">
                        {helpers.formatNumberWithCommas(motherWalletData.current)}
                      </Text>}
                  </div> : <Text as="p">Tất cả các giao dịch trên tất cả các ví</Text>}
              </LegacyCard.Section>
            </Tabs>
          </LegacyCard>

          <br />

          <Card padding="0">
            <SimpleFilter loading={loading} options={optionForFilter} onCallback={filterCallback} sortField={[{
          label: 'Số tiền',
          field: 'amount'
        }]} />
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Page>}
      <Hotkeys keyName="shift+enter" onKeyDown={(keyname: any, event: any, _handler) => {
      event.preventDefault();
      setShowModal(true);
    }} />

      <TransactionNewModal onClose={reloadContent} show={showModal} />
      <TransactionView show={!!showTransactionDetail} transaction_hash={showTransactionDetail} onClose={() => setShowTransactionDetail('')} />
    </>;
}