import { Card, Text, Link, Page, Thumbnail, DataTable, InlineStack, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { DeleteIcon } from '@shopify/polaris-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Pagination from 'components/pagination';
// import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
// import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './brand.modal';
import { useDeleteProductBrand, useGetProductBrands, useBatchReorder } from 'queries/brand.query';
import { TypedProductBrand } from 'queries/brand.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import SimpleFilter from 'components/simpleFilter';
export default function BrandList() {
  const [entities, setEntities] = useState<TypedProductBrand[] | null>(null);
  const [totalItems, setTotalItems] = useState(0);

  // const dispatch = useAppDispatch();
  // const history = useNavigate();

  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 100,
    sort: 'brand_order:asc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    isLoading: searching,
    data: productCategoryData
  } = useGetProductBrands(mainQuery);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductBrand();
  useEffect(() => {
    if (productCategoryData) {
      let {
        body,
        totalItems
      } = productCategoryData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [productCategoryData]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product Brand', '/product_brand' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async brand_id => {
    const confirmed = await showDeleteConfirm('Xóa nhãn hiệu', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(brand_id);
    }
  }, []);

  /** Sắp xếp */

  const {
    mutate: batchReorder
  } = useBatchReorder();
  const handleOnDragEnd = useCallback(result => {
    if (!result.destination) return;
    let cloneEntities = [...entities, ...[]];
    const [reorderedItem] = cloneEntities.splice(result.source.index, 1);
    cloneEntities.splice(result.destination.index, 0, reorderedItem);
    setEntities(cloneEntities);
    let reorderData = {};
    let index = 0;
    for (let d of cloneEntities) {
      index++;
      reorderData = {
        ...reorderData,
        ...{
          [d.brand_id]: index
        }
      };
    }
    batchReorder(reorderData);
  }, [entities]);
  return <>
      <Helmet>
        <title>Quản lý nhãn hiệu hàng hóa</title>
      </Helmet>

      <ProductCategoryModalAddAndEdit show={showModal} onClose={() => {
      modalEntity.current = null;
      setShowModal(false);
    }} entity={modalEntity.current} />

      <Page title="Nhãn hiệu hàng hóa" primaryAction={{
      content: 'Thêm nhãn hiệu',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <SimpleFilter defaultSort={'brand_order:asc'} onCallback={filterCallback} options={[]} loading={false} sortField={[{
          label: 'Thứ tự sắp xếp',
          field: 'brand_order'
        }]} />

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="Polaris-DataTable__ScrollContainer">
              <Droppable droppableId="table">
                {provided => <table {...provided.droppableProps} ref={provided.innerRef} className="Polaris-DataTable__Table">
                    <thead>
                      <tr>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">STT</th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Tên nhãn hiệu
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Mô tả
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Sản phẩm
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header">
                          Ngày tạo
                        </th>
                        <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric">
                          -
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entities?.map((ProductBrandData, index) => <Draggable key={ProductBrandData.brand_id} draggableId={ProductBrandData.brand_id} index={index}>
                          {provided => <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{index + 1}</td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                <Link removeUnderline url={'view/' + ProductBrandData.brand_id} key={index}>
                                  {ProductBrandData.product_brand_to_media ? <InlineStack blockAlign="center" gap={'100'}>
                                      <Thumbnail size="small" source={helpers.getMediaLink(ProductBrandData.product_brand_to_media ? ProductBrandData.product_brand_to_media?.media_thumbnail ? ProductBrandData.product_brand_to_media?.media_thumbnail['128x128'] : '' : undefined)} alt={''} />
                                      <Text as="span">{ProductBrandData.brand_name}</Text>
                                    </InlineStack> : <Text as="span">{ProductBrandData.brand_name}</Text>}
                                </Link>
                              </td>

                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                <Text as="span">{helpers.trimMiddleString(ProductBrandData.brand_excerpt, 30, 10)}</Text>
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                {ProductBrandData._count.product_to_brand || 0}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric">
                                {dateandtime.format(new Date(Number(ProductBrandData.createdAt)), 'DD-MM-YYYY')}
                              </td>
                              <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                                <Button tone="critical" variant="plain" icon={DeleteIcon} onClick={() => {
                          handleDelete(ProductBrandData.brand_id);
                        }}></Button>
                              </td>
                            </tr>}
                        </Draggable>)}
                      {provided.placeholder}
                    </tbody>
                  </table>}
              </Droppable>
            </div>
          </DragDropContext>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}