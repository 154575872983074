import { Badge, Button, Card, Icon, IndexTable, InlineStack, Link, Page, SkeletonBodyText, Tooltip, useBreakpoints, useIndexResourceState } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import __, { ___ } from 'languages';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import FilterAdvance from './filter';
import { ListBulletedIcon, AlertDiamondIcon, LockIcon, PlusIcon, OrderFulfilledIcon, DeleteIcon, MoneyFilledIcon, OrderUnfulfilledIcon, RefreshIcon, PageDownIcon, PrintIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
import { TypedOrder, useBulkDownloadOrder, useBulkUpdateOrder, useCreateOrder, useGetOrders, useUpdateOrder } from 'queries/orders.query';
import { Helmet } from 'react-helmet-async';
import { useNotification } from 'context/NotificationContext';
import { getBadgeOfPaymentMethod, getOrderDeliveryMethod, getOrderStatusBadge } from './order.component';
import triggerDownload from 'components/triggerDownload';
import CustomerDetailPopup from 'components/customer.detail.popup';
import printJS from 'print-js';
import { useAuth } from 'context/AuthContext';
export default function OrderList() {
  const {
    addNotification
  } = useNotification();
  // lấy quyền
  const {
    currentUserCan
  } = useAuth();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    order_status: null,
    payment_status: null,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
    // ...stateProp
  });
  const {
    data: rootData,
    isLoading: loading,
    refetch: getEntities
  } = useGetOrders(mainQuery);
  const {
    mutateAsync: createEntity,
    data: newCreateData
  } = useCreateOrder();
  const history = useNavigate();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (rootData) {
      let {
        body,
        totalItems
      } = rootData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [rootData]);

  /**
   * create new Order
   */

  const createNewEmptyOrder = useCallback(() => {
    createEntity({}).then().catch(e => {});
  }, []);
  useEffect(() => {
    if (newCreateData) {
      history('/order/view/' + newCreateData.order_id);
    }
  }, [newCreateData]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Order', '/order' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /** Show modal customer detail */
  const [current_customer_selected, setCurrent_customer_selected] = useState(null);
  const closeCustomerDetailCallback = useCallback(() => {
    setCurrent_customer_selected(null);
  }, []);

  /** Khi ấn nút in đơn ... */
  const {
    mutateAsync: updateOrder,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateOrder();
  /** https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf */
  const markThisOrderPrintPackingSlip = (order_id, order_fulfillment_packingslip_uri) => {
    printJS(process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + '' + order_fulfillment_packingslip_uri, 'pdf');
    updateOrder({
      order_id: order_id,
      order_print_packing_slip: 1
    }).catch(e => {});
  };
  const renderItem = (orderDetail: TypedOrder, index) => {
    const {
      order_id,
      order_pnr,
      order_status,
      order_note,
      order_total_price,
      order_total_mustpay,
      order_fulfillment,
      order_delivery_type,
      order_checked,
      payment_type,
      payment_status,
      user_id,
      createdAt,
      customer_data,
      order_print_packing_slip
    } = orderDetail;
    if (!order_id) return null;
    let {
      order_fulfillment_error,
      order_fulfillment_packingslip_uri
    } = order_fulfillment || {};
    return <IndexTable.Row disabled={order_checked === 1} id={order_id} key={order_id + '_' + index} selected={selectedResources.includes(order_id)} position={index}>
        <IndexTable.Cell>
          <Link url={'/order/view/' + order_id} removeUnderline>
            {order_print_packing_slip ? order_pnr : <strong>{order_pnr}</strong>}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Tooltip content="Đơn đã đối soát">{order_checked === 1 && <Button size="micro" variant="plain" icon={LockIcon}></Button>}</Tooltip>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Tooltip content="In packing slip">
            {order_fulfillment_packingslip_uri && <Button url={process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + '' + order_fulfillment_packingslip_uri} target="_blank" onClick={() => markThisOrderPrintPackingSlip(order_id, order_fulfillment_packingslip_uri)} size="micro" variant="plain" icon={PrintIcon}></Button>}
          </Tooltip>
        </IndexTable.Cell>
        <IndexTable.Cell>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm') : '-'}</IndexTable.Cell>
        <IndexTable.Cell>
          {user_id ? <Button onClick={() => setCurrent_customer_selected(user_id)} variant="plain">
              {customer_data?.display_name || customer_data?.user_email || customer_data?.user_phonenumber || '---'}
            </Button> : <>
              <Badge></Badge>
              <Badge></Badge>
            </>}
        </IndexTable.Cell>
        <IndexTable.Cell> {getOrderStatusBadge(order_status)}</IndexTable.Cell>
        <IndexTable.Cell> {getOrderDeliveryMethod(order_delivery_type)}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{
          minWidth: '180px'
        }} key={Math.random()}>
            <InlineStack gap={'100'} align="start" blockAlign="center">
              {order_fulfillment_error && <span title={order_fulfillment_error}>
                  <span className="Polaris-Text--root Polaris-Text--bodySm" style={{
                fontSize: '12px'
              }}>
                    <Icon source={AlertDiamondIcon} tone="critical" />
                  </span>
                </span>}
              {order_fulfillment?.logistic_service?.logistic_name || <>
                  <Badge></Badge>
                  <Badge></Badge>
                </>}
            </InlineStack>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {order_fulfillment?.order_fulfillment_code || <>
              <Badge></Badge>
              <Badge></Badge>
            </>}
        </IndexTable.Cell>
        <IndexTable.Cell>{helpers.formatNumber(order_total_mustpay, ',')}</IndexTable.Cell>
        <IndexTable.Cell>{payment_type}</IndexTable.Cell>
        <IndexTable.Cell> {getBadgeOfPaymentMethod(payment_status)}</IndexTable.Cell>
        <IndexTable.Cell>
          <div className="sale_order_note">
            {order_note || <>
                <Badge></Badge>
                <Badge></Badge>
              </>}
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>;
  };

  /** Bulk update */
  /** Bulk update */
  /** Bulk update */
  /** Bulk update */
  const {
    mutate: bulkUpdateOrder,
    isSuccess: batchUpdateSuccess
  } = useBulkUpdateOrder();
  const {
    mutateAsync: downloadOrders
  } = useBulkDownloadOrder();
  function resourceIDResolver(orders: any) {
    return orders.order_id;
  }
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(entities || [], {
    resourceIDResolver
  });

  /** Dọn / xóa order rác ... */
  const clearDraftOrderCallback = useCallback(() => {
    bulkUpdateOrder({
      mode: 'delete_order_draft',
      mode_value: '',
      orders: selectedResources
    });
  }, [bulkUpdateOrder, selectedResources]);

  /** Hủy đơn hàng loạt ... */
  const cancelOrderCallback = useCallback(() => {
    bulkUpdateOrder({
      mode: 'cancel_order',
      mode_value: '',
      orders: selectedResources
    });
  }, [bulkUpdateOrder, selectedResources]);

  /** Đánh dấu đã trả tiền hàng loạt ... */
  const markOrderRefunCallback = useCallback(() => {
    bulkUpdateOrder({
      mode: 'mark_as_refuned',
      mode_value: '',
      orders: selectedResources
    });
  }, [bulkUpdateOrder, selectedResources]);

  /** Đánh dấu đã trả tiền hàng loạt ... */
  const downloadOrderCallback = useCallback(async () => {
    try {
      let data = await downloadOrders(selectedResources);
      triggerDownload(data, 'orders_export.csv', 'text/csv');
    } catch (e) {}
  }, [selectedResources]);
  useEffect(() => {
    if (batchUpdateSuccess) {
      addNotification('info', __('update_successfully'));
    }
  }, [batchUpdateSuccess]);
  const bulkActions = [{
    title: 'Kế toán',
    items: [{
      content: 'Đã đối soát',
      icon: OrderFulfilledIcon,
      onAction: () => alert('Đang phát triển ...')
    }, {
      icon: MoneyFilledIcon,
      content: 'Đã hoàn tiền',
      onAction: () => markOrderRefunCallback()
    }]
  }, {
    title: 'Khác',
    items: [{
      icon: OrderUnfulfilledIcon,
      destructive: true,
      content: 'Hủy đơn',
      onAction: () => cancelOrderCallback()
    }, {
      icon: DeleteIcon,
      destructive: true,
      content: 'Xóa bỏ đơn nháp',
      onAction: () => clearDraftOrderCallback()
    }, {
      icon: PageDownIcon,
      content: 'Tải các đơn này về',
      onAction: () => downloadOrderCallback()
    }]
  }];
  const OrderListTable = <>
      <IndexTable condensed={useBreakpoints().smDown} resourceName={{
      singular: 'Đơn hàng',
      plural: 'Đơn hàng'
    }} itemCount={entities?.length || 0} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} headings={[{
      title: 'PNR'
    }, {
      title: ''
    }, {
      title: ''
    }, {
      title: 'Ngày tạo'
    }, {
      title: 'Khách hàng'
    }, {
      title: 'Tình trạng'
    }, {
      title: 'Vận chuyển'
    }, {
      title: 'D.vụ vận chuyển'
    }, {
      title: 'Mã vận chuyển'
    }, {
      title: 'Giá trị'
    }, {
      title: 'Cách thanh toán'
    }, {
      title: 'Thanh toán'
    }, {
      title: 'Ghi chú'
    }]} bulkActions={bulkActions}>
        {entities?.map(renderItem) || []}
      </IndexTable>
    </>;
  return <>
      <Helmet>
        <title>Danh sách đơn hàng</title>
      </Helmet>
      <Page fullWidth title="Danh sách đơn hàng" compactTitle primaryAction={{
      content: 'Thêm đơn hàng',
      disabled: !currentUserCan(['manage_orders', 'edit_orders']),
      icon: PlusIcon,
      onAction: createNewEmptyOrder
    }} secondaryActions={[{
      content: 'Tải lại',
      icon: RefreshIcon,
      onAction: () => getEntities()
    }, {
      content: 'Lên đơn nhanh',
      icon: OrderFulfilledIcon,
      disabled: true,
      onAction: () => alert('Đang phát triển')
    }, {
      content: 'Đối soát hàng loạt',
      icon: ListBulletedIcon,
      disabled: true,
      onAction: () => alert('Đang hoàn thiện')
    }]}>
        <Card padding="0">
          <FilterAdvance loading={loading} onCallback={filterCallback} />
          {loading ? <SkeletonBodyText /> : OrderListTable}
        </Card>

        <br />
        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={Number(mainQuery?.page)} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      </Page>
      <br />
      <br />
      <br />
      <br />
      <br />
      <CustomerDetailPopup show={current_customer_selected} onClose={closeCustomerDetailCallback} />
    </>;
}