import { FormLayout, Modal, TextField, Form, BlockStack } from '@shopify/polaris';
import { useCallback } from 'react';
import { lengthLessThan, useField, useForm } from '@shopify/react-form';
import __ from 'languages/index';
import { TypedReviewMeta, useUpdateReviewMeta } from 'queries/review.query';
import Capacity from 'components/Capacity';

/**
 *   Hàm này chỉ hoạt động ở customer campaign
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default function EditReview({
  entity,
  show,
  onClose
}: {
  entity: TypedReviewMeta;
  show: boolean;
  onClose: Function;
}) {
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess
  } = useUpdateReviewMeta();
  const useFields = {
    review_meta_id: useField<string>({
      value: entity?.review_meta_id,
      validates: []
    }),
    review_content: useField<string>({
      value: entity?.review_content,
      validates: [lengthLessThan(65000, 'Không được dài hơn 50 ký tự.')]
    }),
    review_title: useField<string>({
      value: entity?.review_title,
      validates: [lengthLessThan(250, 'No more than 250 characters.')]
    })
  };
  const modalClose = useCallback(() => {
    onClose();
  }, []);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        await updateEntity({
          review_meta_id: values.review_meta_id,
          review_content: values.review_content,
          review_title: values.review_title
        });
        modalClose();
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return <>
      <Modal open={show} onClose={() => modalClose()} title={'Chỉnh sửa lại review'} primaryAction={{
      content: 'Lưu & đóng',
      disabled: !dirty,
      loading: updating,
      onAction: () => {
        submit();
      }
    }} secondaryActions={[{
      content: 'Đóng',
      onAction: () => modalClose()
    }]}>
        <Modal.Section>
          <Capacity current_user_can={['manage_reviews', 'edit_reviews']} onFail={<>Bạn không có quyền truy cập.</>}>
            <Form onSubmit={submit}>
              <FormLayout>
                <BlockStack gap={'200'}>
                  <TextField label="Tựa đề" requiredIndicator autoComplete="off" {...useFields.review_title} />
                  <TextField label="Nội dung" autoComplete="off" multiline={4} {...useFields.review_content} />
                </BlockStack>
              </FormLayout>
            </Form>
          </Capacity>
        </Modal.Section>
      </Modal>
    </>;
}