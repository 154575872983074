import { compiledBlock as _compiledBlock } from "million/react";
const ActivityList_1 = _compiledBlock(_props => <div style={_props.v0}>
      {_props.v1}

      {_props.v2}
      <br />
      <br />
    </div>, {
  name: "ActivityList_1",
  portals: ["v1", "v2"]
});
const ActivityList = function ActivityList() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const recordPerPage = 50;
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const [mainQuery, setMainQuery] = useState({
    ...{
      query: '',
      page: 1,
      limit: recordPerPage,
      sort: 'createdAt:desc'
    },
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetActivities(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Activity', '/activity' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const handleSearchField = useCallback((text: string) => {
    setMainQuery({
      ...mainQuery,
      page: 1,
      query: text
    });
  }, [mainQuery]);
  const groups = useRef('');
  const commentBodyComponent = useCallback((element, index) => {
    let datetimeCreate = new Date(Number(element.createdAt));
    let dateMonthYear = dateandtime.format(datetimeCreate, 'ddd, DD, MMM, YYYY'); // Fri, 28, Apr, 2023
    let {
      activity_id,
      device_id,
      activity_referrer,
      activity_target,
      activity_ip,
      device
    } = element;
    let {
      device_uuid,
      user_agent,
      system_type,
      status
    } = device;
    let A = false;
    if (dateMonthYear !== groups.current) {
      groups.current = dateMonthYear;
      A = true;
    }
    let N = dateMonthYear.toLowerCase().split(',');
    let M = __(N[0]) + ', ' + N[1] + ' ' + __(N[2]) + ', ' + N[3];
    return <>
        {A && <div key={index + '_hand'} className="step-item">
            {M}
          </div>}
        <div className="card" key={index + 'fghncfgnd'} id={'comment_' + activity_id}>
          <div className="timeline_handler" key={index + 'fhgnfdghnmdx'}></div>
          <div className="time" key={index + 'ghngnf'}>
            <span className="hour" key={index + 'ghfnfhmtu'}>
              {dateandtime.format(datetimeCreate, 'HH:mm')}
            </span>
            <div className="author" key={index + 'yukmfyumy'}>
              {system_type}
            </div>
          </div>
          <div className="info" key={index + 'kmfgyumjdry'}>
            <h3 className="title" key={index + '46u546u'}>
              Trang đích: {activity_target}
            </h3>
            <div key={'_jkd' + index}>
              {activity_referrer && <Text as="p" variant="bodyMd" key={index + '_kjhk'}>
                  Referrer: {activity_referrer}
                </Text>}
              <Text as="p" variant="bodyMd" key={index + 'tyjdhertjy'}>
                Agent: {user_agent}
              </Text>
              <Text as="p" variant="bodyMd" key={index + 'stswe4ye'}>
                From IP: {activity_ip}
              </Text>
              <Text as="p" variant="bodyMd" key={index + '4ty65u7yera'}>
                Device UUID: <Link url={'/activity/view/' + device_uuid}>{device_uuid}</Link>
              </Text>
              <br />
              <InlineStack gap={'200'} align="start" blockAlign="center">
                {device?.user_to_device.map(device_user => {
                return <Tag onClick={() => history('/customer/view/' + device_user.user.user_id)}>
                      {device_user.user.display_name || device_user.user.user_email}
                    </Tag>;
              })}
              </InlineStack>
            </div>
          </div>
        </div>
      </>;
  }, []);
  return /*@million jsx-skip*/<ActivityList_1 v0={{
    padding: '15px'
  }} v1={<InlineGrid gap="400">
        <Box padding="400">
          <Text variant="headingMd" as="h6" key="dfgfdg">
          Lịch sử hoạt động
          </Text>

          <div className="timeline" key={'xdfdfbdbf' + Math.random()}>
            <div className="outer" key="dfbdfb">
              <div className="card" key="zdfbtt">
                <div className="timeline_handler" key="fgnjjhfgn"></div>
                <div className="info" key="hvncfdhn" style={{
              maxWidth: '600px'
            }}>
                  <ShowSearchForm onChange={handleSearchField} />
                </div>
              </div>

              {!loading && Array.isArray(entities) && (entities.length > 0 ? entities.map(commentBodyComponent) : <div style={{
            padding: '15px'
          }}>
                    <Text as="p">Chưa có một hoạt động nào.</Text>
                  </div>)}
            </div>
          </div>

          <Text variant="headingMd" as="h6">
          Lịch sử hoạt động
          </Text>
        </Box>
      </InlineGrid>} v2={totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null} />;
};
import { block as _block } from "million/react";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InlineGrid, Box, Form, Text, TextField, Link, InlineStack, Tag } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import __ from 'languages/index';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import { useNavigate } from 'react-router-dom';
import { useGetActivities } from 'queries/activity.query';
function ShowSearchForm({
  onChange
}: {
  onChange: Function;
}) {
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const [textFieldValue, setTextFieldValue] = useState(StringQuery.query);
  const handleClearButtonClick = useCallback(() => setTextFieldValue(''), []);
  const handleTextFieldChange = useCallback((value: string) => {
    setTextFieldValue(value);
  }, []);
  const onSubmit = useCallback(() => {
    onChange?.call(this, textFieldValue);
  }, [textFieldValue]);
  return <Form onSubmit={onSubmit}>
      <TextField label="Tìm kiếm" placeholder="Mã Device UUID, Tên trang đích, địa chỉ IP ..." value={textFieldValue} onChange={handleTextFieldChange} clearButton onClearButtonClick={handleClearButtonClick} autoComplete="off" />
    </Form>;
}
export default ActivityList;