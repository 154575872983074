import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedUser_capacity_group } from "./user_capacity_group.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/


export interface TypedUser_role {
    id?: string | bigint;
    user_role?: string;
    user_role_type?: string;
    user_role_order?: number;
    user_capacity_group_id?: string,
    is_default?: number;
    is_readonly?: number;
    createdAt?: string | bigint;

    user_capacity_group?: TypedUser_capacity_group;
}

type IQuery = TypedUser_role & IQueryParams;


/*
* List of all app
*/

export function useGetUserRoles(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user_role/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_role[]>(`/user_role${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetUserRole(app_id: string) {
    return useQuery({
        queryKey: ['user_role/fetch_entity'],
        queryFn: () => axios.get<TypedUser_role>(`/user_role/${app_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateUserRole() {
    return useMutation({
        mutationKey: ['user_role/update_entity'],
        mutationFn: ({ id, ...rest }: TypedUser_role) => axios.patch<TypedUser_role>(`/user_role/${id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_role/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['user_role/fetch_entity'] });
        }
    });
}


export function useCreateUserRole() {
    return useMutation({
        mutationKey: ['user_role/create_entity'],
        mutationFn: (entity: TypedUser_role) => axios.post<TypedUser_role>(`/user_role`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_role/fetch_entity_list'] });
        }
    });
}


export function useDeleteUserRole() {
    return useMutation({
        mutationKey: ['user_role/delete_entity'],
        mutationFn: (app_id: string) => axios.delete<any>(`/user_role/${app_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_role/fetch_entity_list'] });
        }

    });
}






