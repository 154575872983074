import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, DropZone, Modal, Text } from '@shopify/polaris';
import axios from 'axios';
import helpers from 'helpers/index';
import SimpleFilter from './simpleFilter';
import EmptyState from './EmptyState';
import 'media/css/media_center.scss';
import { TypedMedia, useGetMedias } from 'queries/media.query';
interface MediaCenterProps {
  show: boolean;
  onClose: (selectedMediaID: string[]) => void;
  initialSelectedMedia?: string[];
}
export default function MediaCenter({
  show = false,
  onClose,
  initialSelectedMedia = []
}: MediaCenterProps) {
  const [entities, setEntities] = useState<TypedMedia[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<string[]>(initialSelectedMedia);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 36,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);
  const {
    data: mediaData,
    refetch: loadMediaDataFromServer
  } = useGetMedias(mainQuery);
  useEffect(() => {
    loadMediaDataFromServer();
  }, [mainQuery]);
  useEffect(() => {
    if (!mediaData) return;
    let {
      body,
      totalItems
    } = mediaData;
    setEntities(body);
  }, [mediaData]);
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const onCloseCallback = useCallback(() => {
    setFiles([]);
    onClose?.call(this, selectedMedia);
  }, [selectedMedia]);
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    setFiles([]);
    setSelectedMedia(initialSelectedMedia);
  }, [show, initialSelectedMedia]);
  const [updateProgress, setUpdateProgress] = useState(0);
  const [processing_file_name, setProcessing_file_name] = useState('');
  const [uploading, setUploading] = useState(false);
  let processing_file_error = [];
  const start_upload = useCallback(async () => {
    let index = -1;
    let clone_files = [...[], ...files];
    if (helpers.isEmpty(clone_files)) return;
    setUpdateProgress(0);
    setUploading(true);
    for (let element of clone_files) {
      index++;
      if (!element) continue;
      if (element.size > 50282820) continue;
      var bodyFormData = new FormData();
      bodyFormData.append('files', element);
      try {
        setProcessing_file_name(element.name);
        await axios.post('media/upload', bodyFormData, {
          onUploadProgress: (progressEvent: any) => {
            let progress_percent = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
            setUpdateProgress(progress_percent);
          }
        });
        delete files[index];
        setFiles(files);
        await helpers.sleep(2000);
        setUpdateProgress(0);
      } catch (error) {
        processing_file_error.push(index);
      }
    }
    setUploading(false);
    loadMediaDataFromServer();
  }, [files]);
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setFiles(files => [...files, ...acceptedFiles]);
  }, []);
  useEffect(() => {
    start_upload();
  }, [files]);
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
  const validVideoTypes = ['video/mp4'];
  const handleOnClickCheckboxChange = useCallback((newChecked: boolean, id: any) => {
    let final = [...[], ...selectedMedia];
    if (newChecked) {
      final.push(id);
    } else {
      final = final.filter(ne => ne !== id);
    }
    setSelectedMedia(final);
  }, [selectedMedia]);
  return <Modal size="large" open={show} onClose={() => onCloseCallback()} title="Quản lý media" primaryAction={{
    content: 'Đóng',
    onAction: () => onCloseCallback(),
    loading: uploading,
    disabled: uploading
  }}>
      <Modal.Section>
        <DropZone variableHeight allowMultiple accept=".jpg, .jpeg, .png, .gif, .webp, .webm, .mp4, .heic, .hevc" overlayText="" errorOverlayText="" labelHidden onDrop={handleDropZoneDrop}>
          <div style={{
          height: '100px'
        }}>
            <DropZone.FileUpload actionHint={uploading ? String(Math.ceil(updateProgress)) + '% ' + processing_file_name : 'Accepts .gif, .jpg, .png, .mp4, .heic, .hevc'} />
          </div>
        </DropZone>
        <br />
        <SimpleFilter onCallback={filterCallback} loading={false} options={[{
        label: 'JPEG',
        value: 'jpg',
        field: 'media_extention'
      }, {
        label: 'PNG',
        value: 'png',
        field: 'media_extention'
      }, {
        label: 'WEBP',
        value: 'webp',
        field: 'media_extention'
      }, {
        label: 'MP4',
        value: 'mp4',
        field: 'media_extention'
      }]} sortField={[]} />

        <div id="media_wrap">
          {Array.isArray(entities) && (entities.length < 1 ? <EmptyState>Không thấy có gì ở đây!</EmptyState> : entities.map((file, index) => {
          return <div className="media_library_element" key={index} onClick={() => handleOnClickCheckboxChange(!selectedMedia.includes(file.media_id), file.media_id)}>
                    <img className="media_image_thumbnail" alt={file.media_filename} src={helpers.getMediaLink(file.media_thumbnail ? file.media_thumbnail['300x200'] : '')} />
                    <Text as="p" variant="bodyXs" tone="subdued">
                      {file.media_filename}
                    </Text>
                    <Checkbox id={`` + file.media_id} label="Select this media ..." labelHidden checked={selectedMedia.includes(file.media_id)} onChange={newChecked => handleOnClickCheckboxChange(newChecked, file.media_id)} />
                  </div>;
        }))}
        </div>
      </Modal.Section>
    </Modal>;
}