import React, { createContext, useContext, useEffect, useState } from 'react';
import { TypedUser_role, useGetUserRoles } from 'queries/user_role.query';
import __ from 'languages/index';

// Định nghĩa type cho Context
interface UserRoleContextType {
  userRoles: TypedUser_role[];
  allRoleSelection: {
    label: string;
    value: string;
  }[];
  customerRoleSelection: {
    label: string;
    value: string;
  }[];
}

// Tạo Context
const UserRoleContext = createContext<UserRoleContextType | undefined>(undefined);

// Provider để quản lý userRoles
export const UserRoleProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const {
    data: userRoleList
  } = useGetUserRoles({
    limit: 100
  });
  const [userRoles, setUserRoles] = useState<TypedUser_role[]>([]);
  useEffect(() => {
    if (userRoleList) {
      let {
        entities
      } = userRoleList;
      setUserRoles(entities);
    }
  }, [userRoleList]);
  const allRoleSelection = userRoles.map(role => ({
    label: __(`user_role_${role.user_role}`),
    // Tùy chỉnh nếu cần dịch (nếu có hàm __)
    value: role.user_role
  }));
  const customerRoleSelection = userRoles.filter(el => el.user_role_type === 'customer').map(role => {
    // if (role.user_role_type === 'customer') {
    return {
      label: __(`user_role_${role.user_role}`),
      // Tùy chỉnh nếu cần dịch (nếu có hàm __)
      value: role.user_role
      // value: role.id.toString(),
    };
    // }
  });
  return <UserRoleContext.Provider value={{
    userRoles,
    allRoleSelection,
    customerRoleSelection
  }}>{children}</UserRoleContext.Provider>;
};

// Custom hook để sử dụng UserRoleContext
export const useUserRoleContext = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error('useUserRoleContext must be used within a UserRoleProvider');
  }
  return context;
};