import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ActionList, Button, Card, DataTable, Divider, InlineStack, Link, Page, Popover, SkeletonTabs, Text, Thumbnail, useIndexResourceState } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import helpers from 'helpers/index';
import Theme404 from 'layout/404';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import ProductCategoryModal from './product_category.edit.modal';
import QuickSearchProduct from 'components/quickSearchProduct';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useDeleteProductCategory, useGetProductCategory, useUpdateProductCategory } from 'queries/product_category.query';
import { useCreateProductToCategory, useGetProductToCategories } from 'queries/product_to_category.query';
export default function ProductCategoryView() {
  let {
    category_id
  } = useParams();
  const {
    data: entity,
    isLoading: loading
  } = useGetProductCategory(category_id);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductCategory();
  const {
    mutateAsync: updateEntity
  } = useUpdateProductCategory();
  const modalEntity = useRef(null);
  /** relationship */
  const {
    data: productToCategoryData,
    refetch: getRelationship
  } = useGetProductToCategories({
    limit: 100,
    category_id: String(category_id)
  });
  const {
    mutateAsync: createRelationship,
    isPending: updatingRelationship
  } = useCreateProductToCategory();
  const [allRelationship, setAllRelationship] = useState([]);
  useEffect(() => {
    if (productToCategoryData) {
      let {
        entities
      } = productToCategoryData;
      setAllRelationship(entities);
    }
  }, [productToCategoryData]);
  const history = useNavigate();

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async category_id => {
    const confirmed = await showDeleteConfirm('Xóa danh mục bài đăng này?', 'Chỉ các danh mục không có bài đăng nào mới có thể xóa bỏ. Nếu danh mục còn bài đăng, vui lòng di chuyển sang danh mục khác trước.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await deleteEntity(category_id);
        history('/product_category');
      } catch (e) {}
    }
  }, []);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const quickUpdateSetting = useCallback(async (status: number) => {
    setUpdateLoading(true);
    setActiveQuickUpdate(false);
    await updateEntity({
      category_id: entity.category_id,
      category_status: status
    });
    setUpdateLoading(false);
  }, [entity]);
  const resourceIDResolver = products => {
    return products.product_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(allRelationship ?? [], {
    resourceIDResolver
  });
  const DisplayListOfProduct = () => {
    if (allRelationship === null) return <SkeletonTabs />;
    if (Array.isArray(allRelationship) && allRelationship.length < 1) return <Text as="p" tone="subdued">
          Chưa có gì ... Bấm thêm sản phẩm để thêm vào danh mục.
        </Text>;
    return <Card padding="0">
        <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric']} headings={['', 'Sản phẩm', 'Tình trạng', 'Giá bán', 'Giá gốc']} rows={allRelationship.map(({
        product
      }) => {
        const {
          product_id,
          product_name,
          product_price,
          product_original_price,
          product_status,
          product_thumbnail_to_media
        } = product;
        return [<Thumbnail source={helpers.getMediaLink(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail ? product_thumbnail_to_media.media_thumbnail['128x128'] : '' : undefined)} alt={'product thumbnail: ' + product_name} />, <Link onClick={() => history('/product/edit/' + product_id)}>{product_name}</Link>, product_status, helpers.formatNumber(product_price, ','), helpers.formatNumber(product_original_price, ',')];
      })} footerContent={`Hiển thị ${allRelationship?.length ?? 0} sản phẩm`} />
      </Card>;
  };
  const actualPage = !helpers.isEmpty(entity) ? <Page title={entity.category_name} subtitle={entity.category_excerpt} titleMetadata={<InlineStack gap="200">
          {/* <Badge tone="success">{entity.app_type}</Badge> */}
          <Popover active={activeQuickUpdate} activator={<Button loading={updateLoading} variant="tertiary" disclosure={'down'} onClick={() => setActiveQuickUpdate(true)}>
                {entity.category_status === 1 ? 'Hiển thị' : 'Không hiển thị'}
              </Button>} autofocusTarget="first-node" onClose={() => setActiveQuickUpdate(false)}>
            <ActionList actionRole="menuitem" items={[{
        content: 'Hiển thị',
        onAction: () => quickUpdateSetting(1)
      }, {
        content: 'Không hiển thị',
        onAction: () => quickUpdateSetting(0)
      }]} />
          </Popover>
        </InlineStack>} backAction={{
    content: 'Back',
    onAction: () => history('/product_category')
  }} primaryAction={{
    content: 'Thêm sản phẩm',
    icon: PlusIcon,
    onAction: () => setShowProductModal(true)
  }} secondaryActions={[{
    content: 'Xóa danh mục',
    onAction: () => handleDelete(entity?.category_id)
  }, {
    content: 'Chỉnh sửa',
    onAction: () => {
      modalEntity.current = entity;
      setShowEditModal(true);
    }
  }]}>
      <div className="content-wrap">{Parser(entity?.category_description ?? '')}</div>
      <br />
      <Divider />
      <br />
      <DisplayListOfProduct />
    </Page> : <Theme404 />;
  const [showProductModal, setShowProductModal] = useState(false);
  const onCloseProductModal = useCallback(async (product_id: bigint | null) => {
    if (product_id) {
      createRelationship({
        category_id: String(category_id),
        product_id: String(product_id)
      });
    }
    setShowProductModal(false);
  }, [category_id]);
  return <Capacity current_user_can={['manage_products', 'view_products', 'edit_products']} onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <ProductCategoryModal show={showEditModal} onClose={closeEditModal} entity={modalEntity.current} />
      <QuickSearchProduct show={showProductModal} onClose={onCloseProductModal} />
      {loading ? <SkeletonLoading /> : actualPage}

      <br />
      <br />
      <br />
      <br />
      <br />
    </Capacity>;
}