import { compiledBlock as _compiledBlock } from "million/react";
const AffiliateAdvanceSetup_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}
      <br />
      <br />
      <br />
      <br />
      <br />

      {_props.v2}
    </slot>, {
  name: "AffiliateAdvanceSetup_1",
  portals: ["v0", "v1", "v2"]
});
const AffiliateAdvanceSetup = function AffiliateAdvanceSetup() {
  const {
    data: allRankEntities,
    isLoading: loadingRank,
    isSuccess: loadingRankSuccess
  } = useGetRanks({
    limit: 100
  });
  const {
    data: productCommissionRankData
  } = useGetDefaultProductCommissionRank();
  const {
    mutateAsync: createProductCommissionRankData,
    isPending: creatingProductCommissionRank
  } = useCreateDefaultProductCommissionRank();
  const {
    data: producRebateRankData
  } = useGetDefaultProductRebateRank();
  const {
    mutateAsync: createProductRebateRankData,
    isPending: creatingProductRebateRank
  } = useCreateDefaultProductRebateRank();
  const [allRanks, setAllRanks] = useState([]);
  const {
    data: settingData,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `affiliate_advance_setting_mode`
  });
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess,
    isPending: updating
  } = useUpdateSetting();
  useEffect(() => {
    if (allRankEntities) {
      let {
        entities
      } = allRankEntities;
      let allRanks = [];
      for (let rank of entities) {
        allRanks.push({
          label: rank.rank_name,
          value: rank.rank_id
        });
      }
      setAllRanks(allRanks);
    }
  }, [allRankEntities]);
  const {
    data: entity
  } = useGetDefaultAffiliateSetting();
  const useFields = {
    /** Để lấy các level chứ không làm gì */
    product_commission: useField<TypedProductCommission[]>({
      value: entity?.commission || [],
      validates: []
    }),
    product_commission_rank_data: useField<TypedProductCommissionRank[]>({
      value: productCommissionRankData || [],
      validates: []
    }),
    product_rebate_rank_data: useField<{
      rebate_value: number;
      rebate_method: string;
      user_rank: string;
    }[]>({
      value: producRebateRankData || [],
      validates: []
    }),
    affiliate_advance_setting_mode: useField<string[]>({
      value: String(settingData?.affiliate_advance_setting_mode || ',').split(','),
      validates: []
    })
  };
  const {
    product_commission,
    product_commission_rank_data,
    product_rebate_rank_data
  } = useFields;
  const addNewRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number) => {
    let currentValue = [...useFields.product_commission_rank_data.value, ...[]];
    currentValue.push({
      commission_parent_index: commission_parent_index,
      user_rank: allRanks[0] ? allRanks[0].value : '',
      commission_value: 1
    });
    useFields.product_commission_rank_data.onChange(currentValue);
  }, [useFields.product_commission_rank_data, allRanks]);
  const deleteRuleToProductCommissionAdvanceCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_commission_rank_data.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_commission_rank_data.onChange(currentValue);
  }, [useFields.product_commission_rank_data]);

  /** REBATE */
  const addNewRuleToProductRebateAdvanceCallback = useCallback(() => {
    let currentValue = [...useFields.product_rebate_rank_data.value, ...[]];
    currentValue.push({
      user_rank: allRanks[0] ? allRanks[0].value : '',
      rebate_method: 'percent',
      rebate_value: 1
    });
    useFields.product_rebate_rank_data.onChange(currentValue);
  }, [useFields.product_rebate_rank_data, allRanks]);
  const deleteRuleToProductRebateAdvanceCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_rebate_rank_data.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_rebate_rank_data.onChange(currentValue);
  }, [useFields.product_rebate_rank_data]);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        let afterFilter = values.product_commission_rank_data.filter(el => el.user_rank !== '' && Number(el.commission_value) > 0);
        await createProductCommissionRankData(afterFilter);
        await createProductRebateRankData(values.product_rebate_rank_data);
        await updateEntity({
          affiliate_advance_setting_mode: values.affiliate_advance_setting_mode
        });
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return /*@million jsx-skip*/<AffiliateAdvanceSetup_1 v0={<Helmet>
        <title>Cài đặt Affiliate nâng cao</title>
      </Helmet>} v1={<Page title="Cài đặt Affiliate nâng cao">
        <BlockStack gap="600">
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt hoa hồng
                </Text>
                <Text as="p" variant="bodyMd">
                  Quy tắc tại đây áp dụng cho người nhận được hoa hồng, nếu người đó không nhận được hoa hồng như trong cài đặt mặc định hoặc cài đặt
                  riêng lẻ trong sản phẩm, người đó không nhận được các ưu đãi này.
                </Text>
              </BlockStack>
            </Box>
            <Card>
              {loadingRank ? <SkeletonBodyText /> : allRanks && Array.isArray(allRanks) && allRanks.length > 0 ? <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    -- Đây là người mua (bậc 0)
                  </Text>
                  {product_commission.value.map((commission, index) => {
              return <div key={'index_product_commission_' + index}>
                        <Text as="h3" variant="headingMd">{`-- Hoa hồng giới thiệu bậc ${index + 1}`}</Text>
                        <Box paddingInlineStart={'600'} paddingBlock={'200'}>
                          <BlockStack gap={'400'}>
                            {useFields.product_commission_rank_data.value.map((commission_advance, index2) => {
                      if (commission_advance.commission_parent_index !== index) return undefined;
                      return <TextField size="slim" label={`Nếu người nhận là *** thì nhận thêm *** giá trị`} connectedLeft={<Select label="" labelHidden options={allRanks} onChange={value => product_commission_rank_data.onChange(product_commission_rank_data.value.map((item, i) => i === index2 ? {
                        ...item,
                        user_rank: value
                      } : item))} value={commission_advance.user_rank.toString()} />} labelAction={{
                        content: 'Xoá',
                        onAction: () => deleteRuleToProductCommissionAdvanceCallback(index2)
                      }} autoComplete="off" value={helpers.formatNumberWithCommas(commission_advance.commission_value.toString())} onChange={value => product_commission_rank_data.onChange(product_commission_rank_data.value.map((item, i) => i === index2 ? {
                        ...item,
                        commission_value: helpers.parseNumeric(value)
                      } : item))} placeholder="Giá trị" suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} />;
                    })}

                            <div>
                              <Button variant="plain" tone="success" onClick={() => addNewRuleToProductCommissionAdvanceCallback(index)} icon={PlusIcon}>
                                Thêm điều kiện nâng cao
                              </Button>
                            </div>
                          </BlockStack>
                        </Box>
                      </div>;
            })}

                  <Divider />

                  <Text as="p" tone="subdued">
                    Các cấp bậc hoa hồng được cài đặt tại phần "cài đặt mặc định"
                  </Text>
                </BlockStack> : <>Chưa có cài đặt rank nào!</>}
            </Card>
          </InlineGrid>

          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt chiết khấu
                </Text>
                <Text as="p" variant="bodyMd">
                  Quy tắc tại đây áp dụng cho người nhận được chiết khấu, nếu người đó không nhận được chiết khấu như trong cài đặt mặc định hoặc cài
                  đặt riêng lẻ trong sản phẩm, người đó không nhận được các ưu đãi này.
                  <br />
                  <br />
                  Cài đặt này cũng áp dụng cho chiết khấu phân tầng.
                </Text>
              </BlockStack>
            </Box>
            <Card>
              {loadingRank ? <SkeletonBodyText /> : <BlockStack gap="400">
                  {product_rebate_rank_data.value.map((rebate, index) => {
              return <InlineGrid columns={{
                xs: 1,
                md: 2
              }} gap="400" key={'index_product_rebate_' + index}>
                        <TextField autoComplete="off" label={`Giá trị chiết khấu cộng thêm ${index + 1}`} labelAction={{
                  content: __('commission_method_label_' + rebate.rebate_method),
                  onAction: () => {
                    product_rebate_rank_data.onChange(product_rebate_rank_data.value.map((item, i) => i === index ? {
                      ...item,
                      rebate_method: rebate.rebate_method === 'percent' ? 'fixed_amount' : 'percent'
                    } : item));
                  }
                }} value={helpers.formatNumberWithCommas(rebate.rebate_value.toString())} onChange={value => product_rebate_rank_data.onChange(product_rebate_rank_data.value.map((item, i) => i === index ? {
                  ...item,
                  rebate_value: helpers.parseNumeric(value)
                } : item))} suffix={rebate.rebate_method === 'percent' ? '%' : 'fixed'} connectedLeft={<Button onClick={() => deleteRuleToProductRebateAdvanceCallback(index)} variant="plain" icon={DeleteIcon}></Button>} />

                        <Select label={'Cho người dùng'} options={allRanks} onChange={value => product_rebate_rank_data.onChange(product_rebate_rank_data.value.map((item, i) => i === index ? {
                  ...item,
                  user_rank: String(value)
                } : item))} value={rebate.user_rank.toString()} />
                      </InlineGrid>;
            })}
                  <Button icon={PlusIcon} onClick={() => addNewRuleToProductRebateAdvanceCallback()} variant="primary">
                    Thêm quy tắc
                  </Button>
                  <Text as="p" tone="subdued">
                    Chiết khấu là số tiền cứng sau khi bán một sản phẩm. Số tiền này được hoàn lại vào tài khoản của người mua sau khi đơn hàng được
                    đối soát.
                  </Text>

                  <br />
                  <Divider />
                  <br />

                  <Text as="h3" variant="headingMd">
                    Áp dụng quy tắc này cho
                  </Text>

                  <ChoiceList allowMultiple title="" choices={[{
              label: 'Chiết khấu',
              value: 'allowed_rebate'
            }, {
              label: 'Chiết khấu phân tầng',
              value: 'allowed_tiered_rebate'
            }]} selected={useFields.affiliate_advance_setting_mode.value || []} onChange={val => useFields.affiliate_advance_setting_mode.onChange(val)} />
                </BlockStack>}
            </Card>
          </InlineGrid>
        </BlockStack>
      </Page>} v2={dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
    onAction: submit,
    loading: creatingProductCommissionRank,
    disabled: !dirty
  }} discardAction={{
    content: 'Hoàn tác',
    onAction: Userreset
  }} />} />;
};
import { block as _block } from "million/react";
import { BlockStack, Box, Button, Card, ChoiceList, ContextualSaveBar, Divider, InlineGrid, Page, Select, SkeletonBodyText, Text, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedProductCommission } from 'interface/product.model';
import { useField, useForm } from '@shopify/react-form';
import { TypedProductCommissionRank, TypedProductRebateRank, useCreateDefaultProductCommissionRank, useCreateDefaultProductRebateRank, useGetDefaultAffiliateSetting, useGetDefaultProductCommissionRank, useGetDefaultProductRebateRank } from 'queries/default_product_commission.query';
import { useGetRanks } from 'queries/rank.query';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
export default AffiliateAdvanceSetup;