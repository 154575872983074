import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedLogin, TypedRegister, TypedUser } from "interface/user.model";
import queryClient from 'queries';

type IQuery = TypedUser & IQueryParams;

/** 
 * Trả về user data hoặc là throw lỗi nếu chưa login ...
 */
export function useGetCurrentUserData() {
    return useQuery({
        queryKey: ["user"],
        queryFn: () => axios.get<TypedUser>("/user/me").then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
    });
}


export function useGetEntity() {
    return useMutation({
        mutationKey: ['user/fetch_entity'],
        mutationFn: (userID: string) => axios.get<TypedUser>(`/user/${userID}`).then((res) => res.data),
    });
}


export function useUserLogout() {
    return useMutation({
        mutationKey: ['user_logout'],
        mutationFn: () => axios.get<any>("/logout"),
        onSuccess: () => {
            /** Để reset lại data, chỉ cần nhớ key, nó sẽ refetch lại ... */
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    })
}


export function useUpdateProfile() {
    return useMutation({
        mutationKey: ['user_update_profile'],
        mutationFn: ({ user_id: user_profile_id, ...entity }: TypedUser) => axios.patch<TypedUser>(`/user/`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            /** Để reset lại data, chỉ cần nhớ key, nó sẽ refetch lại ... */
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    })
}


export function useAddUserToJob() {
    return useMutation({
        mutationKey: ['user_add_user_to_job'],
        mutationFn: (entity: any) => axios.post<any>(`/user/user_to_job/`, helpers.cleanEntity(entity)),
    })
}

/** Payment method ? Chưa save */


export interface TypedUser_bank_information {
    id?: string;
    user_id?: string;
    bank_name?: string;
    bank_owner_display_name?: string;
    bank_owner_number_account?: string;
    bank_owner_card_number?: string;
    createdAt?: string;
}


export function useGetUserPayment() {
    return useQuery({
        queryKey: ["user_get_user_payment"],
        queryFn: () => axios.get<TypedUser_bank_information>("/user_bank_information").then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
    });
}

/** For admin */
export function useAdminGetUserPayment() {
    return useMutation({
        mutationKey: ["admin_get_user_payment"],
        mutationFn: (user_id: string) => axios.get<TypedUser_bank_information>(`/user_bank_information/admin/${user_id}`).then((res) => res.data),
        retry: 1,
    });
}

/** For Admin  */
export function useAdminUpdateUserPayment() {
    return useMutation({
        mutationKey: ['user_add_user_payment'],
        mutationFn: (entity: TypedUser_bank_information) => axios.patch<TypedUser_bank_information>(`/user_bank_information/admin`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            /** Để reset lại data, chỉ cần nhớ key, nó sẽ refetch lại ... */
            queryClient.invalidateQueries({ queryKey: ['user_get_user_payment'] });
        }
    })
}

export function useUpdateUserPayment() {
    return useMutation({
        mutationKey: ['user_add_user_payment'],
        mutationFn: (entity: TypedUser_bank_information) => axios.patch<TypedUser_bank_information>(`/user_bank_information`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            /** Để reset lại data, chỉ cần nhớ key, nó sẽ refetch lại ... */
            queryClient.invalidateQueries({ queryKey: ['user_get_user_payment'] });
        }
    })
}

/** END USER Payment method */



export function useUserQuickLogin() {
    return useMutation({
        mutationKey: ['quick_login'],
        mutationFn: (entity: TypedLogin) => axios.post<TypedLogin>(`login/onetimepassword`, helpers.cleanEntity(entity))
    })
}

export function useUserLogin() {
    return useMutation({
        mutationKey: ['manual_login'],
        mutationFn: (entity: TypedLogin) => axios.post<any>(`login`, helpers.cleanEntity(entity))
    })
}

export function useSearchUser() {
    return useMutation({
        mutationKey: ['user_search'],
        mutationFn: (object: IQuery) => axios.get<TypedUser[]>(`user/search${helpers.buildEndUrl(object)}`).then(response => response.data)
    })
}



export function useCheckActiveCode() {
    return useMutation({
        mutationKey: ['user_check_active_code'],
        mutationFn: (entity: any) => axios.post<TypedLogin>(`user/check_active_code`, helpers.cleanEntity(entity))
    })
}


export function useActiveAccount() {
    return useMutation({
        mutationKey: ['user_active_account'],
        mutationFn: (entity: any) => axios.post<TypedLogin>(`user/active_account`, helpers.cleanEntity(entity))
    })
}

export function useLoginByFacebook() {
    return useMutation({
        mutationKey: ['user_facebook_login'],
        mutationFn: (entity: TypedLogin) => axios.post<TypedLogin>(`user/active_account`, helpers.cleanEntity(entity))
    })
}


/**
 *  user birthday ...
 */

export function useGetUserBirthday(object: IQuery) {
    return useQuery({
        queryKey: ["user_get_birthday"],
        queryFn: () => axios.get<any>(`user/birthday${helpers.buildEndUrl(object)}`).then(res => res.data) // không cần lấy header thì lấy body không!
    });
}




/**
 * JamDev: register
 */
export function useUserRegister() {
    return useMutation({
        mutationKey: ['user_register'],
        mutationFn: (entity: TypedRegister) => axios.post<TypedRegister>(`register`, helpers.cleanEntity(entity))
    })
}


/**
 * JamDev: RecoverPassword
 */

export function useUserRecoverPassword() {
    return useMutation({
        mutationKey: ['user_recover_password'],
        mutationFn: (entity: { user_email?: string, user_phonenumber?: string }) => axios.post<TypedRegister>(`recover_password`, helpers.cleanEntity(entity))
    })
}


/**
 * Set new Password
 */

export function useUserSetNewPassword() {
    return useMutation({
        mutationKey: ['user_set_new_password'],
        mutationFn: (entity: { password: string, user_email?: string, user_phonenumber?: string, code?: string, current_password?: string }) => axios.patch<any>(`user/set_new_password`, helpers.cleanEntity(entity))
    })
}


/** Generate password */
export function useGenerateWebAuthLoginOption() {
    return useMutation({
        mutationKey: ['webauthn/generate-authentication-options'],
        mutationFn: () => axios.get<any>(`/webauthn/generate-authentication-options`)
    })
}

/** webauthn */

export function useGenerateWebAuthRegisterOption() {
    return useMutation({
        mutationKey: ['webauthn/generate-registration-options'],
        mutationFn: () => axios.get<any>(`/webauthn/generate-registration-options`)
    })
}


export function useVerifyWebAuthlogin() {
    return useMutation({
        mutationKey: ['webauthn/verify-authentication'],
        mutationFn: (authResp: {
            data: any,
            challenge: string,
            [propKey: string]: any
        }) => axios.post<any>(`/webauthn/verify-authentication`, helpers.cleanEntity(authResp))
    })
}

export function useVerifyWebAuthRegister() {
    return useMutation({
        mutationKey: ['webauthn/verify-registration'],
        mutationFn: (authResp: {
            data: any,
            challenge: string,
            [propKey: string]: any
        }) => axios.post<any>(`/webauthn/verify-registration`, (authResp)),
        onSuccess: () => {
            /** Để reset lại data, chỉ cần nhớ key, nó sẽ refetch lại ... */
            queryClient.invalidateQueries({ queryKey: ['webauthn/get_all_passkey'] });
        }
    })
}


export function useGetAllMyPasskey() {
    return useQuery({
        queryKey: ['webauthn/get_all_passkey'],
        queryFn: () => axios.get<any[]>(`/webauthn`).then((res) => res.data),
    })
}


export function useDeletePasskey() {
    return useMutation({
        mutationKey: ['webauthn/deletePasskey'],
        mutationFn: (passkeyID: any) => axios.delete<any>(`/webauthn/${passkeyID}`),
        onSuccess: () => {
            /** Để reset lại data, chỉ cần nhớ key, nó sẽ refetch lại ... */
            queryClient.invalidateQueries({ queryKey: ['webauthn/get_all_passkey'] });
        },
    })
}

/** webauthn */



/** Sử dụng nó trước các router yêu cầu cần signed */
export function useSignTest() {
    return useQuery({
        queryKey: ['login/user_sign_test'],
        queryFn: () => axios.get<any>(`/login/sign_test`),
        refetchOnWindowFocus: true,
    });
}