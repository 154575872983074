import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Divider, IndexTable, Link, Page, SkeletonTabs, Text, Thumbnail, useIndexResourceState } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import helpers from 'helpers/index';
import Theme404 from 'layout/404';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import QuickSearchProduct from 'components/quickSearchProduct';
import { useDeleteProductBrand, useGetProductBrand } from 'queries/brand.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useCreateProductToBrand, useDeleteProductToBrand, useGetProductToBrands } from 'queries/product_to_brand_query';
import ProductCategoryModalAddAndEdit from 'entities/brands/brand.modal';
export default function BrandDetail() {
  let {
    brand_id
  } = useParams();
  const {
    data: entity,
    isLoading: loading
  } = useGetProductBrand(brand_id);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductBrand();
  const modalEntity = useRef(null);

  /** relationship */
  const {
    data: productToBrandData,
    refetch: getRelationship
  } = useGetProductToBrands({
    limit: 100,
    brand_id: String(brand_id)
  });
  const {
    mutateAsync: createRelationship,
    isPending: updatingRelationship
  } = useCreateProductToBrand();
  const {
    mutateAsync: deleteRelationship
  } = useDeleteProductToBrand();
  const [allRelationship, setAllRelationship] = useState([]);
  useEffect(() => {
    if (productToBrandData) {
      let {
        entities
      } = productToBrandData;
      setAllRelationship(entities);
    }
  }, [productToBrandData]);
  const history = useNavigate();

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    modalEntity.current = null;
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async brand_id => {
    const confirmed = await showDeleteConfirm('Xóa nhãn hiệu này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(brand_id);
      history('/product_brand');
    }
  }, []);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const resourceIDResolver = products => {
    return products.product_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(allRelationship ?? [], {
    resourceIDResolver
  });
  const [bulkDeleting, setBulkDeleting] = useState(false);
  const bulkDeleteActions = useCallback(async () => {
    setBulkDeleting(true);
    await helpers.sleep(1000);
    try {
      await deleteRelationship({
        brand_id: brand_id,
        product_id: helpers.ArrayToComma(selectedResources)
      });
    } catch (e) {}
    setBulkDeleting(false);
    clearSelection();
  }, [selectedResources]);
  const DisplayListOfProduct = () => {
    if (allRelationship === null) return <SkeletonTabs />;
    if (Array.isArray(allRelationship) && allRelationship.length < 1) return <Text as="p" tone="subdued">
          Chưa có gì ... Bấm thêm sản phẩm để thêm vào nhãn hiệu.
        </Text>;
    return <Card padding="0">
        <IndexTable resourceName={{
        singular: 'product',
        plural: 'products'
      }} itemCount={allRelationship?.length} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={[]} onSort={null} loading={updatingRelationship} promotedBulkActions={[{
        content: 'Xóa khỏi nhãn hiệu',
        onAction: () => bulkDeleteActions(),
        icon: DeleteIcon,
        disabled: bulkDeleting
      }]} sortable={[]} headings={[{
        title: ''
      }, {
        title: 'Sản phẩm'
      }, {
        title: 'Tình trạng'
      }, {
        title: 'Giá bán',
        alignment: 'end'
      }, {
        title: 'Giá gốc',
        alignment: 'end'
      }]}>
          {allRelationship.map(({
          product
        }, index) => {
          let {
            product_id,
            product_name,
            product_price,
            product_original_price,
            product_type,
            product_sku,
            product_status,
            product_sold_quantity,
            product_thumbnail_to_media
          } = product;
          return <IndexTable.Row id={product_id} key={`AU_` + product_id} selected={selectedResources.includes(product_id)} position={index}>
                <IndexTable.Cell>
                  <Thumbnail source={helpers.getMediaLink(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail['128x128'] : undefined)} alt={'product thumbnail: ' + product_name} />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Link onClick={() => history('/product/edit/' + product_id)}>{product_name}</Link>
                </IndexTable.Cell>
                <IndexTable.Cell>{product_status}</IndexTable.Cell>
                <IndexTable.Cell>
                  <Text as="span" variant="bodyMd" alignment="end">
                    {helpers.formatNumber(product_price, ',')}
                  </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Text as="span" variant="bodyMd" alignment="end">
                    {helpers.formatNumber(product_original_price, ',')}
                  </Text>
                </IndexTable.Cell>
              </IndexTable.Row>;
        })}
        </IndexTable>
      </Card>;
  };
  const actualPage = !helpers.isEmpty(entity) ? <Page title={entity.brand_name} subtitle={entity.brand_excerpt} backAction={{
    content: 'Back',
    onAction: () => history('/product_brand')
  }} primaryAction={{
    content: 'Thêm sản phẩm',
    icon: PlusIcon,
    onAction: () => setShowProductModal(true)
  }} secondaryActions={[{
    content: 'Xóa nhãn hiệu',
    onAction: () => handleDelete(entity?.brand_id)
  }, {
    content: 'Chỉnh sửa',
    onAction: () => {
      modalEntity.current = entity;
      setShowEditModal(true);
    }
  }]}>
      <div className="content-wrap">{Parser(entity?.brand_description ?? '')}</div>
      <br />
      <Divider />
      <br />
      <DisplayListOfProduct />
    </Page> : <Theme404 />;
  const [showProductModal, setShowProductModal] = useState(false);
  const onCloseProductModal = useCallback(async (product_id: bigint | null) => {
    if (product_id) {
      createRelationship({
        brand_id: String(brand_id),
        product_id: String(product_id)
      });
    }
    setShowProductModal(false);
  }, [brand_id]);
  return <Capacity current_user_can={['manage_products', 'view_products', 'edit_products']} onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <ProductCategoryModalAddAndEdit show={showEditModal} onClose={closeEditModal} entity={modalEntity.current} />
      <QuickSearchProduct show={showProductModal} onClose={onCloseProductModal} />
      {loading ? <SkeletonLoading /> : actualPage}

      <br />
      <br />
      <br />
      <br />
      <br />
    </Capacity>;
}