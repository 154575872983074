import { Page, Tabs, InlineStack } from '@shopify/polaris';
import { Helmet } from 'react-helmet-async';
import SocialPost from './socialPost';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers/index';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
export default function SocialTab() {
  const [selected, setSelected] = useState(0);
  const [selectedProductID, setSelectedProductID] = useState<string | null>(null);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);
  const tabs = [{
    id: 'live',
    content: 'Thông báo'
  }, {
    id: 'business',
    content: 'Đăng bán'
  }];
  const [mode, setMode] = useState('');
  useEffect(() => {
    let find_id = tabs[selected].id || '';
    setMode(find_id);
  }, [selected]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl({
      mode,
      social_suggest_product_id: selectedProductID
    });
    if (window.location.search !== buildURLSearch) window.history.replaceState(null, 'Social', '/social' + buildURLSearch);
  }, [mode, selectedProductID]);
  return <>
      <Helmet>
        <title>Social</title>
      </Helmet>
      <div>
        <Page narrowWidth>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
        </Page>
      </div>
      <Page narrowWidth>
        <InlineStack gap="200">
          <QuickSearchProduct2 allowMultiple={false} current_product_id={selectedProductID ? [selectedProductID] : []} label={'Lọc theo sản phẩm'} onClose={product => setSelectedProductID(product[0]?.product_id || null)} />
        </InlineStack>
        <SocialPost mode={mode} selectedProductID={selectedProductID} />
      </Page>
    </>;
}