import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/


export interface TypedUser_capacity_group {
    capacity_group_id?: string;
    capacity_group_name?: string;
    capacity_group_description?: string;
    user_capacity_group_permission?: { [key: string]: string[] } | null;
    createdAt?: string | bigint;
}

type IQuery = TypedUser_capacity_group & IQueryParams;


/*
* List of all app
*/
export function useGetUserCapacityGroups(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user_capacity_group/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_capacity_group[]>(`/user_capacity_group${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetUserCapacityGroup(capacity_group_id: string) {
    return useQuery({
        queryKey: ['user_capacity_group/fetch_entity'],
        queryFn: () => axios.get<TypedUser_capacity_group>(`/user_capacity_group/${capacity_group_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateUserCapacityGroup() {
    return useMutation({
        mutationKey: ['user_capacity_group/update_entity'],
        mutationFn: ({ capacity_group_id, ...rest }: TypedUser_capacity_group) => axios.patch<TypedUser_capacity_group>(`/user_capacity_group/${capacity_group_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_capacity_group/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['user_capacity_group/fetch_entity'] });
        }
    });
}


export function useCreateUserCapacityGroup() {
    return useMutation({
        mutationKey: ['user_capacity_group/create_entity'],
        mutationFn: (entity: TypedUser_capacity_group) => axios.post<TypedUser_capacity_group>(`/user_capacity_group`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_capacity_group/fetch_entity_list'] });
        }
    });
}


export function useDeleteUserCapacityGroup() {
    return useMutation({
        mutationKey: ['user_capacity_group/delete_entity'],
        mutationFn: (app_id: string) => axios.delete<any>(`/user_capacity_group/${app_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_capacity_group/fetch_entity_list'] });
        }

    });
}



/** lấy tất thảy các quyền */
type TypedPermission = {
    [propKey: string]: string[] | null
}
export function useGetUserCapacityPermission() {
    return useQuery({
        queryKey: ['user_capacity_group/permission'],
        queryFn: () => axios.get<TypedPermission>(`/user_capacity_group/permission`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
