import { useCallback, useEffect } from 'react';
import { lengthLessThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { Form, Modal, TextField, BlockStack, Checkbox } from '@shopify/polaris';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import helpers from 'helpers/index';
import { TypedUser_capacity_group, useCreateUserCapacityGroup, useUpdateUserCapacityGroup } from 'queries/user_capacity_group.query';
export default function UserCapacityGroupNewModal({
  open = false,
  entity,
  onClose
}: {
  open: boolean;
  onClose: Function;
  entity?: TypedUser_capacity_group;
}) {
  const {
    mutateAsync: createUserRole,
    isSuccess: createSuccesfully
  } = useCreateUserCapacityGroup();
  const {
    mutateAsync: updateUserRole,
    isSuccess: updateSuccessfully
  } = useUpdateUserCapacityGroup();
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (createSuccesfully) {
      addNotification('info', __('created_successfully'));
      closeModal();
    }
  }, [createSuccesfully]);
  useEffect(() => {
    if (updateSuccessfully) {
      addNotification('info', __('updated_successfully'));
      closeModal();
    }
  }, [updateSuccessfully]);
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
    resetForm();
  }, []);
  const useFields = {
    capacity_group_name: useField<string>({
      value: entity?.capacity_group_name ?? undefined,
      validates: [inputVal => {
        if (helpers.isUTF8(inputVal)) {
          return 'Chỉ cho phép A-Z, a-z và số, gạch dưới. Không sử dụng UTF-8.';
        }
      }]
    }),
    capacity_group_description: useField<string>({
      value: entity?.capacity_group_description ?? undefined,
      validates: [notEmpty('Không được để trống trường này.'), lengthLessThan(1000, 'Không được quá 1000 ký tự.')]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!entity) {
          await createUserRole({
            capacity_group_name: values.capacity_group_name,
            capacity_group_description: values.capacity_group_description
          });
        } else {
          await updateUserRole({
            capacity_group_id: entity?.capacity_group_id,
            capacity_group_name: values.capacity_group_name,
            capacity_group_description: values.capacity_group_description
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  return <Modal open={open} title={'Thêm một nhóm quyền'} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap={'400'}>
            <TextField autoFocus autoComplete="off" label="Tên nhóm quyền" helpText={`Chỉ sử dụng chữ, số và gạch dưới.`} {...fields.capacity_group_name} />
            <TextField multiline={2} autoComplete="off" label="Mô tả" {...fields.capacity_group_description} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}