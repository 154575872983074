import React, { useEffect, useRef } from 'react';
import bankList from 'config/bank_list.json';
import { Autocomplete, Icon } from '@shopify/polaris';
import { SearchIcon } from '@shopify/polaris-icons';
import { useState, useCallback, useMemo } from 'react';
import { TypedBanner_tag, useGetBannerTags } from 'queries/banner.query';
export default function QuickSearchBannerTag({
  current_group_id,
  onClose
}: {
  current_group_id?: string;
  onClose?: (bank_id: string) => void;
}) {
  const {
    data,
    isFetching
  } = useGetBannerTags({
    limit: 40
  });
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  useEffect(() => {
    if (!data) return;
    let {
      body,
      totalItems
    } = data;
    let x = [];
    for (let a of body) {
      x.push({
        value: a.tag_slug,
        label: a.tag_name
      });
    }
    setDeselectedOptions(x);
    setOptions(x);
  }, [data]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState(current_group_id);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (current_group_id) {
      let [currentData] = deselectedOptions.filter(el => el.value === current_group_id);
      setInputValue(currentData?.label ?? '');
      setSelectedOptions([currentData?.value]);
    }
  }, [current_group_id]);
  const updateText = useCallback((value: string) => {
    setInputValue(value);
    if (value === '') {
      setOptions(deselectedOptions);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = deselectedOptions.filter(option => option.label.match(filterRegex));
    setOptions(resultOptions);
  }, [deselectedOptions]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = options.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setSelectedOptions(selected);
    setInputValue(selectedValue[0] || '');
    onClose(selected[0]); // call back
  }, [options]);
  const textField = <Autocomplete.TextField onChange={updateText} label="Chọn nhóm" value={inputValue} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm" autoComplete="off" />;
  return <Autocomplete options={options} selected={selectedOptions} onSelect={updateSelection} textField={textField} />;
}