import { ActionList, Avatar, Button, Card, InlineGrid, Link, Page, Popover } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IndexTable, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
import { useGetUserVerifiedRequest, useUpdateUserVerified, TypedUser_verified } from 'queries/user_verified.query';
export default function UserVerifiedList() {
  const {
    mutateAsync: updateEntity
  } = useUpdateUserVerified();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    data: entities,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetUserVerifiedRequest(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedUser_verified[]>([]);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setRecords(body);
      setTotalItems(totalItems);
    }
  }, [entities]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Các yêu cầu chứng thực', '/user_verified' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Action menu ...
   */

  const [currentLoading, setCurrentLoading] = useState<any>(null);
  const quickUpdateVerifiedStatus = useCallback(async (id: any, status: number) => {
    setShowActionList(null);
    setCurrentLoading(id);
    let data = await updateEntity({
      id: id,
      verified_status: status
    });
    if (data) {
      let M = records.map(el => {
        if (el.id === data.id) {
          return data;
        }
        return el;
      });
      setRecords(M);
    }
    setCurrentLoading(null);
  }, [records]);

  // ------------
  const [showActionList, setShowActionList] = useState(null);
  const onCloseActionList = useCallback((id: any) => {
    setShowActionList(!id);
  }, []);
  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);
  const rowMarkup = records?.map(({
    id,
    user_face_image,
    createdAt,
    updatedAt,
    user,
    confirmer,
    verified_status
  }, index) => <IndexTable.Row id={`user_verified_` + id} position={index} key={`user_verified_` + index}>
      <IndexTable.Cell className="table_app_cellMinWidth">
        <Link onClick={() => history('/user_verified/view/' + id)}>
          <InlineGrid columns={['oneThird', 'twoThirds']} gap={'100'} alignItems="center">
            <Avatar size="lg" customer name={user?.display_name} initials={String(user?.display_name || 'R').charAt(0)} source={user_face_image ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + user_face_image : 'https://placehold.co/100x100'} />
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {user?.display_name}
            </Text>
          </InlineGrid>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Popover active={showActionList === id} activator={<Button loading={currentLoading === id} variant="tertiary" disclosure={'down'} onClick={() => togglePophoverActionListCallback(id)}>
              {verified_status === 1 ? 'Duyệt' : verified_status === 0 ? 'Chưa duyệt' : 'Đã từ chối'}
            </Button>} autofocusTarget="first-node" onClose={() => onCloseActionList(id)}>
          <ActionList actionRole="menuitem" items={[{
          content: 'Duyệt',
          onAction: () => quickUpdateVerifiedStatus(id, 1)
        }, {
          content: 'Từ chối',
          onAction: () => quickUpdateVerifiedStatus(id, -1)
        }]} />
        </Popover>
      </IndexTable.Cell>
      <IndexTable.Cell>{confirmer?.display_name}</IndexTable.Cell>
      <IndexTable.Cell>{dateandtime.format(new Date(Number(updatedAt)), 'DD-MM-YYYY HH:mm:ss')}</IndexTable.Cell>
      <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss')}</IndexTable.Cell>
    </IndexTable.Row>);
  return <Capacity current_user_can="manage_user_verified" onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <Page title="Danh sách yêu cầu chứng thực" subtitle="Các yêu cầu chứng thực của người dùng">
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'Chưa duyệt',
          value: '0',
          field: 'verified_status'
        }, {
          label: 'Từ chối',
          value: '-1',
          field: 'verified_status'
        }, {
          label: 'Đã duyệt',
          value: '1',
          field: 'verified_status'
        }]} sortField={[]} />
          <IndexTable resourceName={{
          singular: 'Các yêu cầu',
          plural: 'Các yêu cầu'
        }} itemCount={totalItems || 1} headings={[{
          title: 'Người gửi'
        }, {
          title: 'Tình trạng'
        }, {
          title: 'Người duyệt'
        }, {
          title: 'Ngày duyệt'
        }, {
          title: 'Ngày tạo'
        }]} loading={loading} selectable={false}>
            {records?.length > 0 && rowMarkup}
          </IndexTable>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </Capacity>;
}