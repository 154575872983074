import { compiledBlock as _compiledBlock } from "million/react";
const ListVoucher_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ListVoucher_1",
  portals: ["v0", "v1"]
});
const ListVoucher = function ListVoucher() {
  const history = useNavigate();

  /** Lấy user role ra ... */
  const {
    allRoleSelection: allRoles
  } = useUserRoleContext();

  /** Hết code lấy user role ra ... */

  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const [filterTimeCurrentValue, setFilterTimeCurrentValue] = useState('');
  useEffect(() => {
    let current_mode = mainQuery.current_mode || '';
    setFilterTimeCurrentValue(current_mode);
  }, [mainQuery]);
  const filterTime = useCallback((value: string) => {
    let voucher_valid_from_value = '',
      voucher_valid_to_value = '';
    if ('current' === value) {
      voucher_valid_from_value = ``;
      voucher_valid_to_value = `gte:${new Date().getTime()}`;
    } else if ('intheFuture' === value) {
      voucher_valid_from_value = `gt:${new Date().getTime()}`;
      voucher_valid_to_value = '';
    }
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: 1,
      voucher_valid_from: voucher_valid_from_value,
      voucher_valid_to: voucher_valid_to_value,
      current_mode: value
    }));
  }, []);
  const filterUserRole = useCallback((value: string) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: 1,
      voucher_user_role: value
    }));
  }, []);
  const {
    data,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetVouchers(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedVoucher[]>([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Voucher', '/voucher' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  const {
    lgDown
  } = useBreakpoints();
  const fixedFirstColumns = lgDown ? 2 : 0;
  return /*@million jsx-skip*/<ListVoucher_1 v0={<Helmet>
        <title>Quản lý mã khuyến mại</title>
      </Helmet>} v1={<Page title="Quản lý mã khuyến mại" primaryAction={{
    content: 'Thêm mới Voucher',
    icon: PlusIcon,
    onAction: () => history('/voucher/new')
  }}>
        <Card padding={'0'}>
          <SimpleFilter onCallback={filterCallback} options={[{
        label: 'Mã vận chuyển',
        field: 'voucher_category',
        value: 'shipping'
      }, {
        label: 'Mã đơn hàng',
        field: 'voucher_category',
        value: 'order'
      }, {
        label: 'Mã hoàn tiền',
        field: 'voucher_category',
        value: 'rebate'
      }]} loading={false} sortField={[{
        label: 'Số lượng đã sử dụng',
        field: 'voucher_count'
      }, {
        label: 'Số lượng cho phép',
        field: 'voucher_count_max'
      }]} />
        </Card>
        <br />
        <InlineStack gap="400" align="start" blockAlign="center">
          <Select label="Thời gian nào?" options={[{
        label: 'Tất cả các khoảng thời gian',
        value: ''
      }, {
        label: 'Đang diễn ra',
        value: `current`
      }, {
        label: 'Sắp diễn ra',
        value: `intheFuture`
      }]} onChange={filterTime} value={filterTimeCurrentValue} />
          <Select label="Dành cho ai?" options={[...[{
        label: 'Tất cả người dùng',
        value: ''
      }], ...allRoles]} onChange={filterUserRole} value={mainQuery.voucher_user_role || ''} />
        </InlineStack>
        <br />
        <BlockStack gap="400">
          {records && helpers.isEmpty(records) && !loading && <BlockStack gap={'400'} align="center" inlineAlign="center">
              <Text as="h3" variant="headingMd">
                Chỗ này trống trơn
              </Text>
              <div style={{
          maxWidth: 250
        }}>
                <Lottie animationData={voucher_empty} loop={true} />
              </div>
            </BlockStack>}
          {records && Array.isArray(records) && records.length > 0 && <DataTable columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Voucher', 'Mã', 'Giá trị/tối đa', 'Đã dùng', 'Bắt đầu', 'Kết thúc', 'Ngày tạo']} verticalAlign="middle" rows={records.map((element, index) => {
        let {
          voucher_id,
          voucher_valid_from,
          voucher_valid_to,
          voucher_name,
          voucher_excerpt,
          voucher_code,
          voucher_count,
          voucher_count_max,
          voucher_thumbnail_to_media,
          voucher_value,
          voucher_value_max,
          is_active,
          createdAt
        } = element;
        return [<Link url={`/voucher/view/${voucher_id}`}>
                    <InlineStack gap="400" align="start" blockAlign="center">
                      {voucher_thumbnail_to_media ? <Thumbnail source={helpers.getMediaLink(voucher_thumbnail_to_media.media_thumbnail ? voucher_thumbnail_to_media.media_thumbnail['128x128'] : voucher_thumbnail_to_media.media_url)} alt={''} size="small" /> : <Thumbnail source={voucher_default_image} alt="" size="small" />}

                      <span>{voucher_name}</span>
                    </InlineStack>
                  </Link>, voucher_code, helpers.formatNumberWithCommas(voucher_value), <span style={{
          width: 50
        }}>
                    <Tooltip content={`Đã dùng ${voucher_count} trong tổng số tối đa ${voucher_count_max}`}>
                      <ProgressBar progress={Math.floor(voucher_count / voucher_count_max * 100)} size="small" />
                    </Tooltip>
                  </span>, dateandtime.format(new Date(Number(voucher_valid_from)), 'HH:mm DD-MM-YYYY'), dateandtime.format(new Date(Number(voucher_valid_to)), 'HH:mm DD-MM-YYYY'), dateandtime.format(new Date(Number(createdAt)), 'HH:mm DD-MM-YYYY')];
      })} sortable={[false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={4} stickyHeader fixedFirstColumns={fixedFirstColumns} />}
        </BlockStack>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>} />;
};
import { block as _block } from "million/react";
import { BlockStack, Card, Text, Link, Page, ProgressBar, Thumbnail, Tooltip, DataTable, useBreakpoints, InlineStack, Select } from '@shopify/polaris';
import SimpleFilter from 'components/simpleFilter';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon } from '@shopify/polaris-icons';
import 'media/css/voucher.scss';
import voucher_default_image from 'media/images/voucher_default_image.png';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import { useNavigate } from 'react-router-dom';
import { TypedVoucher, useGetVouchers } from 'queries/voucher.query';
import dateandtime from 'date-and-time';
import voucher_empty from 'media/lottie_files/voucher_empty.json';
import Lottie from 'lottie-react';
import __ from 'languages/index';
import { useUserRoleContext } from 'context/UserRoleContext';
export default ListVoucher;