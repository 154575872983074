import { ActionList, Avatar, BlockStack, Button, Card, InlineStack, Link, Page, Popover, Thumbnail, Tooltip } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import { useState, useEffect, useCallback } from 'react';
import { IndexTable, Text } from '@shopify/polaris';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
import StarRating from 'components/starRating';
import { TypedReviewMeta, useGetReviewMetas, useUpdateReviewMeta } from 'queries/review.query';
import { Helmet } from 'react-helmet-async';
import { PlusIcon } from '@shopify/polaris-icons';
import CreateReviewModal from './createReview.modal';
export default function ListReview() {
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    mutateAsync: updateReviewMeta
  } = useUpdateReviewMeta();
  const {
    data,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetReviewMetas(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedReviewMeta[]>([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Review', '/review' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Action menu ...
   */

  const [currentLoading, setCurrentLoading] = useState<any>(null);
  const quickUpdateVerifiedStatus = useCallback(async (review_meta_id: any, status: number) => {
    setShowActionList(null);
    setCurrentLoading(review_meta_id);
    try {
      let data = await updateReviewMeta({
        review_meta_id: review_meta_id,
        review_status: status
      });
      if (data) {
        let M = records.map(el => {
          if (el.review_meta_id === data.review_meta_id) {
            return data;
          }
          return el;
        });
        setRecords(M);
      }
    } catch (e) {
      console.error(e, 'update review status error.');
    }
    setCurrentLoading(null);
  }, [records]);

  // ------------
  const [showActionList, setShowActionList] = useState(null);
  const onCloseActionList = useCallback((id: any) => {
    setShowActionList(!id);
  }, []);
  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);
  const rowMarkup = records?.map(({
    review_meta_id,
    review_title,
    review_status,
    review_point,
    review_id,
    review,
    review_media,
    createdAt
  }, index) => <IndexTable.Row id={`user_verified_` + review_id} position={index} key={`user_verified_` + index}>
        <IndexTable.Cell>
          <InlineStack align="start" blockAlign="center" gap="200">
            <Thumbnail alt="" size="small" source={helpers.getMediaLink(review_media ? review_media[0]?.review_to_media.media_thumbnail ? review_media[0]?.review_to_media.media_thumbnail['128x128'] : 'https://placehold.co/128x128' : 'https://placehold.co/128x128')} />
            <Link url={`/review/view/${review_id}`} removeUnderline>
              <Text as="p" fontWeight="bold">
                {review_title}
              </Text>
            </Link>
          </InlineStack>
        </IndexTable.Cell>

        <IndexTable.Cell className="table_app_cellMinWidth">
          <BlockStack gap="200">
            <Tooltip content={review?.product?.product_name}>
              <Link removeUnderline url={`/product/edit/${review?.product_id}`}>
                {helpers.trimMiddleString(review?.product?.product_name)}
              </Link>
            </Tooltip>
            <Text as="p" tone="subdued">
              <StarRating size="small" num={review?.product?.product_meta?.product_review_point || 0} /> /{' '}
              {review?.product?.product_meta?.product_review_count} đánh giá
            </Text>
          </BlockStack>
        </IndexTable.Cell>
        <IndexTable.Cell className="table_app_cellMinWidth">
          <InlineStack gap={'400'} align="start" blockAlign="center">
            <Avatar size="sm" customer name={review?.user?.display_name} initials={String(review?.user?.display_name || 'R').charAt(0)} source={review?.user?.user_avatar ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + review?.user.user_avatar : 'https://placehold.co/100x100'} />
            <Link url={`/customer/view/${review?.user?.user_id}`}>
              <Text variant="bodyMd" fontWeight="bold" as="span">
                {review?.user?.display_name}
              </Text>
            </Link>
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <StarRating num={review_point} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<Popover active={showActionList === review_meta_id} activator={<Button loading={currentLoading === review_meta_id} variant="tertiary" disclosure={'down'} onClick={() => togglePophoverActionListCallback(review_meta_id)}>
                  {review_status === 1 ? 'Duyệt' : review_status === 0 ? 'Chưa duyệt' : 'Đã từ chối'}
                </Button>} autofocusTarget="first-node" onClose={() => onCloseActionList(review_meta_id)}>
              <ActionList actionRole="menuitem" items={[{
          content: 'Duyệt',
          onAction: () => quickUpdateVerifiedStatus(review_meta_id, 1)
        }, {
          content: 'Từ chối',
          onAction: () => quickUpdateVerifiedStatus(review_meta_id, -1)
        }]} />
            </Popover>}
        </IndexTable.Cell>
        <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss')}</IndexTable.Cell>
      </IndexTable.Row>);
  const [createModal, setCreateModal] = useState(false);
  return <Capacity current_user_can={['manage_reviews', 'view_reviews', 'edit_reviews']} onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <Helmet>
        <title>Danh sách nhận xét</title>
      </Helmet>
      <Page title="Danh sách nhận xét" subtitle="Các nhận xét của người dùng" primaryAction={{
      content: 'Tạo review',
      icon: PlusIcon,
      onAction: () => setCreateModal(true)
    }} fullWidth>
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'Chưa duyệt',
          value: '0',
          field: 'review_status'
        }, {
          label: 'Đã duyệt',
          value: '1',
          field: 'review_status'
        }]} sortField={[]} />
          <IndexTable resourceName={{
          singular: 'Các nhận xét',
          plural: 'Các nhận xét'
        }} itemCount={totalItems || 1} headings={[{
          title: 'Tiêu đề'
        }, {
          title: 'Sản phẩm'
        }, {
          title: 'Người gửi'
        }, {
          title: 'Vote'
        }, {
          title: 'Tình trạng'
        }, {
          title: 'Ngày tạo'
        }]} loading={loading} selectable={false}>
            {records?.length > 0 && rowMarkup}
          </IndexTable>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>

      <CreateReviewModal show={createModal} onClose={() => setCreateModal(false)} />
      <br />
      <br />
      <br />
    </Capacity>;
}