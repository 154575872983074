import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedStock {
    stock_id?: string;
    stock_name?: string;
    stock_address?: string;
    stock_code?: string;
    manager?: string;
    hotline?: string;
    createdAt?: string;
    stock_address_city?: string,
    stock_address_district?: string,
    stock_address_ward?: string,
    stock_payment_info?: {
        [propKey: string]: string
    } | null
    is_default?: number,
}



type IQuery = TypedStock & IQueryParams;


/*
* List of all app
*/
export function useGetStocks(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["stock/fetch_entity_list"],
        queryFn: () => axios.get<TypedStock[]>(`/stock${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetStock(stock_id: string) {
    return useQuery({
        queryKey: ['stock/fetch_entity'],
        queryFn: () => axios.get<TypedStock>(`/stock/${stock_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
export function useMutateGetStock() {
    return useMutation({
        mutationKey: ['stock/fetch_entity'],
        mutationFn: (stock_id: string) => axios.get<TypedStock>(`/stock/${stock_id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateStock() {
    return useMutation({
        mutationKey: ['stock/update_entity'],
        mutationFn: ({ stock_id, ...rest }: TypedStock) => axios.patch<any>(`/stock/${stock_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['stock/fetch_entity_list'] });
        }
    });
}


export function useCreateStock() {
    return useMutation({
        mutationKey: ['stock/create_entity'],
        mutationFn: (entity: TypedStock) => axios.post<any>(`/stock`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['stock/fetch_entity_list'] });
        }
    });
}


export function useDeleteStock() {
    return useMutation({
        mutationKey: ['stock/delete_entity'],
        mutationFn: (stock_id: string) => axios.delete<any>(`/stock/${stock_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['stock/fetch_entity_list'] });
        }
    });
}




























