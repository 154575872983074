import { Autocomplete, Icon } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import __ from 'languages/index';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import __helpers from 'helpers/index';
import { TypedUser_capacity_group, useGetUserCapacityGroups } from 'queries/user_capacity_group.query';
type TypedSearchUserCapacityGroup = {
  current_capacity_group_id?: bigint | string /** vô dụng, không hoạt động ... */;
  onClose?: (val: TypedUser_capacity_group) => void;
  title: string;
  placeholder?: string;
  disabled?: boolean;
};

/**
 *
 * @param current_capacity_group_id bigint
 * @param  onClose callback with bigint userid
 * @returns
 */
export default function QuickSearchUserCapacityGroup({
  disabled,
  current_capacity_group_id = null,
  onClose,
  title,
  placeholder = 'Tìm một nhóm quyền'
}: TypedSearchUserCapacityGroup) {
  /**!SECTION
   * Select người handler
   */

  const [mainQuery, setMainQuery] = useState({
    query: '',
    sort: 'createdAt:desc',
    limit: 5
  });
  const {
    refetch: searchUserCapacity,
    isPending: userLoading,
    data
  } = useGetUserCapacityGroups(mainQuery);

  /**
   * User list
   */
  const [entities, setEntities] = useState<TypedUser_capacity_group[]>([]);
  /**
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    if (data) {
      const {
        entities
      } = data;
      setEntities(entities); // bồi thêm data vào cái cũ
    }
  }, [data]);
  const onQueryToServer = useCallback(async () => {
    return await searchUserCapacity();
  }, []);
  useEffect(() => {
    let innerUser = [];
    if (entities) for (let voucher of entities) {
      innerUser.push({
        value: voucher.capacity_group_id,
        label: voucher.capacity_group_name
      });
      if (current_capacity_group_id && String(voucher.capacity_group_id) === String(current_capacity_group_id)) {
        setUserQuickSearchText(voucher.capacity_group_name);
      }
    }
    setUserListForQuickOption(innerUser);
  }, [entities, current_capacity_group_id]);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce((_value: string) => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setMainQuery(prevQuery => ({
      ...prevQuery,
      query: value
    }));
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const findUserSelectedInEntities = useCallback(capacity_group_id => {
    if (Array.isArray(entities)) {
      let a = entities.filter(a => a.capacity_group_id === capacity_group_id);
      return a[0] || null;
    }
  }, [entities]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    // @ts-ignore
    onClose?.call(this, findUserSelectedInEntities(selected[0]));
  }, [userListForQuickOption]);
  useEffect(() => {
    onChangeMainQueryCallback('');
  }, []);
  const textFieldSelectUser = <>
      <Autocomplete.TextField align="left" onChange={updateUserText} label={title} disabled={disabled} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder={placeholder} autoComplete="off" />
    </>;
  return <>
      <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={userLoading} id="quickSearchCapacityModalPopover" />
    </>;
}