/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import Banner from 'entities/banner';
import Dashboard from 'entities/dashboard';
import SocialTab from 'entities/dashboard/social/index';
import Game from 'entities/game';
import Gift from 'entities/gift';
import RedeemRequest from 'entities/gift/request';
import EditMyProfile from 'entities/user-profile/edit-my-profile';
import Profile from 'entities/user-profile/profile';
import Wallet from 'entities/wallet/';
import WalletCode from 'entities/wallet_code';
import ActiveAccount from '../layout/active.account';
import Login from '../layout/login';
import QuickLogin from '../layout/quick.login';
import RecoverPassword from '../layout/recover-password';
import Comment from 'entities/comment';
import Customer from 'entities/customer';
import IncomingWebhook from 'entities/incoming_webhook';
import Order from 'entities/order';
import OrderSetting from 'entities/order_setting';
import OutgoingWebhook from 'entities/outgoing_webhook';
import Product from 'entities/product';
import Review from 'entities/review';
import ReviewSetting from 'entities/review/review_setting';
import Transactions from 'entities/transactions';
import Activity_point from '../entities/activity_point';
import Affiliate from '../entities/affiliate';
import Analytics from '../entities/analytics';
import App from '../entities/app/index';
import Contactform from '../entities/contactform';
import Media from '../entities/media';
import Notification from '../entities/notification';
import Posts from '../entities/posts';
import Rank from '../entities/rank';
import Settings from '../entities/settings/index';
import UserGroup from '../entities/user_group';
import UserGroupDetail from '../entities/user_group/user_group.view';
import Users from '../entities/users';
import ViewPage from '../entities/viewpage';
import AccountSetting from 'entities/account_setting';
import Achievement from 'entities/achievement';
import Activity from 'entities/activity';
import BackUp from 'entities/backup';
import Brands from 'entities/brands';
import EmailGeneralSetting from 'entities/email_setting/email_general_setting';
import EmailTemplate from 'entities/email_setting/email_template';
import Embed from 'entities/embed';
import KeyMapIndex from 'entities/keymap';
import Logistic from 'entities/logistic/index';
import MyReferrer from 'entities/my_referrer';
import MyWallet from 'entities/my_wallet';
import ProductCategory from 'entities/product/product_category';
import ProductCollection from 'entities/product/product_collection';
import Security from 'entities/security';
import ShoppingCart from 'entities/shopping_cart';
import Stock from 'entities/stock';
import UserActivity from 'entities/user_activity';
import UserVerified from 'entities/user_verified';
import Voucher from 'entities/voucher';
import Webmail from 'entities/webmail';
import Wishlist from 'entities/wishlist';
import Withdraw from 'entities/withdraw';

/**
 * Private, w/ <AppFrame />
 * Only authorization can access this route
 */

export const private_route = [{
  path: '/',
  Entity: Dashboard
}, {
  path: '/social',
  Entity: SocialTab
}, {
  path: '/game/:slug',
  Entity: Game
}, {
  path: '/game',
  Entity: Game
}, {
  path: '/keymap',
  Entity: KeyMapIndex
}, {
  path: '/webmail',
  Entity: Webmail
}, {
  path: '/webmail/:slug',
  Entity: Webmail
}, {
  path: '/webmail/:slug/:webmail_id',
  Entity: Webmail
}, {
  path: '/email_setting',
  Entity: EmailGeneralSetting
}, {
  path: '/email_template',
  Entity: EmailTemplate
}, {
  path: '/analytics/:feature',
  Entity: Analytics
}, {
  path: '/analytics',
  Entity: Analytics
}, {
  path: '/activity',
  Entity: Activity
}, {
  path: '/activity/:slug',
  Entity: Activity
}, {
  path: '/activity/:slug/:activity_id',
  Entity: Activity
}, {
  path: '/user_activity',
  Entity: UserActivity
}, {
  path: '/user_activity/:slug',
  Entity: UserActivity
}, {
  path: '/user_activity/:slug/:user_activity_id',
  Entity: UserActivity
}, {
  path: '/notification',
  Entity: Notification
}, {
  path: '/notification/:slug/:notification_slug',
  Entity: Notification
}, {
  path: '/notification/:slug',
  Entity: Notification
}, {
  path: '/order',
  Entity: Order
}, {
  path: '/order/:slug/:order_id',
  Entity: Order
}, {
  path: '/order/:slug',
  Entity: Order
}, {
  path: '/logistic',
  Entity: Logistic
}, {
  path: '/profile',
  Entity: Profile
}, {
  path: '/edit-my-profile',
  Entity: EditMyProfile
}, {
  path: '/my-wallet',
  Entity: MyWallet
}, {
  path: '/my_referrer',
  Entity: MyReferrer
}, {
  path: '/my-wallet/:wallet_slug',
  Entity: MyWallet
}, {
  path: '/settings',
  Entity: Settings
}, {
  path: '/account_setting',
  Entity: AccountSetting
}, {
  path: '/account_setting/:slug',
  Entity: AccountSetting
}, {
  path: '/app/:slug/:ID',
  Entity: App
}, {
  path: '/app/:slug',
  Entity: App
}, {
  path: '/app',
  Entity: App
}, {
  path: '/post/:post_slug',
  Entity: ViewPage
}, {
  path: '/posts/:slug/:pages_slug',
  Entity: Posts
}, {
  path: '/posts/:slug',
  Entity: Posts
}, {
  path: '/posts',
  Entity: Posts
}, {
  path: '/media',
  Entity: Media
}, {
  path: '/media/:slug/:media_slug',
  Entity: Media
}, {
  path: '/media/:slug',
  Entity: Media
}, {
  path: '/comment',
  Entity: Comment
}, {
  path: '/comment/:slug/:comment_slug',
  Entity: Comment
}, {
  path: '/comment/:slug',
  Entity: Comment
}, {
  path: '/review/:slug/:review_id',
  Entity: Review
}, {
  path: '/review/:slug',
  Entity: Review
}, {
  path: '/review',
  Entity: Review
}, {
  path: '/review_setting',
  Entity: ReviewSetting
}, {
  path: '/wallet/:wallet_unit',
  Entity: Wallet
}, {
  path: '/wallet',
  Entity: Wallet
}, {
  path: '/users/:slug/:users_slug',
  Entity: Users
}, {
  path: '/users/:slug',
  Entity: Users
}, {
  path: '/users',
  Entity: Users
}, {
  path: '/user_group',
  Entity: UserGroup
}, {
  path: '/user_group/:user_group_id',
  Entity: UserGroupDetail
}, {
  path: '/incoming_webhook',
  Entity: IncomingWebhook
}, {
  path: '/incoming_webhook/:slug/:incoming_webhook_slug',
  Entity: IncomingWebhook
}, {
  path: '/incoming_webhook/:slug',
  Entity: IncomingWebhook
}, {
  path: '/outgoing_webhook',
  Entity: OutgoingWebhook
}, {
  path: '/outgoing_webhook/:slug/:outgoing_webhook_slug',
  Entity: OutgoingWebhook
}, {
  path: '/outgoing_webhook/:slug',
  Entity: OutgoingWebhook
}, {
  path: '/stock/:slug/:stock_id',
  Entity: Stock
}, {
  path: '/stock/:slug',
  Entity: Stock
}, {
  path: '/stock',
  Entity: Stock
}, {
  path: '/gift/:slug/:gift_id',
  Entity: Gift
}, {
  path: '/gift/:slug',
  Entity: Gift
}, {
  path: '/gift',
  Entity: Gift
}, {
  path: '/wallet_code/:slug/:wallet_code_id',
  Entity: WalletCode
}, {
  path: '/wallet_code/:slug',
  Entity: WalletCode
}, {
  path: '/wallet_code',
  Entity: WalletCode
}, {
  path: '/redeem_request/:slug/:redeem_request_id',
  Entity: RedeemRequest
}, {
  path: '/redeem_request/:slug',
  Entity: RedeemRequest
}, {
  path: '/redeem_request',
  Entity: RedeemRequest
}, {
  path: '/product_category',
  Entity: ProductCategory
}, {
  path: '/product_category/:slug/:category_id',
  Entity: ProductCategory
}, {
  path: '/product_collection/:slug/:collection_id',
  Entity: ProductCollection
}, {
  path: '/product_collection/:slug',
  Entity: ProductCollection
}, {
  path: '/product_collection',
  Entity: ProductCollection
}, {
  path: '/product/:slug/:product_id',
  Entity: Product
}, {
  path: '/product/:slug',
  Entity: Product
}, {
  path: '/product',
  Entity: Product
}, {
  path: '/transactions',
  Entity: Transactions
}, {
  path: '/transactions/:slug/:traction_id',
  Entity: Transactions
}, {
  path: '/transactions/:slug',
  Entity: Transactions
}, {
  path: '/customer',
  Entity: Customer
}, {
  path: '/customer/:slug/:customer_id',
  Entity: Customer
}, {
  path: '/customer/:slug',
  Entity: Customer
}, {
  path: '/contactform',
  Entity: Contactform
}, {
  path: '/contactform/:slug/:contactform_id',
  Entity: Contactform
}, {
  path: '/contactform/:slug',
  Entity: Contactform
}, {
  path: '/backup',
  Entity: BackUp
}, {
  path: '/embed',
  Entity: Embed
}, {
  path: '/order_setting',
  Entity: OrderSetting
}, {
  path: '/security',
  Entity: Security
}, {
  path: '/affiliate',
  Entity: Affiliate
}, {
  path: '/affiliate/:slug',
  Entity: Affiliate
}, {
  path: '/affiliate/:slug/:affiliate_id',
  Entity: Affiliate
}, {
  path: '/rank',
  Entity: Rank
}, {
  path: '/activity_point',
  Entity: Activity_point
}, {
  path: '/achievement',
  Entity: Achievement
}, {
  path: '/achievement/:slug',
  Entity: Achievement
}, {
  path: '/achievement/:slug/:achievement_id',
  Entity: Achievement
}, {
  path: '/banner/:slug/:banner_id',
  Entity: Banner
}, {
  path: '/banner/:slug',
  Entity: Banner
}, {
  path: '/banner',
  Entity: Banner
}, {
  path: '/user_verified/:slug/:user_verified_id',
  Entity: UserVerified
}, {
  path: '/user_verified/:slug',
  Entity: UserVerified
}, {
  path: '/user_verified',
  Entity: UserVerified
}, {
  path: '/shopping_cart/:user_id/:cart_id',
  Entity: ShoppingCart
}, {
  path: '/shopping_cart',
  Entity: ShoppingCart
}, {
  path: '/wishlist',
  Entity: Wishlist
}, {
  path: '/product_brand/:slug/:brand_id',
  Entity: Brands
}, {
  path: '/product_brand/:slug',
  Entity: Brands
}, {
  path: '/product_brand',
  Entity: Brands
}, {
  path: '/voucher/:slug/:voucher_id',
  Entity: Voucher
}, {
  path: '/voucher/:slug',
  Entity: Voucher
}, {
  path: '/voucher',
  Entity: Voucher
}, {
  path: '/withdraw/:slug',
  Entity: Withdraw
}, {
  path: '/withdraw',
  Entity: Withdraw
}];

/**
 * Any one can access this URL
 */
export const public_route = [];

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [{
  path: '/login',
  Entity: Login
}, {
  path: '/active-account',
  Entity: ActiveAccount
}, {
  path: '/login/sso',
  Entity: Login
}, {
  path: '/quick-login/:token',
  Entity: QuickLogin
}, {
  path: '/recover_password',
  Entity: RecoverPassword
}];