import * as ReactDOMClient from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'media/css/APP.scss';
import { QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'context/AuthContext';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from './queries';
import { NotificationProvider } from 'context/NotificationContext';
import { AxiosInterceptor } from 'config/axios.config';
import viTranslations from '@shopify/polaris/locales/vi.json';
import { AppProvider } from '@shopify/polaris';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DeleteConfirmProvider } from 'context/DeleteConfirmContext';
import { UserRoleProvider } from 'context/UserRoleContext';

/**
 * Fixed lỗi: TypeError: BigInt value can't be serialized in JSON
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error
BigInt.prototype.toJSON = function (): number {
  return this.toString();
};
const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
// Create a root.

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
function NtsLink({
  children,
  url,
  external,
  ...rest
}) {
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.rel = 'noopener noreferrer nofollow';
    return <a href={url} {...rest}>
        {children}
      </a>;
  }
  return <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>;
}
root.render(<AppProvider i18n={viTranslations} linkComponent={NtsLink} theme={'light'}>
    <QueryClientProvider client={queryClient}>
      <NotificationProvider axiosInterceptors={AxiosInterceptor}>
        <DeleteConfirmProvider>
          <AuthProvider>
            <UserRoleProvider>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </UserRoleProvider>
          </AuthProvider>
        </DeleteConfirmProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </NotificationProvider>
    </QueryClientProvider>
  </AppProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({
  id,
  name,
  value
}) {
  // console.log(id, name, value, '<< REPORT >>');
  // ga('send', 'event', {
  //   eventCategory: 'Web Vitals',
  //   eventAction: name,
  //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   eventLabel: id, // id unique to current page load
  //   nonInteraction: true, // avoids affecting bounce rate
  // });
}
reportWebVitals(sendToAnalytics);

/**
 * Webpack HMR Activation
 */

/**/

type ModuleId = string | number;
interface WebpackHotModule {
  hot?: {
    data: any;
    accept(dependencies: string[], callback?: (updatedDependencies: ModuleId[]) => void): void;
    accept(dependency: string, callback?: () => void): void;
    accept(errHandler?: (err: Error) => void): void;
    dispose(callback: (data: any) => void): void;
  };
}
declare const module: WebpackHotModule;
if (module.hot) {
  module.hot.accept();
  module.hot.dispose(() => {
    process.exit();
  });
}