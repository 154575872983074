import { useCallback, useEffect, useState } from 'react';
// import { getAssignList, deleteAssign, addAssignee } from 'store/customer.store.reducer';
import { BlockStack, Text, InlineStack, Tag, Icon, Popover, Card, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';
import { PlusIcon } from '@shopify/polaris-icons';
import QuickSearchUser from 'components/quickSearchUser';
import Capacity from 'components/Capacity';
import { useCreateCustomerAssignee, useDeleteCustomerAssign, useGetCustomerAssignList } from 'queries/customer.query';
export default function CustomerAssign({
  customerData
}) {
  const entity = customerData;
  const {
    data: assignList,
    isLoading: assignLoading
  } = useGetCustomerAssignList(entity?.user_id);
  const {
    mutateAsync: deleteAssign
  } = useDeleteCustomerAssign();
  const {
    mutateAsync: addAssignee,
    isPending: addAssigneeLoading
  } = useCreateCustomerAssignee();
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    setSelectedTags(assignList);
  }, [assignList]);
  const removeTag = useCallback((tag: any) => async () => {
    setSelectedTags(previousTags => previousTags.filter(previousTag => previousTag.id !== tag.id));
    await deleteAssign(tag.id);
  }, []);
  const [showAddnewUser, setShowAddnewUser] = useState(false);
  const addNewAssignee = useCallback(async user_id => {
    await addAssignee({
      customer_id: entity?.user_id,
      user_id: user_id
    });
  }, [entity]);
  return <>
      {assignLoading ? <SkeletonBodyText /> : <BlockStack gap={'200'}>
          <Text as="p" variant="bodyMd" fontWeight="bold" key="CustomerAssign_title">
            Phân quyền
          </Text>
          <InlineStack gap={'200'} align="start" blockAlign="center">
            {entity?.user && <Tag>{entity?.user?.display_name || entity?.user?.user_email}</Tag>}
            {Array.isArray(selectedTags) && selectedTags.length > 0 && selectedTags.map((e, index) => {
          return <Tag key={index + 'taglist'} onRemove={removeTag(e)}>
                    {e?.assignee?.display_name}
                  </Tag>;
        })}

            {addAssigneeLoading && <SkeletonThumbnail size="extraSmall" />}

            <Popover active={showAddnewUser} activator={<Capacity current_user_can={['manage_customers', 'edit_customers']}>
                  <Tag onClick={() => setShowAddnewUser(true)}>
                    <InlineStack align="center" blockAlign="center">
                      <span key="add_new_assignee">Thêm nhân viên</span>
                      <Icon source={PlusIcon} />
                    </InlineStack>
                  </Tag>
                </Capacity>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(false)}>
              <Card>
                <QuickSearchUser title="" user_role="sale,sale_admin" onClose={(userData: any) => {
              addNewAssignee(userData.user_id);
              setShowAddnewUser(false);
            }} />
              </Card>
            </Popover>
          </InlineStack>
          <Text as="p" variant="bodySm" tone="subdued" key="CustomerAssign_subtitle">
            Những người trong danh sách phân quyền này sẽ có thể xem và chỉnh sửa dữ liệu.
          </Text>
        </BlockStack>}
    </>;
}