import { Card, DataTable, Page, Text, Link } from '@shopify/polaris';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlusIcon } from '@shopify/polaris-icons';
import RankModal from './rank.new';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading';
import { TypedRank, useGetRanks } from 'queries/rank.query';
import { Helmet } from 'react-helmet-async';
import SimpleFilter from 'components/simpleFilter';
import dateandtime from 'date-and-time';
import __ from 'languages/index';
export default function RankList() {
  const [showModal, setShowModal] = useState(false);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 100,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    isLoading,
    refetch: getEntities
  } = useGetRanks(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setEntities(entities);
      setTotalItems(totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Rank', '/rank' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  const closeModal = useCallback(() => {
    setShowModal(false);
    getEntities();
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const currentEntity = useRef(null);
  return <>
      <Helmet>
        <title>Hạng người dùng</title>
      </Helmet>
      {isLoading ? <SkeletonLoading /> : <Page title="Hạng" subtitle="Dành cho tất cả mọi người" primaryAction={{
      content: 'Tạo mới một hạng',
      icon: PlusIcon,
      onAction: () => setShowModal(true)
    }}>
          <Card padding="0">
            <SimpleFilter onCallback={filterCallback} options={[{
          label: 'Xét từng năm',
          value: 'yearly',
          field: 'timeframe'
        }, {
          label: 'Xét từng tháng',
          value: 'monthly',
          field: 'timeframe'
        }, {
          label: 'Xét từng tuần',
          value: 'weekly',
          field: 'timeframe'
        }, {
          label: 'Xét từng ngày',
          value: 'daily',
          field: 'timeframe'
        }]} loading={isLoading} sortField={[{
          label: 'Số điểm',
          field: 'min_points'
        }]} />
            <DataTable verticalAlign="middle" columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Tên thành tích', 'Slug', 'Điểm tối thiểu', 'Chu kỳ xét hạng', 'Ngày tạo']} rows={Array.isArray(entities) ? entities.map((rankData: TypedRank, index) => {
          const {
            rank_id,
            rank_name,
            rank_slug,
            rank_description,
            min_points,
            timeframe,
            createdAt
          } = rankData;
          return [<Link key={'rank_' + index} onClick={() => {
            currentEntity.current = rankData;
            setShowModal(true);
          }} removeUnderline>
                          <Text as="span" fontWeight="bold">
                            {rank_name}
                          </Text>
                          <Text as="p" variant="bodyXs" tone="subdued">
                            {helpers.trimMiddleString(rank_description)}
                          </Text>
                        </Link>, rank_slug, helpers.formatNumber(min_points), __(timeframe), dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY')];
        }) : []} />
          </Card>

          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Page>}
      <RankModal open={showModal} onClose={closeModal} entity={currentEntity.current} />
    </>;
}