import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Text } from '@shopify/polaris';
import { UploadIcon, XIcon } from '@shopify/polaris-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import 'media/css/product_gallery.scss';
import MediaCenter from 'components/mediaCenter';
import helpers from 'helpers/index';
import { TypedProduct_to_media, useCreateProduct_to_media, useDeleteProduct_to_media, useGetProduct_to_medias } from 'queries/product_to_media.query';
export default function ProductGallery({
  product_id
}: {
  product_id: string;
}) {
  const {
    mutateAsync: createProductToMedia
  } = useCreateProduct_to_media();
  const {
    mutate: deleteMedia
  } = useDeleteProduct_to_media();
  const {
    refetch: getEntities,
    data: mediaList
  } = useGetProduct_to_medias({
    limit: 100,
    sort: 'media_order:asc',
    product_id: product_id || 'null'
  });
  const [entities, setEntities] = useState<TypedProduct_to_media[]>([]);
  const [showMediaModal, setShowMediaModal] = useState(false);
  useEffect(() => {
    if (mediaList) {
      setEntities(mediaList.body);
    }
  }, [mediaList]);
  const mediaModal = useCallback(() => {
    setShowMediaModal(true);
  }, []);
  const removeThisElement = useCallback(async (id: string) => {
    try {
      setEntities(previousTags => previousTags.filter(previousTag => previousTag.id !== id));
      deleteMedia(id);
    } catch (e) {}
  }, []);
  const setTheseElement = useCallback(async (selectedMedia: string[]) => {
    const currentMediaIds = entities.map(entity => entity.media_id);

    // Add new media
    for (const mediaId of selectedMedia) {
      if (!currentMediaIds.includes(mediaId)) {
        const newMedia = {
          product_id: product_id,
          media_id: mediaId,
          media_order: entities.length
        };
        try {
          const mediaAfterCreate = await createProductToMedia(newMedia);
          setEntities(oldData => [...oldData, mediaAfterCreate]);
        } catch (e) {
          console.error('Failed to add media:', e);
        }
      }
    }

    // Remove media not in the selected list
    for (const entity of entities) {
      if (!selectedMedia.includes(entity.media_id)) {
        try {
          await deleteMedia(entity.id);
          setEntities(previousTags => previousTags.filter(previousTag => previousTag.id !== entity.id));
        } catch (e) {
          console.error('Failed to remove media:', e);
        }
      }
    }

    // Update the order of the images
    setEntities(prevEntities => {
      const updatedEntities = [...prevEntities];
      updatedEntities.sort((a, b) => a.media_order - b.media_order);
      return updatedEntities;
    });
  }, [entities, product_id, createProductToMedia, deleteMedia]);
  const onDragEnd = async result => {
    if (!result.destination) return;
    const reorderedEntities = Array.from(entities);
    const [removed] = reorderedEntities.splice(result.source.index, 1);
    reorderedEntities.splice(result.destination.index, 0, removed);
    setEntities(reorderedEntities);
    const reorderPayload = reorderedEntities.reduce((acc, entity, index) => {
      acc[entity.id] = index;
      return acc;
    }, {});
    try {
      await axios.patch('/product_to_media/batch_reorder', reorderPayload);
    } catch (error) {
      console.error('Failed to reorder media:', error);
    }
  };
  return product_id ? <div>
      <MediaCenter show={showMediaModal} onClose={(selectedMedia: string[]) => {
      setShowMediaModal(false);
      setTheseElement(selectedMedia);
    }} initialSelectedMedia={entities.map(entity => entity.media_id)} />
      <div id="product_gallery">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="product_gallery" direction="horizontal">
            {provided => <div className="product_gallery_wrap" {...provided.droppableProps} ref={provided.innerRef}>
                {entities.length < 1 ? <Text as="p" tone="subdued" alignment="center">
                    Chưa có gì ở đây cả!
                  </Text> : entities.map((file, index) => <Draggable key={file.id} draggableId={file.id} index={index}>
                      {(provided, snapshot) => <div className="product_gallery_element" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{
                ...provided.draggableProps.style,
                display: 'inline-block',
                margin: snapshot.isDragging ? '0 10px' : '0'
              }}>
                          <img className="product_gallery_thumbnail" alt={file.media.media_filename} src={helpers.getMediaLink(file.media?.media_thumbnail ? file.media?.media_thumbnail['128x128'] : '')} />
                          <Button icon={XIcon} variant="plain" onClick={() => removeThisElement(file.id)}></Button>
                        </div>}
                    </Draggable>)}
                {provided.placeholder}
              </div>}
          </Droppable>
        </DragDropContext>
      </div>
      <br />
      <Divider />
      <br />
      <Text as="p" alignment="center">
        <Button variant="plain" icon={UploadIcon} onClick={mediaModal}>
          Thêm ảnh và video
        </Button>
      </Text>
    </div> : null;
}