import React, { createContext, useContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { useGetCurrentUserData } from 'queries/user.query';
import { TypedUser } from 'interface/user.model';
import __helpers from 'helpers';
interface AuthContextType {
  user: TypedUser | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  currentUserCan: (capacity_to_check: string | string[]) => boolean;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);
const AuthProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [user, setUser] = useState<TypedUser | null>(null);
  const [start_display_children, setStart_display_children] = useState(false);
  const {
    data,
    error,
    isLoading,
    isFetched
  } = useGetCurrentUserData();
  const startSetData = useCallback(async () => {
    if (data) {
      setUser(data);
    }
    if (error) {
      setUser(null);
    }
    await __helpers.sleep(1000);
    setStart_display_children(true);
  }, [data, error]);
  useEffect(() => {
    if (isFetched) startSetData();
  }, [isFetched]);

  /** Trường hợp bị log out ? OEE */
  // useEffect(() => {
  //   if (isRefetchError) {
  //     console.log('User has been log out ...');
  //   }
  // }, [isRefetchError]);

  /**
   * Check if current user login has a capacity or not
   */
  const currentUserCan = (capacity_to_check: string | string[]) => {
    const {
      capacities = {},
      user_role = 'visitor'
    } = data;
    const requiredCapacities = Array.isArray(capacity_to_check) ? capacity_to_check : [capacity_to_check];

    // Direct permissions for 'super_admin' and 'admin'
    if (['super_admin', 'admin'].includes(user_role)) {
      return true;
    } else {
      // Check user_role against required capacities
      if (requiredCapacities.includes(user_role)) {
        return true;
      } else if (__helpers.isObject(capacities)) {
        // Check specific capacities
        return Object.values(capacities).some(value => Array.isArray(value) && value.some((cap: string) => requiredCapacities.includes(cap)));
      }
    }
    return false;
  };
  return <AuthContext.Provider value={{
    user,
    currentUserCan,
    isAuthenticated: !!user,
    isAuthenticating: isLoading
  }}>
      {start_display_children && children}
    </AuthContext.Provider>;
};

/** return {user: TypedUser | null, isAuthenticated: boolean, isAuthenticating: boolean} */
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
export { AuthProvider, useAuth };