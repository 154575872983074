import { Text, Tag, Autocomplete, Icon, InlineError, InlineStack } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { TypedProductBrand, useMutateGetProductBrands } from 'queries/brand.query';
import ProductBrandModalAddAndEdit from 'entities/brands/brand.modal';
import useDidMountEffect from './useDidMountEffect';

/** Phiên bản cải tiến tìm nhanh một product brand, bản cải tiến triệt để ... */
export default function QuickSearchProductBrand({
  current_brand_id = [],
  label = 'Nhãn hiệu',
  errorMessage = '',
  onClose,
  allowMultiple = true
}: {
  current_brand_id: any[];
  errorMessage?: string;
  label: string;
  onClose: (val: string[]) => void;
  allowMultiple?: boolean;
}) {
  /******************************** *
   * ADVANCE PRODUCT CATEGORY ...
   ******************************** */
  const initialQuery = {
    brand_id: '',
    // trong trường hợp mà nó lưu sẵn rồi thì có thể để vào đây!
    limit: 10,
    query: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);
  const {
    mutateAsync: refetchProductBrand,
    isPending: searchingCategory,
    data: entities
  } = useMutateGetProductBrands();
  const reduceProductCategoryRequest = useCallback(mainQuery => {
    refetchProductBrand(mainQuery);
  }, []);
  const reduceRequestProductCategoryMemo = useMemo(() => {
    return __helpers.debounce(_value => {
      reduceProductCategoryRequest?.call(this, _value);
    }, 500);
  }, []);
  useDidMountEffect(() => {
    reduceRequestProductCategoryMemo(mainQuery);
  }, [mainQuery, reduceRequestProductCategoryMemo]);

  /** Lưu cache hết lại để hiển thị cái tên trùng với cái ID chết tiệt ... */
  const cacheProductBrandData = useRef<any[]>([]);
  const [selectedOptions3, setSelectedOptions3] = useState<string[]>([]);
  const [quickSearchText, setQuickSearchText] = useState('');
  const [options3, setOptions3] = useState([]);

  /** Chỉ khi hiện tại khác với current thì mới gọi sang bên kia ... */
  const handleClose = useCallback(selectedOptions3 => {
    if (JSON.stringify(current_brand_id) !== JSON.stringify(selectedOptions3)) {
      onClose(selectedOptions3);
    }
  }, [current_brand_id, onClose]);

  /**
   * Yêu cầu chỉ được nạp lần đầu tiên, không được nạp lại ...
   */
  let alreadyTakeCurrentCategory = useRef(false);
  useEffect(() => {
    if (current_brand_id && alreadyTakeCurrentCategory.current === false) {
      setSelectedOptions3(current_brand_id);
      alreadyTakeCurrentCategory.current = true;
      setMainQuery(old => {
        return {
          ...old,
          brand_id: current_brand_id.join(',')
        };
      });
    }
  }, [current_brand_id]);

  /** Khối này theo dõi entities và nhét vào cache, sau đó nhét vào danh sách */

  const deselectedOptions3 = useCallback(entities => {
    const results = entities.body.map((productcategory: any) => {
      cacheProductBrandData.current.push(productcategory);
      return {
        label: productcategory.brand_name,
        value: String(productcategory.brand_id)
      };
    });
    setOptions3(results);
  }, [cacheProductBrandData.current]);
  useEffect(() => {
    if (!entities && typeof entities?.body === 'undefined') return;
    deselectedOptions3(entities);
  }, [entities]);
  const updateText3 = useCallback((value: string) => {
    setQuickSearchText(value);
    //   if (!value) return;
    let N = {
      ...mainQuery,
      ...{
        query: value,
        brand_id: '' /** unset brand_id ddi */
      }
    };
    setMainQuery(N);
  }, [mainQuery]);
  const updateSelection = useCallback((selected: string[]) => {
    let selectedLabel = '';
    selected.map(selectedItem => {
      const matchedOption = options3.find(option => {
        return option.value.match(selectedItem);
      });
      selectedLabel = matchedOption.label;
      return matchedOption && matchedOption.label;
    });
    setSelectedOptions3(selected);
    if (!allowMultiple) setQuickSearchText(selectedLabel);
    handleClose(selected);
  }, [allowMultiple, options3]);
  const removeTag3 = useCallback((tag: string) => () => {
    const selected = [...selectedOptions3];
    selected.splice(selected.indexOf(tag), 1);
    setSelectedOptions3(selected);
    handleClose(selected);
  }, [selectedOptions3]);

  /**************** HIEERN THIJ MODAL TAO MOI */
  const [showQuickCreate, setShowQuickCreate] = useState(false);
  const afterCreateProductBrandCallback = useCallback((newProductBrand?: TypedProductBrand) => {
    if (newProductBrand) {
      // Thêm vào cache ngay lập tức
      const isCached = cacheProductBrandData.current.some(item => String(item.brand_id) === String(newProductBrand.brand_id));
      if (!isCached) {
        cacheProductBrandData.current.push(newProductBrand);
      }

      // Cập nhật selectedOptions3
      if (allowMultiple) {
        setSelectedOptions3(old => [...old, newProductBrand.brand_id]);
      } else {
        setSelectedOptions3([newProductBrand.brand_id]);
        setQuickSearchText(newProductBrand.brand_name); // Cập nhật input value nếu không cho phép chọn nhiều
      }
    }
    setShowQuickCreate(false);
  }, [allowMultiple]);
  const verticalContentMarkup = selectedOptions3?.length > 0 ? <InlineStack align="start" blockAlign="center" gap="200">
        {selectedOptions3.map(option => {
      if (!option) return null;
      let l = options3.find(el => el.value === String(option))?.label;
      if (!l) return null;
      return <Tag key={`option_x_${option}`} onRemove={removeTag3(option)}>
              {l}
            </Tag>;
    })}
      </InlineStack> : null;
  return <>
      <Autocomplete actionBefore={{
      accessibilityLabel: 'Tạo nhãn hiệu mới',
      content: 'Tạo một nhãn hiệu mới',
      ellipsis: true,
      helpText: 'Tạo nhãn hiệu mới và chỉnh sửa sau',
      icon: PlusCircleIcon,
      onAction: () => {
        setShowQuickCreate(!showQuickCreate);
      }
    }} allowMultiple={allowMultiple} loading={searchingCategory} options={options3} selected={selectedOptions3} textField={<>
            <Autocomplete.TextField onChange={updateText3} label={label} error={errorMessage ? true : false} placeholder="Chọn một nhãn hiệu" value={quickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} verticalContent={verticalContentMarkup} autoComplete="off" />
            <div style={{
        marginTop: '4px'
      }}>
              <InlineError message={errorMessage} fieldID="TextFieldSelectCategory" />
            </div>
          </>} onSelect={updateSelection} // setSelectedOptions3
    listTitle="Danh sách nhãn hiệu" />

      <ProductBrandModalAddAndEdit show={showQuickCreate} onClose={v => afterCreateProductBrandCallback(v)} />
    </>;
}