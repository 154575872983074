import { compiledBlock as _compiledBlock } from "million/react";
const OrderTransportLog_1 = _compiledBlock(_props => <slot>
      {_props.v0}

      {_props.v1}
    </slot>, {
  name: "OrderTransportLog_1",
  portals: ["v0", "v1"]
});
const OrderTransportLog = function OrderTransportLog({
  order_id
}) {
  const {
    data,
    isLoading
  } = useGetOrderTransportLog(order_id);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const ActualPage = !__helpers.isEmpty(data) && data.map((element, index) => {
    return <div className={`order_transport_history ${index === 0 && 'first_element'}`} key={`order_transport_history_${index}`}>
          <span className={`order_transport_history_item_dot order_transport_history_item_status_${element.transport_status}`}></span>
          <InlineStack align="start" blockAlign="center" gap="400">
            <div>{__helpers.subtractTimeHistory(Number(element.createdAt))}</div>
            <BlockStack>
              <Text as="h4" variant="headingMd">
                {element.transport_title}
              </Text>
              <Text as="p" variant="bodySm" tone="subdued">
                {element.transport_content}
              </Text>
            </BlockStack>
          </InlineStack>
        </div>;
  });
  return /*@million jsx-skip*/<OrderTransportLog_1 v0={<ShowCreateTransportModal current_order_id={order_id} show={showTransactionModal} onClose={() => setShowTransactionModal(false)} />} v1={isLoading ? <SkeletonBodyText /> : <BlockStack gap="400">
          <span>
            <Button icon={PlusIcon} onClick={() => setShowTransactionModal(true)}>
              Thêm nhật ký vận chuyển
            </Button>
          </span>
          <div id="order_transport_history_wrap">{ActualPage}</div>
          <Text as="p">Thông tin vận chuyển tại đây có thể được xem bởi khách hàng, trong các chức năng theo dõi đơn hàng.</Text>
        </BlockStack>} />;
};
import { block as _block } from "million/react";
import React, { useState } from 'react';
import { BlockStack, Button, InlineStack, SkeletonBodyText, Text } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { useGetOrderTransportLog } from 'queries/order_transport_log.query';
import 'media/css/order_transport_log.scss';
import { PlusIcon } from '@shopify/polaris-icons';
import ShowCreateTransportModal from './order_transport_modal';
export default OrderTransportLog;